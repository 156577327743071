// App reviews in Playstore / App Store
.app-review-wrap {
  figure {
    width: 48px;
    margin-right: 15px;
  }
  p {
    font-size: 16px;
    line-height: 32px;
  }
  .app-review {
    > * {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &.is-adaptive {
    @include respond-below(tablet) {
      text-align: center;
      .app-review {
        display: none;
      }
    }
  }
}

.device--ios {
  .app-review-wrap {
    [data-devicetype="iphone"] {
      display: block !important;
    }
  }
}

.device--android {
  .app-review-wrap {
    [data-devicetype="android"] {
      display: block !important;
    }
  }
}
