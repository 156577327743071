.grid--default {
  @include respond-above(tablet) {
    text-align: justify;
    font-size: 0;
    > * {
      width: 50%;
      display: inline-block;
    }
    picture {
      max-width: 560px;
    }
    &::after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 0;
    }
    &.has-align-middle {
      > * {
        vertical-align: middle;
      }
    }
    &.has-align-top {
      > * {
        vertical-align: top;
      }
    }
  }
  @include respond-between(tablet, desktop) {
    > *:first-child {
      padding-right: 20px;
    }
  }
}
.grid--wrap {
  display: flex;
  flex-wrap: wrap;
  @include respond-above(tablet) {
    flex-wrap: nowrap;
  }
}
