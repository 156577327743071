@mixin tick($x, $y) {
  $tick-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='%233B4C66' fill-rule='evenodd' transform='translate(-36 -174)'%3E%3Cpath d='M52.225 178.975c.244-.244.64-.244.884 0 .221.222.242.569.06.814l-.06.07-9.167 9.166c-.222.222-.57.242-.814.06l-.07-.06-4.167-4.166c-.244-.244-.244-.64 0-.884.222-.222.57-.242.814-.06l.07.06 3.725 3.724 8.725-8.724z'/%3E%3C/g%3E%3C/svg%3E%0A";
  &::before {
    content: "";
    display: inline-block;
    width: $x;
    height: $y;
    background: url($tick-svg) no-repeat;
    background-size: contain;
    position: relative;
    top: 0.25em;
  }
}

/*Bullet */
// @include bulletin ('arrow',4px,10px,1px,#000,10px,10px)-> _| (after respective rotate)->   <
@mixin bulletin($type, $w, $h, $border-w, $color, $offset-x, $offset-y) {
  &::before {
    content: "";
    width: $w;
    height: $h;
    position: absolute;
    top: $offset-y;
    left: $offset-x;
    border: solid $color;
    border-width: 0 $border-w $border-w 0;

    @if ($type== "tick") {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    } @else if($type== "arrow") {
      -webkit-transform: rotate(45deg + 90deg);
      -ms-transform: rotate(45deg + 90deg);
      transform: rotate(45deg + 90deg);
    }
  }
}

//adding z-index value
@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}
