%card-shadow {
  box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
}
.card--default {
  @include respond-above(tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @include respond-above(tablet_max) {
    &.has-two-col {
      > * {
        width: calc((100% - (40px * 2)) / 2);
      }
    }
    &.has-three-col {
      > * {
        width: calc((100% - (40px * 2)) / 3);
      }
    }
    &.has-four-col {
      > * {
        width: calc((100% - (40px * 3)) / 4);
      }
    }
  }
  @include respond-between(tablet, tablet_max) {
    &.has-two-col {
      > * {
        width: calc((100% - (20px * 2)) / 2);
      }
    }
    &.has-three-col {
      > * {
        width: calc((100% - (20px * 2)) / 3);
      }
    }
    &.has-four-col {
      > * {
        width: calc((100% - (40px)) / 2);
      }
    }
  }
  @include respond-between(mobile, tablet) {
    width: 70%;
    margin: 0 auto;
  }
}

.card--default {
  h3 {
    @extend .text-md;
  }
  .icon-title-wrap {
    font-size: 0;
  }
  @include respond-above(tablet) {
    .icon-title-wrap {
      figure {
        width: 48px;
      }
    }
    .icon-wrap {
      width: 40px;
      margin-right: 24px;
      @include respond-below(tablet) {
        width: 35px;
        margin-right: 19px;
      }
    }
  }
  @include respond-below(tablet) {
    .icon-title-wrap {
      figure {
        width: 24px;
        img {
          width: 25px;
          height: 25px;
        }
      }
      > * {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.card--default {
  &.card--feature {
    @include respond-below(tablet) {
      .icon-title-wrap {
        margin-bottom: 12px;
        display: flex;
        figure {
          margin-top: 2px;
          margin-right: 16px;
        }
      }
      > * {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    @include respond-above(tablet) {
      &.has-three-col {
        > li p {
          padding-right: 40px;
        }
      }
      &.has-shadow {
        > li p {
          padding-right: 0;
        }
      }
    }
    @include respond-between(tablet, tablet_max) {
      > * {
        margin-bottom: 40px;
      }
    }
  }
  &.has-shadow {
    > * {
      @extend .br-8;
      box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
      @include respond-above(tablet) {
        padding: 24px 24px 32px;
      }
      @include respond-below(tablet) {
        padding: 20px 20px 24px;
        margin-bottom: 20px;
      }
    }
  }
}

.card--default {
  &.card--showcase {
    > * {
      @extend %card-shadow;
      @extend .br-8;
      @extend .bg-white;
      display: flex;
      padding: 24px;
      margin-bottom: 40px !important;
      @include respond-above(tablet_max) {
        &:nth-last-child(3),
        &:nth-last-child(2),
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
      @include respond-below(tablet) {
        padding: 22px 22px 20px;
        margin-bottom: 20px;
      }
    }
    figure {
      min-width: 40px;
      margin-right: 24px;
      @include respond-between(tablet, tablet_max) {
        min-width: 28px;
        margin-right: 10px;
      }
      @include respond-below(tablet) {
        min-width: 35px;
        margin-right: 19px;
      }
    }
    h3 {
      margin-bottom: 8px;
    }
    p {
      @extend .text-xs;
    }
    .see-all {
      border: 1px solid $black-300;
      box-shadow: none;
      background-color: transparent;
      color: $color-brand;
      align-items: center;
      > * {
        @extend .text-sm;
      }
      @include respond-below(tablet) {
        min-height: 124px;
        margin-bottom: 0;
      }
    }
  }
}

.card--masonry {
  flex-wrap: nowrap;
  > ul {
    max-width: 360px;
    @include respond-below(tablet) {
      margin-right: auto;
      margin-left: auto;
    }
    li {
      @extend .br-8;
      background-color: $color-white;
      box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
    }
  }
  @include respond-above(tablet) {
    > ul li {
      margin-bottom: 40px;
      padding: 24px 24px 32px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @include respond-below(tablet) {
    > ul li {
      margin-bottom: 20px;
      padding: 20px 20px 24px;
    }
  }
  @include respond-between(tablet, desktop) {
    > ul {
      width: 32%;
    }
  }
}

@include respond-below(tablet) {
  .scroll-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    width: 100%;
    margin: 0;
    padding: 0 0 12px 20px;
    a {
      display: inline-block;
      padding: 24px 26px 24px 24px;
      min-width: 295px;
      vertical-align: bottom;
      white-space: normal;
      margin-right: 20px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
