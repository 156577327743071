// Rating card
// Reviews card
// Client card
// Stats card
// Product card

.card--masonry {
  .rating-card {
    > p {
      margin-top: 20px;
    }
  }
  .review-card {
    .review-from {
      color: $color-brand;
      @extend .text-sm;
    }
    > p {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    > cite {
      font-size: 16px;
      line-height: 28px;
    }
  }
  .stats-card {
    strong {
      @extend .ff-euclid;
      margin-bottom: 8px;
      font-size: 56px;
      line-height: 64px;
      color: $black-500;
    }
    p.even {
      margin-top: 12px;
    }
    > cite {
      @extend .text-brandcolor;
      display: inline-block;
      margin-top: 16px;
      font-size: 16px;
      line-height: 28px;
    }
    @include respond-below(mobile) {
      min-height: 140px;
    }
  }

  .client-card {
    padding-bottom: 0;
    color: $color-white;
    .quot-glyph {
      margin-top: -4px;
      margin-right: 8px;
    }
  }
}

// .card--masonry {
//   .review-card,
//   .rating-card,
//   .stats-card {
//     padding-bottom: 32px;
//     @include respond-below(tablet) {
//       padding-bottom: 24px;
//     }
//   }
//   .rating-card {
//     > * {
//       @extend .text-sm;
//     }
//     .rating-img {
//       margin-bottom: 20px;
//     }
//   }
//   .review-card {
//     .review-from {
//       @extend .text-sm;
//       @extend .mb-2;
//       color: $blue-500;
//     }
//     .review-cite {
//       line-height: 28px;
//       @extend .mt-2;
//     }
//   }
//   .client-card {
//     .client-text {
//       @extend .text-md;
//       color: $color-white;
//     }
//   }
//   .product-card {
//   }
//   .stats-card {
//   }
// }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
