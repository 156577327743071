//faq section
.faq-section {
  text-align: left;
  input {
    position: absolute;
    opacity: 0;
    @include z-index(minus-1);
  }
  .faq {
    @extend .mx-auto;
    width: 545px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
    overflow: hidden;
    margin-bottom: 22px;
    &:hover,
    &:active {
      box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
        0 1px 4px 0 rgba(22, 45, 61, 0.1);
    }
    @include respond-below(tablet) {
      width: 100%;
      margin-bottom: 22px;
    }
    &-ques {
      @extend .text-md;
      @extend .fw-700;
      color: $black;
      display: flex;
      justify-content: space-between;
      padding: 26px 60px 28px 20px !important;
      cursor: pointer;
      word-break: break-word;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 20px;
        width: 24px;
        height: 24px;
        border: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2348A36C' fill-rule='evenodd' d='M21.797 15.864c.27.26.27.681 0 .941-.245.234-.625.257-.897.07l-.086-.07L12 8.36l-8.814 8.445c-.244.234-.625.257-.896.07l-.087-.07c-.244-.234-.268-.599-.073-.859l.074-.082 8.866-8.495c.246-.236.581-.369.93-.369.3 0 .588.098.819.274l.11.095 8.868 8.495z' clip-rule='evenodd'/%3E%3C/svg%3E");
        -webkit-transform: translateY(-50%) rotate(180deg);
        transform: translateY(-50%) rotate(180deg);
        text-align: center;
        -webkit-transition: all 0.35s;
        transition: all 0.35s;
        color: #3c60da;
      }
      @include respond-below(tablet) {
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 25px;
      }
    }
    &-point {
      @extend .text-sm;
      @extend .fw-400;
      color: $black-600;
      max-height: 0;
      transition: all 0.35s ease-in-out;
      padding: 0px 30px 0px 20px;
      p {
        font-size: 18px;
        @include respond-below(tablet) {
          font-size: 16px;
        }
      }
    }
  }
  .faq:focus-visible {
    outline: 4px solid rgba(0, 125, 250, 0.6);
    outline-offset: 1px;

    & > label {
      outline: none;
    }
  }
  // :checked
  input:checked,
  label[aria-expanded="true"] {
    + .faq-ques {
      color: $black;
      &::after {
        top: 14px;
        transform: rotate(-45deg);
      }
    }
    + .active {
      &::after {
        transform: rotate(-45deg);
      }
    }
    ~ .faq-point {
      max-height: 150px;
      padding-bottom: 32px;
      @include respond-below(tablet) {
        padding-bottom: 35px;
        max-height: 230px;
      }
    }
  }
  &#seeplan-toggle {
    display: none;
    @include respond-below(tablet) {
      display: block;
      .faq {
        .active {
          display: flex !important;
        }
        .inactive {
          display: none;
        }
        #show {
          display: none;
          &::after {
            top: 10px;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

.integrations-faq {
  & > * {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(51, 57, 61, 0.12),
      0 3px 8px 0 rgba(51, 57, 61, 0.15);
  }
}

.page-with-faq,
.integration-listing-page {
  .faq-section .faq-ques {
    padding: 26px 60px 28px 20px !important;
    &::after {
      position: absolute;
      top: 50%;
      right: 20px;
      width: 24px;
      height: 24px;
      border: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2348A36C' fill-rule='evenodd' d='M21.797 15.864c.27.26.27.681 0 .941-.245.234-.625.257-.897.07l-.086-.07L12 8.36l-8.814 8.445c-.244.234-.625.257-.896.07l-.087-.07c-.244-.234-.268-.599-.073-.859l.074-.082 8.866-8.495c.246-.236.581-.369.93-.369.3 0 .588.098.819.274l.11.095 8.868 8.495z' clip-rule='evenodd'/%3E%3C/svg%3E");
      transform: translateY(-50%) rotate(180deg);
    }
    @include respond-below(tablet) {
      padding-right: 0;
    }
  }

  .faq-section input:checked + .faq-ques::after,
  .faq-section label[aria-expanded="true"] + .faq-ques::after {
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
  }
  @include respond-below(tablet) {
    .faq-section.bg-offset-top::before {
      height: 95px;
    }
    .faq-section input:checked ~ .faq-point,
    .faq-section label[aria-expanded="true"] ~ .faq-point {
      padding-bottom: 24px;
      max-height: initial;
    }
    &.integrations-faq h3 {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  @include respond-above(tablet) {
    .faq-section input:checked ~ .faq-point,
    .faq-section label[aria-expanded="true"] ~ .faq-point {
      max-height: initial !important;
    }
    .faq-section.bg-offset-top::before {
      height: 200px;
    }
  }
}
