.partners {
  .blog-posts {
    transition: 0.2s box-shadow linear;
    display: inline-block;
  }
  .video-section::before {
    height: 80%;
  }
  .hover-review-card.boxshadow-none {
    border: solid 1.5px $black-300;
    &:hover {
      box-shadow: none;
    }
  }
  .hover-review-card:hover,
  .blog-posts:hover {
    box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1),
      0 4px 8px 0 rgba(48, 58, 71, 0.14);
  }
  .card--default.card--showcase figure {
    min-width: 48px;
    @include respond-below(tablet) {
      min-width: 24px;
    }
  }
  .card--default.card--showcase {
    & > * {
      display: initial;
    }
    & > a {
      text-align: right;
      @include respond-below(tablet) {
        text-align: left;
      }
      display: inline-block;
    }
    @include respond-below(tablet) {
      & > * {
        padding: 18px 16px 24px 20px;
      }
    }
  }
  .card--default.card--showcase p {
    font-size: 18px;
    line-height: 28px;
    @include respond-above(tablet) {
      max-width: 240px;
      display: inline-block;
    }
    text-align: left;
  }
  .img-with-green-bg {
    margin-top: -15px;
    @include respond-below(tablet) {
      margin-top: -12px;
    }
  }
  .desktop-pdf {
    display: block;
  }
  .mobile-pdf {
    display: none;
  }
  @include respond-below(tablet) {
    .img-with-yellow-bg {
      margin-top: 4px;
    }
    .video-section article h2 {
      font-size: 38px;
      line-height: 48px;
    }
    .desktop-pdf {
      display: none;
    }
    .mobile-pdf {
      display: block;
    }
  }
}

.social-media-section {
  figure {
    max-width: 440px;
    display: flex;
    justify-content: space-between;
  }
  a {
    background-color: white;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 0.7px 1.4px 0 rgba(48, 58, 71, 0.2),
      0 0.7px 2.1px 0 rgba(48, 58, 71, 0.1);
    &:hover {
      box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1),
        0 4px 8px 0 rgba(48, 58, 71, 0.14);
    }
    @include respond-above(tablet) {
      padding: 20px;
    }
    @include respond-below(tablet) {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  &::after {
    height: 50%;
  }
}
