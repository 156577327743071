.industry-root-page {
  scroll-behavior: smooth;

  .industry__nav {
    &.bg-offset::after {
      bottom: 0;
      height: 50%;
    }

    nav {
      box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
      padding: 22px 40px;
      transition: 0.7s transform;
      transform: scaleY(1);

      a {
        display: inline-block;
        opacity: 1;
        transition: 0.2s opacity;
        padding: 6px 30px;
        margin: 0px 29px 10px 0px;
        margin-left: 0px;
        border-radius: 18px;
        border: 1px solid $black-300;

        &:last-child {
          margin-right: 0;
        }

        &:active {
          color: white !important;
        }
      }
    }

    &.animate-nav {
      nav {
        a {
          opacity: 0;
        }

        transform: scaleY(0);
      }
    }
  }

  .client-card.bg-green picture {
    margin-top: -18px !important;
  }

  @include respond-below(tablet) {
    .categories .card--default > a {
      display: block !important;
    }

    .industry__nav {
      width: 100%;
      overflow-x: auto;
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;

      /* IE 10+ */
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background: transparent;
        /* Chrome/Safari/Webkit */
      }

      nav {
        white-space: nowrap;
        box-shadow: none;
        padding-left: 0;

        a {
          margin-left: 16px;

          &:last-child {
            margin-right: 20px;
          }
        }
      }

      &.bg-offset::after {
        content: none;
      }
    }

    .client-card.bg-green picture {
      margin-top: -48px !important;
    }
  }

  .categories {
    article h2::after {
      content: "";
      display: block;
      width: 80px;
      height: 8px;
      border-radius: 8px;
      margin-top: 16px;
    }

    .categories__beauty::after {
      @extend .bg-yellow;
    }

    .categories__education::after {
      @extend .bg-orange;
    }

    .categories__wellbeing::after {
      @extend .bg-red;
    }

    .categories__professional::after {
      @extend .bg-green;
    }

    .categories__health::after {
      @extend .bg-blue;
    }

    .categories__trade::after {
      @extend .bg-purple;
    }

    .categories__creative::after {
      @extend .bg-light-grey;
    }

    .card--default {
      justify-content: flex-start;
    }

    .btn-outline {
      .animated-dot {
        display: none;
      }

      cursor: pointer;
    }

    .icon-title-wrap {
      position: relative;
      display: block;

      .animated-dot {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        animation: dot-animation 0.3s linear;
        width: 16px;
        height: 16px;
      }

      @keyframes dot-animation {
        from {
          transform: scale(0, 0);
        }

        to {
          transform: scale(1, 1);
        }
      }
    }

    .btn-outline:hover {
      .animated-dot {
        display: inline-block;
      }

      background-color: #fff;
      border-color: transparent;
    }
  }
}

.home-integrations {
  .card--default.card--showcase figure {
    min-width: 46px;
    margin-right: 17px;
  }
}

.home-repair {
  @include respond-below(tablet) {
    .custom-masonry {
      overflow: hidden;

      picture {
        margin-left: -11px !important;
      }
    }

    .industry-masonry article {
      margin-left: 20px;
      margin-right: 20px;
      width: inherit;

      & > ul {
        max-width: none;
      }
    }
  }
}

.tour-booking {
  @include respond-above(tablet) {
    .hero--default .fg-layer {
      position: absolute;
      left: -115px;
      width: 145%;
    }

    .custom-height {
      padding: 24px 24px 16px !important;
    }

    .ng-margin {
      margin-left: -24px !important;
    }
  }

  @include respond-below(mobile) {
    .hero--default .fg-layer {
      position: absolute;
      left: 13px;
      bottom: -50px;
    }

    .ng-margin {
      margin-left: -32px !important;
    }
  }

  @include respond-below(tablet) {
    .card--default .icon-title-wrap figure svg {
      width: 25px;
      height: 25px;
    }
  }
}

.wedding-boutiques {
  @include respond-below(tablet) {
    .masonry-image {
      position: relative;
      right: 12px;
    }
  }
}

.barber-shops {
  .hero--default {
    .fg-layer {
      bottom: -80px;

      @include respond-below(tablet) {
        bottom: -40px;
      }
    }

    .app-review-wrap {
      margin-top: 82px;

      @include respond-below(tablet) {
        margin-top: 42px;
      }
    }
  }

  .use-setmore-wrap {
    div {
      max-width: 1160px;
    }

    article {
      margin: 0;

      @include respond-above(desktop) {
        margin-left: 140px;
      }
    }

    picture {
      img {
        margin-top: 0;
      }
    }
  }

  .customise-wrap {
    box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
  }

  .faq-section.bg-offset-top::before {
    background-color: #fff;
  }

  // .faq-section {
  //   input:checked ~ .faq-point {
  //     max-height: 380px;
  //     @include respond-below(tablet) {
  //       max-height: 600px;
  //     }
  //   }
  //   .faq {
  //     background-color: #fff;
  //     width: 560px;
  //     @include respond-below(tablet) {
  //       width: 335px;
  //     }
  //   }
  //   .faq-ques {
  //     position: relative;
  //     width: 100%;
  //     padding: 26px 64px 30px 20px;
  //     &::after {
  //       position: absolute;
  //       top: 50%;
  //       right: 25px;
  //       transform: translateY(-50%) rotate(135deg);
  //     }
  //     @include respond-below(tablet) {
  //       width: 100%;
  //       padding: 26px 50px 30px 20px;
  //     }
  //   }
  //   input:checked {
  //     + .faq-ques {
  //       color: $black;
  //       &::after {
  //         top: 50%;
  //         transform: translateY(-50%) rotate(-45deg);
  //       }
  //     }
  //     ~ .faq-point {
  //       padding-bottom: 32px;
  //       @include respond-below(tablet) {
  //         padding-bottom: 24px;
  //       }
  //     }
  //   }
  // }
  // .faq-section.bg-offset::after {
  //   bottom: 565px;
  //   @include respond-below(tablet) {
  //     bottom: 841px;
  //   }
  // }
  .card--masonry {
    .client-card {
      padding-bottom: 24px;

      a {
        text-decoration: underline;
      }
    }
  }

  @include respond-above(tablet_max) {
    .cta-contrast.has-image.has-img-offset {
      picture {
        right: 0;
        width: 660px;
      }
    }
  }

  .cta-contrast {
    @include respond-above(tablet_max) {
      min-height: 368px;
    }
  }
}

//accountants industry page

.accountants {
  .cta-contrast {
    @include respond-above(tablet_max) {
      min-height: 370px;
    }
  }

  .industry-features {
    @include respond-above(tablet_max) {
      &.bg-offset {
        &::after {
          height: 335px;
        }
      }
    }
  }
}

// Doctors industry page
.doctors {
  .industry-features {
    h2 {
      max-width: 1030px;
    }
  }

  @include respond-above(tablet_max) {
    .hover-link-blue {
      display: inline;
    }
  }
}

// Medical appointment scheduling software page
.medical {
  @include respond-below(tablet) {
    .fg-layer {
      aspect-ratio: 335/368 !important;
    }

    .client-card > figure > picture img {
      height: 356px;
    }
  }

  .industry-features {
    h2 {
      max-width: 1030px;
    }
  }
}

//ITprofessional page
.ITprofessional {
  .cta-contrast {
    @include respond-above(tablet_max) {
      min-height: 330px;
    }
  }
}

.teachers {
  .masonry-image {
    width: 270px;
    margin-left: auto;
    margin-right: auto;
  }

  @include respond-above(tablet) {
    .review-card p {
      margin-bottom: 20px;
    }
  }

  @include respond-below(tablet) {
    .cta-contrast.has-image {
      padding-bottom: 27px;
    }
  }
}

// INDUSTRIES COMMON CLASSES (uncomment it in base/_common.scss if used in more pages)
// Negative-margin
.ng-margin {
  margin-left: -24px;
}

.chiropactor {
  @include respond-below(tablet) {
    .imgholder-basic {
      aspect-ratio: 335/368 !important;
    }

    .card--masonry .client-card .quot-glyph {
      margin-top: 3px;
    }

    .ng-margin {
      margin-left: -32px;
    }

    .hero--default .fg-layer {
      bottom: -88px;
    }

    .use-setmore-wrap picture img {
      margin-top: -32px;
    }
  }
}

.counseling {
  @include respond-above(tablet) {
    .cta-contrast.has-image {
      padding-top: 98px;
      padding-bottom: 80px;
    }
  }

  .card--masonry .client-card .quot-glyph {
    margin-top: 3px;
  }

  @include respond-below(tablet) {
    .card--masonry .client-card figure picture img {
      width: auto;
      margin-left: -12px;
    }
  }
}

.dental-page {
  .card--masonry .client-card .quot-glyph {
    margin-top: 2px;
  }

  @include respond-below(tablet) {
    .card--masonry .client-card img {
      height: 356px;
    }
  }

  .cta-contrast.has-image {
    h2 {
      font-size: 32px;
      line-height: 40px;

      @include respond-below(tablet) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  @include respond-above(tablet_max) {
    .customise-wrap.has-content-right {
      img {
        aspect-ratio: 600/532;
      }
    }
  }
}

.embassies {
  @include respond-above(tablet) {
    .industry-masonry {
      h2 {
        padding: 0px 130px;
      }

      &.bg-offset-lg::after {
        top: 0;
        height: 300px;
      }

      .card--masonry .stats-card p.even {
        margin-top: 18px;
      }

      .card--masonry .client-card {
        picture {
          img {
            width: auto;
            margin-bottom: -68px;
            margin-top: -7px;
          }
        }
      }
    }

    .faq-section {
      background-color: #f6f8fc !important;
      margin-bottom: 0px !important;
      padding-bottom: 160px;
    }
  }

  @include respond-below(tablet) {
    .hero--default .fg-layer {
      left: -63px;
      bottom: 0px;
      top: -6px;
      width: 120%;
    }

    .card--masonry .client-card {
      picture {
        img {
          width: auto;
          margin-left: -29px;
          margin-top: -10px;
        }
      }
    }

    .faq-section {
      background-color: #f6f8fc !important;
      margin-bottom: 0px !important;
      padding-bottom: 80px;
    }
  }
}
.physical-therapy,
.clinics,
.vaccination-scheduling,
.veterinary {
  .customise-wrap {
    background-color: $black-200 !important;
    @include respond-above(tablet) {
      justify-content: space-between;
      article {
        padding-block: 80px;
        h2 {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
    @include respond-below(tablet) {
      flex-direction: column-reverse;
      padding-bottom: 20px;
      article {
        padding: 20px 20px 40px;
        h2 {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
}

.physical-therapy {
  .cta-contrast {
    min-height: 440px;
  }

  .use-setmore-wrap {
    div {
      max-width: 1160px;
    }

    article {
      margin: 0;

      @include respond-above(desktop) {
        margin-left: 140px;
      }
    }

    picture {
      img {
        margin-top: 0;
      }
    }

    .video-highlight {
      position: relative;
      border-radius: 8px;
      width: 560px;
      height: 314px;

      @include respond-above(desktop) {
        margin-top: -40px;
      }

      @include respond-below(tablet) {
        width: 100%;
        height: 100%;
      }

      .video-timg {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: transparent;

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          border-radius: 8px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: opacity 0.3s;
        }

        &:focus {
          outline: none;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .video-timg:hover {
        &::before {
          opacity: 0.25;
          cursor: pointer;
        }

        path {
          transform: scale(1.3);
          transform-origin: 40px 40px;
        }
      }

      .video-timg:active {
        &::before {
          opacity: 0.5;
        }

        circle {
          fill-opacity: 1;
          fill: #fff;
        }
      }

      #videoUtube {
        display: none;
        border-radius: 8px;

        @include respond-below(tablet) {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 99.8%;
          height: 100%;
          z-index: 1;
        }
      }

      .play-btn {
        width: 80px;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        cursor: pointer;
        border: 0;
        padding: 0;
        background-color: transparent;
        outline: none;
      }
    }

    .is-played {
      @include respond-below(tablet) {
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
      }
    }
  }

  .industry-masonry {
    .card--masonry {
      .client-card {
        picture {
          img {
            width: auto;
            margin-bottom: 0px;
            margin-top: -70px;
            margin-left: -25px;

            @include respond-below(tablet) {
              width: auto;
              margin-left: -29px;
              margin-top: -63px;
            }
          }
        }
      }

      a {
        text-decoration: underline;
      }
    }

    @include respond-below(tablet) {
      .people-love-setmore {
        margin: 0px 80px;
      }
    }
  }

  .faq-section {
    background-color: #f6f8fc !important;
    margin-bottom: 0px !important;
    padding-bottom: 160px;

    @include respond-below(tablet) {
      padding-bottom: 80px;

      h2 {
        margin-bottom: 40px !important;
      }
    }
  }

  @include respond-below(tablet) {
    .hero--default .fg-layer {
      left: -4px;
      bottom: 0px;
      top: -22px;
      width: 105%;
    }

    .cta-contrast {
      min-height: 0px;
    }
  }
}

.massage-therapy,
.online-tutoring,
.government {
  .faq-section {
    background-color: #f6f8fc;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 6.5%;
      left: 0;
      background-color: #f6f8fc;
      z-index: -1;
    }
  }
}

.online-tutoring .cta-contrast.has-image.has-img-offset picture {
  top: unset;
}

@include respond-below(tablet) {
  .massage-therapy .client-card.bg-yellow {
    padding-bottom: 0 !important;
  }
}

.pilates {
  .industry-masonry {
    .people-love-setmore .heart-glyph {
      font-size: 54px;
      line-height: 64px;

      @include respond-below(tablet) {
        font-size: 40px;
        display: block;
      }
    }

    .card--masonry {
      .client-card {
        picture {
          img {
            width: auto;
            margin-bottom: 0px;
            margin-top: 0px;
            margin-left: -25px;

            @include respond-below(tablet) {
              margin-left: -30px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  .faq-section {
    background-color: #f6f8fc !important;
    margin-bottom: 0px !important;
    padding-bottom: 80px;

    @include respond-below(tablet) {
      h2 {
        margin-bottom: 40px !important;
      }
    }
  }

  @include respond-below(tablet) {
    .use-setmore-wrap picture img {
      margin-top: -130px;
    }

    .hero--default {
      padding-bottom: 132px;

      .fg-layer {
        left: 0px;
        bottom: 0px;
        top: 3px;
        width: 100%;
      }
    }

    .cta-contrast {
      min-height: 0px;
    }
  }
}

.no-webp {
  .video-timg video {
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    height: 100%;
  }

  .ind-consulting {
    .video-timg video {
      background-image: url("https://assets.setmore.com/website/v2/images/industry-pages/consulting/laura-gomez-setmore.jpg");
    }
  }

  .ind-personal-trainer {
    .video-timg video {
      background-image: url("https://assets.setmore.com/website/v2/images/industry-pages/personal-trainer/lendale-setmore.jpg");
    }
  }

  .ind-legal {
    .video-timg video {
      background-image: url("https://assets.setmore.com/website/v2/images/industry-pages/legal/legal-offices-of-athar-a-khan.jpeg");
    }
  }

  .ind-photography {
    .video-timg video {
      background-image: url("https://assets.setmore.com/website/v2/images/industry-pages/photography/square-foot-photography.jpeg");
    }
  }

  .veterinary {
    .video-timg video {
      background-image: url("https://assets.setmore.com/website/v2/images/industry-pages/veterinary/girl-holding-cat-in-her-hand.jpg");
    }
  }

  .ind-pet-service {
    .video-timg video {
      background-image: url("https://assets.setmore.com/website/v2/images/industry-pages/pet-grooming/aaron-with-dog.jpg");
    }
  }

  .cleaning-services {
    .video-timg video {
      background-image: url("https://assets.setmore.com/website/v2/images/industry-pages/cleaning-services/happy-client-talking-about-setmore-app.jpg");
    }
  }
}

//Adding new styles here v2 design
.retail-scheduling-software {
  @include respond-above(tablet) {
    .hero--default {
      padding-bottom: 0px !important;

      h1 {
        font-size: 56px;
        line-height: 64px;
      }

      .layered-images-wrap {
        vertical-align: top;
      }
    }

    .faq-section {
      h2 {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }

  @include respond-below(tablet) {
    .hero--default {
      h1 {
        font-size: 40px;
        line-height: 48px;
      }
    }

    .people-love-setmore {
      span {
        font-size: 40px !important;
        line-height: 48px !important;
        display: inline-block !important;
      }
    }

    .industry-features {
      h2 {
        font-size: 40px !important;
        line-height: 48px !important;
      }
    }

    .faq-section {
      h2 {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
}

.massage-therapy {
  @include respond-below(tablet) {
    .hero--default article h1 {
      font-size: 40px !important;
      line-height: 48px !important;
    }

    .faq-section {
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
      }

      .faq:not(:last-child) {
        margin-bottom: 24px !important;
      }
    }

    .industry-features.bg-offset:after {
      height: calc(100% - 238px) !important;
    }
  }

  @include respond-above(tablet) {
    .hero--default {
      h1 {
        font-size: 56px;
        line-height: 64px;
      }
    }

    .hero--default figure picture:nth-child(2) img {
      bottom: -40px;
    }

    .hero--default {
      padding-bottom: 40px !important;
    }

    .faq-section {
      h2 {
        font-size: 40px;
        line-height: 48px;
      }
    }

    .industry-features.bg-offset:after {
      height: calc(100% - 288px) !important;
    }

    .card--default.card--feature li.gutter-space:nth-child(2) {
      margin-bottom: 64px;
    }
  }
}

.spa {
  // mobile
  @include respond-below(tablet) {
    .hero--default article h1 {
      font-size: 40px !important;
      line-height: 48px !important;
    }

    .faq-section {
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
      }

      .faq:not(:last-child) {
        margin-bottom: 24px !important;
      }
    }

    .people-love-setmore {
      span {
        display: inline-block !important;
        font-size: 40px !important;
        line-height: 48px !important;
      }
    }

    .industry-features h2 {
      font-size: 40px !important;
      line-height: 48px !important;
    }

    .industry-features.bg-offset:after {
      height: calc(100% - 268px) !important;
    }
  }

  // desktop
  @include respond-above(tablet) {
    .hero--default {
      h1 {
        font-size: 56px;
        line-height: 64px;
      }
    }

    .hero--default figure picture:nth-child(2) img {
      bottom: -40px;
    }

    .hero--default {
      padding-bottom: 40px !important;
    }

    .cta-contrast.has-image.variable-color {
      min-height: 368px;
    }

    .industry-features.bg-offset:after {
      height: calc(100% - 286px) !important;
    }

    .faq-section {
      h2 {
        font-size: 40px;
        line-height: 48px;
      }
    }

    .card--default.card--feature li.gutter-space:nth-child(2) {
      margin-bottom: 64px;
    }
  }
}

.veterinary {
  @include respond-above(tablet) {
    .hero--default article h1 {
      font-size: 40px !important;
      line-height: 48px !important;
    }

    .use-setmore-wrap h2 {
      font-size: 32px;
      line-height: 40px;
    }

    .industry-features h2,
    .faq-section h2 {
      font-size: 40px !important;
      line-height: 48px !important;
    }

    &.industry-page-with-trust-pilot .industry-masonry.bg-offset:after {
      height: 456px !important;
    }

    .industry-features.bg-offset:after {
      height: calc(100% - 256px) !important;
    }
    .cta-contrast.has-img-offset h2 {
      margin-bottom: 64px !important;
    }
  }

  @include respond-below(tablet) {
    .use-setmore-wrap h2 {
      font-size: 24px;
      line-height: 32px;
    }

    .faq-section h2 {
      font-size: 32px;
      line-height: 40px;
    }

    &.industry-with-video .use-setmore-wrap .video-highlight {
      margin-top: -60px;
    }

    &.industry-page-with-trust-pilot .industry-masonry.bg-offset:after {
      height: 508px !important;
    }

    .industry-features.bg-offset:after {
      height: calc(100% - 240px) !important;
    }
  }

  .card--masonry .stats-card cite > a {
    border-bottom: 1px solid;
  }
  &.industry-with-video .use-setmore-wrap .video-highlight .video-timg video {
    width: 100% !important;
    position: static;
    margin: 0px;
  }
}

.government {
  @include respond-below(tablet) {
    .hero--default article h1 {
      font-size: 32px !important;
      line-height: 40px !important;
    }

    .faq-section {
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
      }

      .faq:not(:last-child) {
        margin-bottom: 24px !important;
      }
    }

    &.industry-page-with-trust-pilot .industry-masonry.bg-offset:after {
      height: 510px !important;
    }

    .industry-features.bg-offset:after {
      height: calc(100% - 270px) !important;
    }
  }

  @include respond-above(tablet) {
    .hero--default {
      padding-bottom: 40px !important;

      h1 {
        font-size: 56px;
        line-height: 64px;
      }

      figure picture:nth-child(2) img {
        bottom: -40px;
      }
    }

    .card--feature.has-three-col li:nth-child(-n + 3) {
      margin-bottom: 64px !important;
    }

    &.industry-page-with-trust-pilot .industry-masonry.bg-offset:after {
      height: 455px !important;
    }

    .industry-features {
      h2 {
        font-size: 40px !important;
        line-height: 48px !important;
      }

      &.bg-offset:after {
        height: calc(100% - 300px) !important;
      }
    }

    .cta-contrast.has-img-offset h2 {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }

  .card--masonry .review-card {
    cite {
      display: none;
    }

    & > p {
      margin-bottom: 0 !important;
    }
  }
}

.electrical {
  @include respond-below(tablet) {
    .hero--default article h1 {
      font-size: 32px !important;
      line-height: 40px !important;
    }

    .use-setmore-wrap h2 {
      font-size: 24px !important;
      line-height: 32px !important;
    }

    .faq-section {
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
      }

      .faq:not(:last-child) {
        margin-bottom: 24px !important;
      }
    }

    .industry-features.bg-offset:after {
      height: calc(100% - 205px) !important;
    }
  }

  @include respond-above(tablet) {
    .hero--default {
      padding-bottom: 40px !important;

      h1 {
        font-size: 56px;
        line-height: 64px;
      }

      figure picture:nth-child(2) img {
        bottom: -40px;
      }
    }

    .use-setmore-wrap h2 {
      font-size: 32px !important;
      line-height: 40px !important;
    }

    .faq-section {
      h2 {
        font-size: 40px !important;
        line-height: 48px !important;
      }
    }

    .industry-features.bg-offset:after {
      height: calc(100% - 285px) !important;
    }
  }
  .card--masonry .stats-card cite > a {
    border-bottom: 1px solid;
  }
}

.cleaning-services {
  @include respond-above(tablet) {
    .hero--default {
      padding-bottom: 0 !important;

      .layered-images-wrap {
        vertical-align: top;
      }

      article h1 {
        font-size: 56px !important;
        line-height: 64px !important;
      }
    }

    .use-setmore-wrap h2 {
      font-size: 32px;
      line-height: 40px;
    }

    .industry-features h2 {
      font-size: 40px !important;
      line-height: 48px !important;
    }

    .faq-section h2 {
      font-size: 56px !important;
      line-height: 64px !important;
    }

    &.industry-page-with-trust-pilot .industry-masonry.bg-offset:after {
      height: 456px !important;
    }

    .industry-features.bg-offset:after {
      height: calc(100% - 256px) !important;
    }
    .cta-contrast.has-img-offset h2 {
      margin-bottom: 64px !important;
    }
  }

  @include respond-below(tablet) {
    .hero--default article h1 {
      font-size: 40px;
      line-height: 48px;
    }

    &.industry-with-video .use-setmore-wrap .video-highlight {
      margin-top: -60px;
    }

    .use-setmore-wrap h2 {
      font-size: 24px;
      line-height: 32px;
    }

    .people-love-setmore {
      span {
        display: inline-block !important;
      }
    }

    .faq-section h2 {
      font-size: 40px;
      line-height: 48px;
    }

    &.industry-page-with-trust-pilot .industry-masonry.bg-offset:after {
      height: 508px;
    }

    .industry-features.bg-offset:after {
      height: calc(100% - 240px) !important;
    }
  }
  .card--masonry .stats-card cite > a {
    border-bottom: 1px solid;
  }
  &.industry-with-video .use-setmore-wrap .video-highlight .video-timg video {
    width: 100% !important;
    position: static;
    margin: 0px;
  }
}

.landscaping {
  @include respond-below(tablet) {
    .hero--default article h1,
    .use-setmore-wrap h2 {
      font-size: 40px !important;
      line-height: 48px !important;
    }
    .faq-section {
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
      }
      .faq:not(:last-child) {
        margin-bottom: 24px !important;
      }
    }
    &.industry-page-with-trust-pilot .industry-masonry.bg-offset:after {
      height: 510px !important;
    }
    .industry-features {
      h2 {
        font-size: 24px !important;
        line-height: 32px !important;
      }
      &.bg-offset:after {
        height: calc(100% - 220px) !important;
      }
    }
  }

  @include respond-above(tablet) {
    .hero--default {
      padding-bottom: 40px !important;
      h1 {
        font-size: 56px;
        line-height: 64px;
      }
      figure picture:nth-child(2) img {
        bottom: -40px;
      }
    }
    .use-setmore-wrap h2 {
      font-size: 40px !important;
    }
    .card--feature.has-three-col li:nth-child(-n + 3) {
      margin-bottom: 64px !important;
    }
    .cta-contrast.has-image a {
      margin-top: 16px !important;
    }

    &.industry-page-with-trust-pilot .industry-masonry.bg-offset:after {
      height: 450px !important;
    }
    .faq-section h2 {
      font-size: 40px !important;
      line-height: 48px !important;
    }
    .industry-features {
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
      }
      &.bg-offset:after {
        height: calc(100% - 240px) !important;
      }
    }
  }
}

.customer-service {
  .card--masonry .review-card cite {
    display: none;
  }

  @include respond-above(tablet) {
    .hero--default {
      padding-bottom: 0 !important;

      h1 {
        font-size: 56px;
        line-height: 64px;
      }

      .layered-images-wrap {
        vertical-align: top;
      }
    }

    .use-setmore-wrap h2,
    .industry-features h2 {
      font-size: 40px !important;
      line-height: 48px !important;
    }

    &.industry-page-with-trust-pilot .industry-masonry.bg-offset:after {
      height: 452px !important;
    }

    .industry-features.bg-offset::after {
      height: calc(100% - 256px) !important;
    }
  }

  @include respond-below(tablet) {
    &.industry-page-with-trust-pilot .industry-masonry.bg-offset:after {
      height: 508px;
    }

    .industry-features.bg-offset::after {
      height: calc(100% - 240px) !important;
    }
  }
}

.industry-muas,
.plumbing,
.music-lessons,
.library {
  @include respond-above(tablet) {
    .hero--default {
      padding-bottom: 0 !important;
      padding-top: 161px !important;

      h1 {
        font-size: 56px;
        line-height: 64px;
      }
      .fg-layer {
        bottom: -40px;
      }
      article {
        top: -105px;
      }

      .layered-images-wrap {
        vertical-align: top;
      }
    }

    .container.has-img-offset {
      height: 375px !important;
      min-height: 375px !important;
    }

    .container.has-img-offset h2 {
      font-size: 24px !important;
      line-height: 32px !important;
    }

    .industry-features h2 {
      font-size: 40px !important;
      line-height: 48px !important;
    }

    .faq-section h2 {
      font-size: 40px !important;
      line-height: 48px !important;
    }

    .use-setmore-wrap h2 {
      font-size: 32px !important;
      line-height: 48px !important;
    }

    &.industry-page-with-trust-pilot .industry-masonry.bg-offset:after {
      height: 452px !important;
    }

    .industry-features.bg-offset::after {
      height: calc(100% - 256px) !important;
    }
  }

  @include respond-below(tablet) {
    .use-setmore-wrap {
      h2 {
        font-size: 24px !important;
        line-height: 32px !important;
      }
    }
    &.industry-page-with-trust-pilot .industry-masonry.bg-offset:after {
      height: 508px;
    }

    .industry-features.bg-offset::after {
      height: calc(100% - 240px) !important;
    }

    .faq-section h2 {
      font-size: 32px !important;
      line-height: 40px !important;
    }
  }
}
.library {
  .card--default {
    p {
      color: #303a47;
    }
  }
  .cta-contrast.has-image {
    h2 {
      margin-bottom: 32px !important;
    }
    a span {
      min-width: fit-content;
    }
    .btn-primary {
      padding: 12px 38px;
    }
    .btn-secondary {
      padding: 12px 32px;
      @include respond-above(tablet) {
        margin-left: 24px !important;
      }
    }
    img {
      max-height: 460px;
    }
  }
  .cta-w-image {
    article {
      .btn-primary {
        padding: 12px 32px;
      }
      &:nth-child(1) a {
        background-color: transparent !important;
        border: 1px solid #1b3d32 !important;
        color: #1b3d32 !important;
      }
      &:nth-child(2) a {
        background-color: #1b3d32 !important;
        color: #ffffff !important;
        &:hover {
          background-color: #0f2922 !important;
        }
      }
    }
  }
  .industry-features a {
    padding: 24px 24px 32px;
  }
  @include respond-above(tablet) {
    .hero--default {
      .fg-layer {
        bottom: -50px;
      }
      article {
        top: -97px;
      }
    }
    .use-setmore-wrap {
      padding-bottom: 146px !important;
      article {
        margin-left: 86px;
        h2 {
          font-size: 40px !important;
          line-height: 48px !important;
        }
      }
      img {
        width: 670px;
        height: 414px;
      }
    }
    .card--masonry {
      li:last-child {
        padding: 16px 24px 32px;
        p {
          margin-top: 16px !important;
        }
      }
    }
    section:nth-of-type(8) {
      h2 {
        font-size: 32px !important;
      }
    }
    .cta-w-image {
      article {
        padding: 80px 100px !important;
      }
    }
  }
  @include respond-below(tablet) {
    .people-love-setmore {
      display: flex;
      justify-content: center;
      gap: 4px;
    }
    h1 {
      margin-bottom: 16px !important;
    }
    .cta-w-image {
      h2 {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
}
.music-lessons {
  @include respond-above(tablet) {
    .hero--default {
      .fg-layer {
        bottom: -50px;
      }
      article {
        top: -97px;
      }
    }

    .container.has-img-offset h2 {
      font-size: 32px !important;
      line-height: 40px !important;
    }
  }
}

.clinics {
  .use-setmore-wrap {
    > div {
      max-width: 1160px !important;
      justify-content: space-between !important;
    }
  }
  .use-setmore-wrap h2 {
    font-size: 32px !important;
    line-height: 40px !important;
  }
  .video-highlight {
    filter: none !important;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1),
      0 4px 8px 0 rgba(48, 58, 71, 0.14);
    border: solid 4px #f5f8fa;
    .video-timg {
      &::after {
        display: none !important;
      }
    }
  }
  @include respond-above(tablet) {
    .cta-contrast.has-image {
      .btn-primary {
        padding: 12px 32px !important;
        min-width: 100px !important;
      }
      a {
        &:last-child {
          padding: 12px 36px !important;
        }
      }
    }
    .hero--default {
      padding-bottom: 0 !important;
      h1 {
        font-size: 56px;
        line-height: 64px;
      }
      .layered-images-wrap {
        vertical-align: top;
      }
    }
    .people-love-setmore {
      font-size: 56px !important;
      line-height: 64px !important;
    }
  }
  @include respond-below(tablet) {
    .video-highlight {
      margin-bottom: 10px !important;
      top: -20px;
    }
    .use-setmore-wrap.md\:pt-5 {
      padding: 0px 0px 80px 0px !important;
    }
    .use-setmore-wrap h2 {
      margin-top: 24px !important;
    }
    .cta-w-image {
      article {
        padding-bottom: 20px !important ;
      }
    }
  }
}

.hvac,
.lashes-eyebrows {
  @include respond-above(desktop_sm) {
    .cta-w-image {
      article {
        padding: 80px 100px !important;
        a {
          padding: 12px 32px;
        }
      }
    }
  }
  @include respond-above(tablet) {
    .hero--default {
      padding-bottom: 0 !important;
      h1 {
        font-size: 56px;
        line-height: 64px;
      }
      .layered-images-wrap {
        vertical-align: top;
      }
    }
    .use-setmore-wrap {
      div {
        justify-content: space-around;
      }
      img {
        width: 560px !important;
        height: 378px !important;
      }
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
      }
    }
  }
  @include respond-below(tablet) {
    .use-setmore-wrap {
      picture {
        display: flex;
        justify-content: center;
        img {
          aspect-ratio: 385/255 !important;
          width: 100% !important;
          max-width: calc(100% - 34px);
        }
      }
    }
    .use-setmore-wrap h2 {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }
  .product-card {
    background-color: #fff !important;
    border: none !important;
    padding: 24px 24px 32px !important;
  }
}

.clinics,
.hvac {
  @include respond-above(mobile) {
    .industry-features h2 {
      font-size: 40px !important;
      line-height: 48px !important;
    }
  }
  @include respond-above(tablet) {
    .faq-section {
      h2 {
        font-size: 40px !important;
        line-height: 48px !important;
      }
    }
    .industry-features {
      .card--feature.has-three-col {
        a {
          padding: 24px 24px 32px;
        }
      }
    }
    .cta-contrast.has-image {
      a {
        padding: 12px 32px;
        &:last-child {
          margin-left: 24px !important;
        }
        span {
          min-width: 0 !important;
        }
      }
    }
  }
  @include respond-below(tablet) {
    .hero--default {
      h1 {
        font-size: 40px;
        line-height: 48px;
      }
    }
    .faq-section {
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
      }
    }
    .industry-features h2 {
      font-size: 32px !important;
      line-height: 40px !important;
    }
  }
}

.lashes-eyebrows {
  .industry-features.bg-offset:after {
    height: calc(100% - 285px) !important;
  }
  .grid--default.hero--default::after {
    height: 50px !important;
  }
  @include respond-above(tablet) {
    section {
      &:nth-of-type(6) {
        h2 {
          margin-bottom: 40px !important;
        }
      }
    }
    .cta-contrast {
      min-height: auto !important;
      article {
        a {
          margin-top: 0px !important;
        }
        .btn-primary span {
          min-width: auto !important;
        }
        .btn-secondary {
          margin-left: 24px !important;
        }
      }
    }
    .hero--default {
      h1 {
        font-size: 40px;
        line-height: 48px;
      }
    }
    .use-setmore-wrap {
      div {
        justify-content: space-evenly;
        margin-bottom: 40px;
      }
    }
    h2,
    .use-setmore-wrap h2,
    .people-love-setmore * {
      font-size: 40px !important;
      line-height: 48px !important;
    }
  }
  @include respond-below(tablet) {
    .hero--default {
      h1 {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 16px !important;
      }
    }
    .use-setmore-wrap h2 {
      font-size: 32px !important;
      line-height: 40px !important;
    }
    .people-love-setmore > span {
      display: inline !important;
    }
  }
}
