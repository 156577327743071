// Set a flex env
.fx {
  display: flex;

  // flex wrap enable
  &--w {
    flex-wrap: wrap;
  }

  // direction column
  &--c {
    flex-direction: column;
  }

  // direction column reverse
  &--cr {
    flex-direction: column-reverse;
  }

  // center vertical content distribution
  &--cv {
    align-items: center;
    align-content: center;
  }

  // space between content distribution
  &--sb {
    justify-content: space-between;
  }

  // space around content distribution
  &--sa {
    justify-content: space-around;
  }

  // center horizontal content distribution
  &--ch {
    justify-content: center !important;
  }

  // to left content distribution
  &--tl {
    justify-content: flex-start !important;
  }

  // to right content distribution
  &--tr {
    justify-content: flex-end;
  }

  // no stretch content distribution
  &--ns {
    align-items: flex-start;
  }

  &--tb {
    align-items: flex-end;
  }

  &--bl {
    align-items: baseline;
  }

  &--o-1 {
    order: 1;
  }

  &--o-2 {
    order: 2;
  }

  &--o-3 {
    order: 3;
  }

  &--g-1 {
    flex-grow: 1;
  }
}

@include respond-below(tablet) {
  .md\:fx {
    display: flex;

    &--w {
      flex-wrap: wrap;
    }

    &--c {
      flex-direction: column;
    }

    &--o-1 {
      order: 1;
    }

    &--o-2 {
      order: 2;
    }
  }
}

/*
*/

$unit: 8px;

// --------------------------------------- //
// Margin and Padding
// --------------------------------------- //
@for $i from 0 through 10 {
  $value: $i * $unit;

  // Margin
  // _________________________
  .mt-#{$i} {
    margin-top: $value !important;
  }

  .mb-#{$i} {
    margin-bottom: $value !important;
  }

  .ml-#{$i} {
    margin-left: $value !important;
  }

  .mr-#{$i} {
    margin-right: $value !important;
  }

  .mx-#{$i} {
    margin-right: $value !important;
    margin-left: $value !important;
  }

  .my-#{$i} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }

  // Padding
  // _________________________
  .pt-#{$i} {
    padding-top: $value !important;
  }

  .pb-#{$i} {
    padding-bottom: $value !important;
  }

  .pl-#{$i} {
    padding-left: $value !important;
  }

  .pr-#{$i} {
    padding-right: $value !important;
  }

  .px-#{$i} {
    padding-right: $value !important;
    padding-left: $value !important;
  }

  .py-#{$i} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }
}

// Other Margins
// _________________________
.mb-2\.5 {
  margin-bottom: 20px;
}

// --------------------------------------- //
// Screen: Desktop
// --------------------------------------- //
@include respond-above(tablet) {
  @for $i from 0 through 10 {
    $value: $i * $unit;

    // Margin
    // _________________________
    .lg\:mt-#{$i} {
      margin-top: $value !important;
    }

    .lg\:mb-#{$i} {
      margin-bottom: $value !important;
    }

    .lg\:ml-#{$i} {
      margin-left: $value !important;
    }

    .lg\:mr-#{$i} {
      margin-right: $value !important;
    }

    .lg\:mx-#{$i} {
      margin-right: $value !important;
      margin-left: $value !important;
    }

    .lg\:my-#{$i} {
      margin-top: $value !important;
      margin-bottom: $value !important;
    }

    // Padding
    // _________________________
    .lg\:pt-#{$i} {
      padding-top: $value !important;
    }

    .lg\:pb-#{$i} {
      padding-bottom: $value !important;
    }

    .lg\:pl-#{$i} {
      padding-left: $value !important;
    }

    .lg\:pr-#{$i} {
      padding-right: $value !important;
    }

    .lg\:px-#{$i} {
      padding-right: $value !important;
      padding-left: $value !important;
    }

    .lg\:py-#{$i} {
      padding-top: $value !important;
      padding-bottom: $value !important;
    }
  }

  // Other Margins
  // _________________________
  //Margin --- top
  .lg\:mt-1\.5 {
    margin-top: 12px; // 8 * 1.5
  }

  .lg\:mt-2\.5 {
    margin-top: 20px; // 8 * 2.5
  }

  .lg\:mt-7\.5 {
    margin-top: 60px !important; // 8 * 7.5;
  }

  .lg\:mt-11 {
    margin-top: 88px !important;
  }

  .lg\:mt-15 {
    margin-top: 120px !important;
  }

  .lg\:mt-18 {
    margin-top: $unit * 18 !important;
  }

  .lg\:mt-19 {
    margin-top: $unit * 19 !important;
  }

  .lg\:mt-20 {
    margin-top: $unit * 20 !important;
  }

  .lg\:mt-21 {
    margin-top: $unit * 21 !important;
  }

  //Margin --- bottom
  .lg\:mb-1\.5 {
    margin-bottom: 12px; // 8 * 1.5
  }

  .lg\:mb-2\.5 {
    margin-bottom: 20px; // 8 * 2.5
  }

  .lg\:mb-4\.5 {
    margin-bottom: 36px; // 8 * 4.5
  }

  .lg\:mb-11 {
    margin-bottom: 88px !important;
  }

  .lg\:mb-15 {
    margin-bottom: 120px !important;
  }

  .lg\:mb-18 {
    margin-bottom: $unit * 18 !important;
  }

  .lg\:mb-19 {
    margin-bottom: $unit * 19 !important;
  }

  .lg\:mb-20 {
    margin-bottom: $unit * 20 !important;
  }

  .lg\:mb-21 {
    margin-bottom: $unit * 21 !important;
  }

  //Margin --- left
  .lg\:ml-12\.5 {
    margin-left: 100px !important;
  }

  //Margin --- right
  .lg\:mr-12\.5 {
    margin-right: 100px !important;
  }

  .lg\:mr-15 {
    margin-right: 120px !important;
  }

  // ------------------------------------
  //Negative margin
  //Margin --- top
  .lg\:-mt-4\.5 {
    margin-top: -36px !important; // 8 * 4.5
  }

  .lg\:-mt-5\.2 {
    margin-top: -42px !important;
  }

  .lg\:-mt-9 {
    margin-top: -90px !important;
  }

  .lg\:-mt-10 {
    margin-top: -80px !important;
  }

  //Margin --- bottom
  .lg\:-mb-4 {
    margin-bottom: -32px; // 8 * 4
  }

  .lg\:-mb-11 {
    margin-bottom: -110px;
  }

  //Margin --- left
  .lg\:-ml-5 {
    margin-left: -40px !important;
  }

  .lg\:-ml-8 {
    margin-left: -64px !important;
  }

  .lg\:-ml-9 {
    margin-left: -76px !important;
  }

  .lg\:-ml-10 {
    margin-left: -80px !important;
  }

  .lg\:-ml-12 {
    margin-left: -100px !important;
  }

  .lg\:-ml-12\.5 {
    margin-left: 130px !important;
  }

  // Other Padding
  // _________________________
  //Padding --- top
  .lg\:pt-2\.5 {
    padding-top: 20px; // 8 * 2.5
  }

  .lg\:pt-12\.5 {
    padding-top: 100px !important;
  }

  .lg\:pt-13\.5 {
    padding-top: 108px !important;
  }

  .lg\:pt-14 {
    padding-top: $unit * 14 !important;
  }

  .lg\:pt-15 {
    padding-top: $unit * 15 !important;
  }

  .lg\:pt-18 {
    padding-top: $unit * 18 !important;
  }

  .lg\:pt-19 {
    padding-top: $unit * 19 !important;
  }

  .lg\:pt-20 {
    padding-top: $unit * 20 !important;
  }

  //Padding --- bottom
  .lg\:pb-2\.5 {
    padding-bottom: 20px; // 8 * 2.5
  }

  .lg\:pb-12\.5 {
    padding-bottom: 100px !important;
  }

  .lg\:pb-13\.5 {
    padding-bottom: 108px !important;
  }

  .lg\:pb-14 {
    padding-bottom: $unit * 14 !important;
  }

  .lg\:pb-15 {
    padding-bottom: $unit * 15 !important;
  }

  .lg\:pb-18 {
    padding-bottom: $unit * 18 !important;
  }

  .lg\:pb-19 {
    padding-bottom: $unit * 19 !important;
  }

  .lg\:pb-20 {
    padding-bottom: $unit * 20 !important;
  }

  .lg\:pb-23\.5 {
    padding-bottom: 188px !important;
  }

  //Padding --- left
  .lg\:pl-2\.5 {
    padding-left: 20px !important; // 8 * 2.5
  }

  .lg\:pl-12\.5 {
    padding-left: 100px !important;
  }

  //Padding --- right
  .lg\:pr-2\.5 {
    padding-right: 20px !important; // 8 * 2.5
  }

  .lg\:pr-12\.5 {
    padding-right: 100px !important;
  }

  // Width
  // _________________________
  .lg\:maxw-xs-logo {
    max-width: 260px;
  }

  .lg\:maxw-xss {
    max-width: 280px;
  }

  .lg\:maxw-xs {
    max-width: 320px;
  }

  .lg\:maxw-sm {
    max-width: 360px;
  }

  .lg\:maxw-md {
    max-width: 460px;
  }

  .lg\:maxw-smd {
    max-width: 475px;
  }

  .lg\:maxw-mds {
    max-width: 480px;
  }

  .lg\:maxw-lg {
    max-width: 560px;
  }

  .lg\:maxw-xls {
    max-width: 720px;
  }

  .lg\:maxw-xl {
    max-width: 760px;
  }

  .lg\:maxw-2xls {
    max-width: 920px;
  }

  .lg\:maxw-2xl {
    max-width: 960px;
  }

  .lg\:maxw-3xl {
    max-width: 1440px;
  }

  // Display
  // _________________________
  .lg\:d-fx {
    display: flex;
  }

  .lg\:d-none {
    display: none !important;
  }

  // Text
  // _________________________
  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:w-auto {
    width: auto !important;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mr-auto {
    margin-right: auto;
  }
}

// --------------------------------------- //
// Screen: Tablet
// --------------------------------------- //
@include respond-below(tablet) {
  @for $i from 0 through 10 {
    $value: $i * $unit;

    // Margin
    // _________________________
    .md\:m-#{$i} {
      margin: $value !important;
    }

    .md\:mt-#{$i} {
      margin-top: $value !important;
    }

    .md\:mb-#{$i} {
      margin-bottom: $value !important;
    }

    .md\:ml-#{$i} {
      margin-left: $value !important;
    }

    .md\:mr-#{$i} {
      margin-right: $value !important;
    }

    .lg\:mx-#{$i} {
      margin-right: $value !important;
      margin-left: $value !important;
    }

    .lg\:my-#{$i} {
      margin-top: $value !important;
      margin-bottom: $value !important;
    }

    // Padding
    // _________________________
    .md\:pt-#{$i} {
      padding-top: $value !important;
    }

    .md\:pb-#{$i} {
      padding-bottom: $value !important;
    }

    .md\:pl-#{$i} {
      padding-left: $value !important;
    }

    .md\:pr-#{$i} {
      padding-right: $value !important;
    }

    .md\:px-#{$i} {
      padding-right: $value !important;
      padding-left: $value !important;
    }

    .md\:py-#{$i} {
      padding-top: $value !important;
      padding-bottom: $value !important;
    }
  }

  // Other margins
  // _________________________

  // Margin --- top
  .md\:mt-1\.5 {
    margin-top: 12px !important;
  }

  .md\:mt-2\.5 {
    margin-top: 20px !important;
  }

  // Margin --- bottom
  .md\:mb-1\.5 {
    margin-bottom: 12px !important;
  }

  .md\:mb-2\.5 {
    margin-bottom: 20px !important;
  }

  // Margin --- left
  .md\:ml-2\.5 {
    margin-left: 20px !important;
  }

  // Margin --- right
  .md\:mr-2\.5 {
    margin-right: 20px !important;
  }

  // Negative margins
  //--------------------
  .md\:m-0 {
    margin: 0px !important;
  }

  // Margin --- top
  .md\:-mt-4\.5 {
    margin-top: -36px !important; // 8 * 4.5
  }

  .md\:-mt-5 {
    margin-top: -$unit * 5 !important;
  }

  .md\:-mt-6 {
    margin-top: -$unit * 6 !important;
  }

  // Margin --- bottom
  .md\:-mb-5 {
    margin-bottom: -$unit * 5 !important;
  }

  .md\:-mb-6 {
    margin-bottom: -60px !important;
  }

  .md\:-mb-7\.5 {
    margin-bottom: -60px !important;
  }

  // Margin --- left
  .md\:-ml-2 {
    margin-left: -20px !important;
  }

  // Other paddings
  // _________________________

  // Padding --- top
  .md\:pt-2\.5 {
    padding-top: 20px !important;
  }

  .md\:pt-11 {
    padding-top: $unit * 11 !important;
  }

  .md\:pt-15 {
    padding-top: $unit * 15 !important;
  }

  // Padding --- bottom
  .md\:pb-2\.5 {
    padding-bottom: 20px !important;
  }

  .md\:pb-11 {
    padding-bottom: $unit * 11 !important;
  }

  // Padding --- right
  .md\:pr-2\.5 {
    padding-right: 20px !important;
  }

  // Padding --- left
  .md\:pl-2\.5 {
    padding-left: 20px !important;
  }

  // Width
  // _________________________
  .md\:w-100 {
    width: 100% !important;
  }

  .md\:w-75 {
    width: 75% !important;
  }

  .md\:w-50 {
    width: 50% !important;
  }

  // Display
  // _________________________
  .md\:d-fx {
    display: flex;
  }

  .md\:d-none {
    display: none !important;
  }

  .md\:d-block {
    display: block;
  }

  .md\:d-inline {
    display: inline;
  }

  // Text
  // _________________________
  .md\:text-center {
    text-align: center !important;
  }

  .md\:text-left {
    text-align: left !important;
  }

  .md\:neg-left {
    left: -30px !important;
  }

  .md\:container-reset {
    margin: 0 auto;
  }
}
// --------------------------------------- //
// Screen: Tablet alone
// --------------------------------------- //
@include respond-between(tablet, tablet_max) {
  .tablet-hide {
    display: none !important;
  }
}

@include respond-below(tablet) {
  .tablet-show {
    display: none !important;
  }
}

@include respond-above(tablet_max) {
  .tablet-show {
    display: none !important;
  }
}

// --------------------------------------- //
// For all screens
// --------------------------------------- //

// Margin
// _________________________
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.mr-auto {
  margin-right: auto !important;
}

// Border
// _________________________
.br-0 {
  border-radius: 0;
}

.br-4 {
  border-radius: 4px;
}

.br-8 {
  border-radius: 8px;
}
// BG
// _________________________
.bg-light {
  background-color: $color-bg !important;
}

.bg-white,
.md\:bg-white {
  background-color: $color-white;
}

.bg-orange {
  background-color: #f57f31 !important;
}

.bg-bright-orange {
  background-color: #ffb420;

  @include respond-below(tablet) {
    &.has-after::after {
      background-color: #ffb420;
    }
  }
}

.bg-purple {
  background-color: #8b54a6 !important;
}

.bg-yellow {
  background-color: #ffb41f !important;
}

.bg-red {
  background-color: #e04209 !important;
}

.bg-green {
  background-color: #068f86 !important;

  @include respond-below(tablet) {
    &.has-after::after {
      background-color: #068f86;
    }
  }
}

.bg-dark-green {
  background-color: #19af72 !important;
}

.bg-blue {
  background-color: $blue-500 !important;
}

.bg-grey {
  background-color: $black-300 !important;
}

.bg-dull-grey {
  background-color: #f5f8fa;
}

.bg-light-grey {
  background-color: #adbbd2;
}

.bg-light-blue-grey {
  background-color: #edf0f2 !important;
}

.bg-dark-grey {
  background-color: #303a47 !important;
}

.bg-almost-black {
  background-color: #191f29;
}

.text-grey {
  color: $black-600 !important;
}
.text-grey-800 {
  color: $grey-800 !important;
}

.text-black {
  color: $black-700 !important;
}

.text-lg-grey {
  color: $black-400 !important;
}

.text-light-grayish-blue {
  color: #8996a3;
}

.child--bg-white {
  > * {
    background-color: $color-white;
  }
}
//hover
.bg\:hover-white:hover {
  background-color: white;
  border-color: white !important;
  cursor: pointer;
  box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
    0 1px 4px 0 rgba(22, 45, 61, 0.1);
}

.active\:bg-yellow:active {
  @extend .bg-yellow;
}

.active\:bg-orange:active {
  @extend .bg-orange;
}

.active\:bg-red:active {
  @extend .bg-red;
}

.active\:bg-purple:active {
  @extend .bg-purple;
}

.active\:bg-green:active {
  @extend .bg-green;
}

.active\:bg-blue:active {
  @extend .bg-blue;
}

.active\:bg-light-grey:active {
  @extend .bg-light-grey;
}

.hover\:border-yellow:hover {
  border-color: #ffb41f !important;
  color: #ffb41f !important;
}

.hover\:border-orange:hover {
  border-color: #f57f31 !important;
  color: #f57f31 !important;
}

.hover\:border-red:hover {
  border-color: #e04209 !important;
  color: #e04209 !important;
}

.hover\:border-purple:hover {
  border-color: #8b54a6 !important;
  color: #8b54a6 !important;
}

.hover\:border-green:hover {
  border-color: #068f86 !important;
  color: #068f86 !important;
}

.hover\:border-blue:hover {
  border-color: $color-brand !important;
  color: $color-brand !important;
}

.hover\:border-light-grey:hover {
  border-color: #adbbd2 !important;
  color: #adbbd2 !important;
}

// Font
// _________________________

// weights
.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

// size
.fs-0 {
  font-size: 0 !important;
}

// style
.fs-italic {
  font-style: italic !important;
}

// Position
// _________________________
.pos-abs {
  position: absolute;

  &--l-0 {
    left: 0;
  }

  &--t-0 {
    top: 0;
  }

  &--b-0 {
    bottom: 0;
  }

  &--r-0 {
    right: 0;
  }
}

.pos-rel {
  position: relative;
}

.zi--1 {
  @include z-index(1);
}

// Width
// _________________________
.w-auto {
  width: auto !important;
}

.w-100 {
  width: 100% !important;
}

// Width
// _________________________
.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-brandcolor {
  color: $color-brand;
  &:hover {
    color: $blue-800;
  }
}

a.text-brandcolor {
  color: $green-500 !important;
  &:hover {
    color: $green-400 !important;
  }
}

.text-white {
  color: $white-900;
}

.text-underline {
  text-decoration: underline;
}

// Shadows
// _________________________
.boxshadow-none {
  box-shadow: none !important;
}

.hover-shadow {
  box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
    0 1px 4px 0 rgba(22, 45, 61, 0.1);
}

.light-shadow {
  box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
    0 1px 3px 0 rgba(48, 58, 71, 0.1);
}

// Display
// _________________________
.d-none {
  display: none !important;
}

.inline-block {
  display: inline-block;
}

.d-block {
  display: block !important;
}

// Asorted
// _________________________
.pointer {
  cursor: pointer !important;
}

.img-auto {
  width: auto;
  height: auto;
}

.pt-20 {
  padding-top: 160px;
}

.pb-20 {
  padding-bottom: 160px;
}

.pt-36 {
  padding-top: 288px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.events-none {
  pointer-events: none;
}
.word-break {
  word-break: break-all !important ;
}
