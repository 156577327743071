.affiliate-hero {
  img {
    max-width: 481px;
    height: auto;
  }

  @include respond-above(tablet) {
    img {
      margin-left: -40px;
    }
  }
}

.affiliate-form {
  .terms {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin-left: auto;
  }

  @include respond-below(tablet) {
    .terms {
      margin: 0 auto;
      max-width: 295px;
    }
  }
}

.form-cont {
  max-width: 460px;
  padding: 48px 80px;
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(22, 45, 61, 0.1),
    0px 14px 32px rgba(22, 45, 61, 0.08);
  border-radius: 8px;
  margin-left: auto;

  h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: #3b4c66;
    font-family: $primary-font;
  }

  input {
    display: block;
    max-width: 300px;
    width: 100%;
    padding: 0 0 11px 0;
    border: 0;
    border-bottom: 0.5px solid #d9e1ee;
    font-size: 16px;
    line-height: 24px;
    &::placeholder {
      color: #8996a3;
    }
  }

  button[type="submit"] {
    border: 0;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
  }

  label {
    position: relative;
  }

  .error-msg {
    display: none;
    width: 300px;
    margin-left: 24px;
    color: #dd2727;
    font-size: 12px;
    line-height: 19px;
    position: absolute;
    top: 35px;
    left: -24px;
  }

  .error {
    .error-msg {
      display: block;
    }
  }

  @include respond-below(tablet) {
    padding: 20px;
  }
}

.common-bg {
  background-color: #303a47;
}

@media (min-width: 992px) {
  header.is-minimal .main-header {
    padding: 32px 0;
  }
}

.msg-box {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
}
@media (max-width: 575px) {
  .msg-box {
    position: relative;
  }
}
.toast {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  width: 30%;
  max-width: 300px;
  margin-left: auto;
  top: 40px;
  left: 80%;
  margin-right: 0%;
  color: #fff;
  padding: 12px;
  background-color: rgba(24, 24, 24, 0.9);
  border-radius: 30px;
  text-align: center;
  opacity: 0;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity;
  z-index: 99;
}
.toast.is-visible {
  opacity: 1;
}
@media (max-width: 767px) {
  .toast {
    position: fixed !important;
    top: 55px !important;
    max-width: 300px !important;
    left: 50%;
    width: 70%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.lander-enterprise {
  .toast {
    white-space: initial;
    line-height: 16px;
    height: auto;
    @media (max-width: 767px) {
      transform: translate(-20%, -20%);
    }
  }
}
