// Colors
// ______________________________
$black-200: #f6f8fc;
$black-300: #d9e1ee;
$black-400: #8495b1;
$black-500: #556784;
$black-700: #142640;
$black-600: #3b4c66;
$grey-800: #556270;
$black: #181818;
$black-900: #303a47;
$blue-700: #126ab8;
$blue-500: #1d90f5;
$blue-600: #086cd0;
$blue-800: #1265b8;
$white-900: #fff;
$red-500: #dd2727;
$light-white: #f5f7f6;
$light-grey: #d2d3d4;
$light-grey-100: #edf0f2;
$medium-grey: #6d7175;
$pale-blue: #00adef;

$color-brand: $blue-600;
$color-primary: $black-600;
$color-dark: $black-700;
$color-white: $white-900;
$color-shadow: rgba(22, 45, 61, 0.2);
$color-bg: $black-200;
$color-black: $black;
$color-error: $red-500;
$color-violet: #3c60da;
$color-olive: #068f86;

$green-100: #ecfff7;
$green-400: #3e9363;
$green-500: #48a36c;
$green-600: #19af72;
$green-700: #107f52;
$green-800: #1b3d32;
$green-900: #0f2922;
// FONT
// ______________________________
// Weights
$normal: 400;
$semibold: 600;
$bold: 700;
// Sizes
$f-sizes: (
  xxl: (
    56px,
    64px,
  ),
  xl: (
    38px,
    48px,
  ),
  lg: (
    20px,
    28px,
  ),
  md: (
    18px,
    28px,
  ),
  base: (
    16px,
    24px,
  ),
  sm: (
    14px,
    24px,
  ),
);
// Spacing
$spacing: (
  1: 8px,
  2: 16px,
  3: 24px,
  4: 32px,
  5: 40px,
  6: 48px,
  8: 64px,
  10: 80px,
  15: 120px,
  20: 160px,
  25: 200px,
  30: 240px,
);

// Grid
// _______________________
$grids: ("two", "three", "four");
$gutter: 40px;

// Icons Base64
$icon-error: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIxIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNERDI3MjciIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEwLjUyNyAxLjY2N2M0LjYwMiAwIDguMzMzIDMuNzMgOC4zMzMgOC4zMzMgMCA0LjYwMi0zLjczMSA4LjMzMy04LjMzMyA4LjMzMy00LjYwMyAwLTguMzM0LTMuNzMtOC4zMzQtOC4zMzMgMC00LjYwMiAzLjczMS04LjMzMyA4LjMzNC04LjMzM3ptMCAxLjE1OEM2LjU2NCAyLjgyNSAzLjM1IDYuMDM3IDMuMzUgMTBjMCAzLjk2MyAzLjIxMyA3LjE3NSA3LjE3NiA3LjE3NXM3LjE3NS0zLjIxMiA3LjE3NS03LjE3NWMwLTMuOTYzLTMuMjEyLTcuMTc1LTcuMTc1LTcuMTc1em0wIDkuNjk4bC4wOS4wMDVjLjQyLjA0NS43NDcuNC43NDcuODMyIDAgLjQ2My0uMzc1LjgzOC0uODM3LjgzOC0uNDYzIDAtLjgzOC0uMzc1LS44MzgtLjgzOCAwLS40MzEuMzI3LS43ODcuNzQ2LS44MzJsLjA5Mi0uMDA1em0wLTcuNzU1Yy4yOTMgMCAuNTM1LjIxOC41NzMuNWwuMDA2LjA4djUuNjg2YzAgLjMyLS4yNi41NzktLjU4LjU3OS0uMjkyIDAtLjUzNS0uMjE4LS41NzMtLjVsLS4wMDUtLjA4VjUuMzQ4YzAtLjMyLjI1OS0uNTc5LjU3OS0uNTc5eiIvPgo8L3N2Zz4K";
$icon-arrow-down: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' %3E%3Cpath fill='%23fff' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'%3E%3C/path%3E%3C/svg%3E";

//z-index values
$z-index: (
  minus-1: -1,
  1: 1,
  10: 10,
  11: 11,
  100: 100,
  1005: 1005,
  1006: 1006,
);

$primary-font: var(--Euclid-Circular-B);
$secondary-font: var(--Inter);
$default-font: var(--Lato);
