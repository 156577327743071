#privacy-policy,
#terms-of-use {
  width: 70%;
  position: absolute;
  top: 50%;
  left: 55%;
  @include z-index(1006);
  opacity: 0;
  margin: -250px 0 0 -40%;
  visibility: hidden;
}
#privacy-policy *,
#terms-of-use * {
  font-weight: 300;
}
#privacy-policy h4,
#terms-of-use h4 {
  color: $black-700;
  font-weight: 700;
}
// #privacy-policy .copy a,
// #terms-of-use .copy a {
//   color: #00cfca;
// }

#privacy-policy .header,
#terms-of-use .header {
  border-radius: 5px 5px 0 0;
}

#privacy-policy .copy,
#terms-of-use .copy {
  padding: 15px;
  background: #fff;
  height: 400px;
  overflow: auto;
}

#privacy-policy .copy p,
#terms-of-use .copy p {
  margin: 1.75em auto;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6rem;
  max-width: 700px;
}

@media screen and (max-width: 480px) {
  #privacy-policy,
  #terms-of-use {
    width: 90%;
    left: 0px;
    margin: 60px 20px 0px 20px;
    top: 0px;
  }
}

#privacy-policy .overlay,
#terms-of-use .overlay {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  @include z-index(1005);
  width: 100%;
  height: 100%;
}

#privacy-policy-terms-privacy,
#privacy-policy-terms-privacy-head {
  font-size: 18px;
  font-weight: 300;
  margin: 20px;
  color: $black-600;
  line-height: 1.4;
  @include respond-below(tablet) {
    margin: 14px;
  }
  h3 {
    color: $black-700;
    font-weight: 700;
  }
  h4 {
    font-size: 24px;
    font-weight: 700;
    margin: 20px 0px;
  }
  p {
    font-size: 18px;
    margin: 10px 0px;
  }
  table {
    margin: 0 auto;
  }

  table,
  th,
  td {
    border: 1px solid !important;
  }

  th,
  td {
    padding: 8px 15px !important;
  }

  a {
    color: $color-brand;
  }

  ol {
    padding-left: 50px;
  }
}
.modal-content {
  background: #fbfcfc;
  border-radius: 5px;
  padding: 27px 30px 20px;
  position: relative;
  @include z-index(1006);
  @include respond-below(tablet) {
    padding: 10px;
  }
}

.popup-close {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 1.5em;
  color: #bbc5ca;
  cursor: pointer;
}

.popup-close::after,
.popup-close::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 1px;
  background-color: #bbc5ca;
}

.popup-close::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.popup-close::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.popup-close:hover::after,
.popup-close:hover::before {
  background-color: #666;
}

.sm-terms-privacy {
  margin-top: 88px;
  padding-top: 48px;
  background-color: $black-200;

  h1,
  h2 {
    color: $black-700;
  }
  h1 {
    margin-bottom: 80px;
  }
  h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
    padding-top: 48px;
    scroll-margin-top: 90px;
    @include respond-below(tablet) {
      scroll-margin-top: 40px;
    }
  }
  p {
    color: $black-600;
  }

  @include respond-below(tablet) {
    margin-top: 52px;
    padding-top: 40px;

    h1 {
      margin-bottom: 40px;
    }
  }
}
