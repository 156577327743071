@use "sass:math";

.homepage {
  .container {
    max-width: 1160px;
    margin-right: auto;
    margin-left: auto;
    @include respond-below(desktop) {
      margin-left: 84px;
      margin-right: 84px;
    }
    @include respond-below(tablet) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .faq-section {
    .container {
      max-width: 560px;
      h2 {
        @include respond-below(tablet_max) {
          margin-bottom: 40px !important;
        }
      }
    }
    p {
      max-width: 560px;
    }
  }

  // Header
  .buttons-wrap {
    .btn-secondary {
      font-size: 14px;
      line-height: 2.2;
    }
  }

  // Hero Section
  .hero--default {
    @include respond-above(desktop) {
      margin-bottom: 106px !important;
      .jsx-btn-primary {
        height: 58px !important;
      }
      article {
        h1 {
          margin-bottom: 24px !important;
        }
      }
    }
    @include respond-between(tablet, tablet_max) {
      position: relative;
      padding-top: 32px !important;
      margin-bottom: 92px !important;
      .layered-images-wrap {
        position: static;
        video {
          display: none;
        }
        img {
          bottom: 32px;
          right: -81px;
          width: 360px !important;
          left: unset !important;
        }
      }
      article {
        width: 100%;
      }
      p {
        width: 280px;
      }
      .index-form {
        width: 280px;
        flex-direction: column;

        .email-cont {
          margin-bottom: 16px;
          margin-right: 0 !important;
        }
        div {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
    }
    @include respond-below(tablet) {
      padding-bottom: 38px;
      .layered-images-wrap {
        img {
          max-width: 535px;
        }
      }
    }
  }

  // cutoutFeatureImage after hero
  .use-setmore-wrap {
    & > div {
      margin: 0 auto;
      max-width: 1160px !important;

      @include respond-between(tablet_max, desktop) {
        & > * {
          width: 50%;
        }
      }

      @include respond-below(desktop) {
        margin: 0 84px !important;
      }

      @include respond-below(tablet) {
        margin: 0 !important;
      }

      figure {
        @include respond-below(tablet_max) {
          width: 100% !important;
        }
        picture {
          @include respond-below(tablet) {
            width: 100% !important;
            max-width: calc(100% - 34px);
            margin: 0 auto;
          }
          img {
            margin-top: -40px !important;
            filter: drop-shadow(0px 1px 3px rgba(48, 58, 71, 0.1))
              drop-shadow(0px 1px 2px rgba(48, 58, 71, 0.2));

            @include respond-between(tablet_max, desktop) {
              margin-top: 70px !important;
            }
            @include respond-below(tablet_max) {
              width: 100% !important;
            }
            @include respond-below(tablet) {
              aspect-ratio: auto !important;
            }
          }
        }
      }

      article {
        @include respond-above(desktop) {
          margin-left: 140px;
        }
        @include respond-between(tablet_max, desktop) {
          margin-left: 40px;
        }

        h2 {
          @include respond-above(tablet_max) {
            margin-top: 80px !important;
          }

          @include respond-below(tablet) {
            font-size: 32px;
            line-height: 40px;
          }
        }

        p {
          margin-bottom: 40px;
        }

        @include respond-below(tablet_max) {
          margin-left: 0;
          max-width: none;
        }

        @include respond-below(tablet) {
          margin: 0 20px !important;
          max-width: none;
        }
      }

      @include respond-below(tablet_max) {
        flex-direction: column;
      }
    }

    padding-bottom: 160px;

    @include respond-below(tablet_max) {
      padding-bottom: 120px;
    }

    @include respond-below(tablet) {
      padding-bottom: 80px;
    }
  }
  // --------------------------------

  // featureGrid section
  .features-grid-section {
    @include respond-above(desktop) {
      li {
        margin-bottom: 0 !important;
      }
    }
    @include respond-between(tablet, tablet_max) {
      // to avoid unexpected spacing that's happening
      .gutter-space {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }
  }
  // --------------------------------

  // steps section after featureGrid
  .booking-steps {
    overflow: unset !important;
    @include respond-below(tablet_max) {
      padding-top: 56px !important;
      margin-bottom: 80px !important;
    }
    @include respond-below(tablet) {
      padding-top: 0px !important;
      padding-bottom: 140px !important;
    }

    h2 {
      @include respond-below(tablet_max) {
        font-size: 40px !important;
        line-height: 48px !important;
      }
      @include respond-below(tablet) {
        font-size: 32px !important;
        line-height: 48px !important;
        text-align: center !important;
      }
      @include respond-above(desktop) {
        margin-bottom: 80px !important;
      }
    }

    ul {
      @include respond-below(tablet_max) {
        flex-direction: column;
        margin-bottom: 0;
      }
    }

    .steps {
      &:after {
        content: none;
      }
      div {
        @include respond-above(desktop) {
          margin-bottom: 0 !important;
        }
      }
      @include respond-below(tablet_max) {
        max-width: 280px;
        width: 100% !important;
        margin-bottom: 24px;
        &:nth-child(3) {
          margin-bottom: 0 !important;
        }
      }
      @include respond-below(tablet) {
        max-width: 100%;
      }
      .steps-para {
        position: relative;
        padding-left: 48px;
        max-width: 360px;

        &::after {
          content: attr(data-step);
          width: 32px;
          height: 32px;
          border-radius: 50%;
          font-family: $primary-font;
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
          color: #556270;
          background-color: $color-white;
          position: absolute;
          top: 0;
          left: 0;
          text-align: center;
        }
        @include respond-below(tablet_max) {
          margin: 0 !important;
        }
      }
    }

    .desktop-mobile-picture {
      @include respond-below(tablet_max) {
        display: none;
      }
      @include respond-below(tablet) {
        display: block;
      }
    }

    .desktop-mobile-img {
      max-width: 560px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 40px);
      @include respond-below(tablet) {
        max-width: 320px;
        height: auto;
        transform: translate(-50%, 18px);
      }
    }

    .steps-img,
    .steps-img > img {
      width: 100%;
      max-width: 360px;
      @include respond-below(tablet_max) {
        display: none;
      }
    }

    .tablet-picture {
      width: 100% !important;
      max-width: 280px;
      position: absolute;
      bottom: 0;
      right: 74px;
      margin-bottom: 0;
    }
    .tablet-img {
      width: 100%;
    }
  }
  // -----------------

  // Integrations section
  .home-integrations {
    @include respond-between(mobile_max, tablet_max) {
      margin-bottom: 120px !important;
    }
    overflow-x: hidden;
    article {
      & > div {
        margin-bottom: 80px;
        @include respond-below(tablet_max) {
          margin-bottom: 40px;
        }
      }

      h2 {
        @include respond-below(tablet_max) {
          font-size: 32px !important;
          line-height: 40px !important;
          max-width: 600px;
          margin: 0 auto;
        }
        @include respond-below(mobile) {
          margin: 0 20px;
        }
      }
      & > div {
        max-width: 531px;
        @include respond-below(tablet_max) {
          max-width: 600px;
        }
        @include respond-below(mobile) {
          margin: 0 20px 40px;
        }
      }
    }
    .carousal-container {
      display: flex;
      flex-wrap: nowrap;
      transition: transform 3s ease-out;
      overflow-x: scroll;
      width: 100% !important;
      max-width: 100% !important;
      height: 149px;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
      @include respond-below(tablet_max) {
        align-items: flex-start;
        height: 96px;
      }

      .carousal-item {
        width: calc((100% - 240px) / 6);

        min-width: 360px;
        height: 136px;
        margin-right: 20px;
        text-align: left;

        background: #ffffff;
        opacity: 0.75;

        box-shadow: 0px 1px 3px rgba(48, 58, 71, 0.1),
          0px 1px 2px rgba(48, 58, 71, 0.2);
        border-radius: 8px;
        transition: box-shadow 300ms ease-in-out, opacity 300ms ease-in-out;
        &:first-child {
          margin-left: 40px;
        }
        @include respond-below(tablet_max) {
          width: auto;
          height: auto;
          min-width: initial;
        }

        .carousal-item-link {
          height: 100%;
          width: 100%;
          display: flex;
          padding: 24px;
          @include respond-below(tablet_max) {
            align-items: center;
          }
          .carousal-item-img {
            width: 40px;
            height: 40px;
          }
          .carousal-item-content {
            @include respond-below(tablet_max) {
              margin-left: 16px !important;
            }
            h3 {
              font-family: $primary-font;
              font-weight: 600;
              font-size: 24px;
              line-height: 32px;
              @include respond-below(tablet_max) {
                white-space: nowrap;
              }
            }
            p {
              font-family: $default-font;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              @include respond-below(tablet_max) {
                display: none;
              }
            }
          }
        }
      }
      .carousal-item.active {
        opacity: 1;
        box-shadow: 0px 4px 8px rgba(48, 58, 71, 0.14),
          0px 2px 4px rgba(48, 58, 71, 0.1);
      }
    }
    ul {
      max-width: 959px;
    }
  }

  .cta-contrast.has-image {
    @include respond-above(desktop) {
      padding-top: 80px;
      padding-bottom: 88px;
      min-height: 368px;
      max-height: 368px;
    }
    article {
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
        max-width: 460px;
        margin-bottom: 40px !important;
      }
      a {
        &:last-child {
          margin-left: 24px !important;
        }
      }
    }

    @include respond-below(tablet_max) {
      article {
        h2 {
          max-width: 440px;
          margin-bottom: 40px !important;
        }
        a {
          margin-top: 0 !important;
          &:last-child {
            margin-left: 24px !important;
          }
        }
      }
    }
    @include respond-below(tablet) {
      article {
        h2 {
          font-size: 24px !important;
          line-height: 32px !important;
          margin-bottom: 40px !important;
        }
        a {
          &:last-child {
            margin-top: 16px !important;
            margin-left: 0px !important;
          }
        }
      }
    }
  }
  // -------------------------------------

  // CTA

  .cta-halflength {
    @include respond-between(tablet, tablet_max) {
      padding-bottom: 120px !important;
    }
    @include respond-below(desktop) {
      picture {
        display: none;
      }
    }
  }
  //--------------------------------------

  // Pricing section
  .pricing-container {
    article {
      @include respond-above(desktop_sm) {
        h2 {
          padding-top: 0 !important;
        }
        p {
          padding-bottom: 80px;
        }
      }
    }
    .billing-type-wrap {
      @include respond-below(tablet_max) {
        display: none;
      }
    }

    .card--default {
      @include respond-below(tablet_max) {
        display: block;
        & > li {
          width: 100%;
          max-width: 335px;
          margin-top: 24px;
          margin-left: auto !important;
          margin-right: auto !important;
          .billing-type-wrap {
            display: block !important;
          }
          .togg-btn-wrap {
            margin-left: 0 !important;
          }

          &:first-child {
            margin-top: 40px !important;
          }
        }
      }
      @include respond-below(tablet) {
        & > li {
          margin-top: 16px !important;

          &:first-child {
            margin-top: 40px !important;
          }
        }
      }
    }
    @include respond-between(mobile_max, tablet_max) {
      padding-bottom: 120px !important;
      article {
        & > h2 {
          font-size: 56px !important;
          line-height: 64px !important;
          padding-top: 0 !important;
        }
        & > p {
          margin-bottom: 40px !important;
        }
      }
    }
    @include respond-below(mobile_max) {
      article {
        & > h2 {
          margin-top: 0 !important;
        }
      }
    }
  }
  //--------------------------------------
  .carousel {
    &-item {
      max-height: 396px;
      @include respond-below(tablet_max) {
        margin-right: 15px !important;
      }
    }
  }
}

// Adjustments for home-v2 classes
.cta-contrast {
  .btn-secondary {
    min-width: 173px;
  }
}

#home-app img {
  margin-top: 0;
}
.home-app article img {
  width: 157px;
}

.talkingAboutUsSection {
  h2 {
    font-size: 40px;
    line-height: 48px;
    @include respond-below(tablet) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  .big-pill {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 32px 80px;

    background: #edf0f2;
    border-radius: 44px;
    max-width: 1160px;

    .small-pill {
      display: inline-block;
      margin-right: 96px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  @include respond-below(tablet_max) {
    .big-pill {
      background: transparent;
      border-radius: 0px;
      max-width: 100%;
      overflow-x: auto;
      padding: 0px 0px 10px 0px;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
      }

      .small-pill {
        background: #edf0f2;
        border-radius: 44px;
        padding: 16px 24px;
        margin-right: 16px;
      }
    }
  }
  @include respond-between(mobile, tablet_max) {
    .small-pill:first-child {
      margin-left: 200px;
    }
    .small-pill:last-child {
      margin-right: 200px;
    }
  }
  @include respond-below(mobile) {
    .small-pill:first-child {
      margin-left: 533px;
    }
  }
}

.featured-img-section {
  & > div {
    flex-direction: column-reverse;
  }
  div {
    @include respond-below(tablet_max) {
      article {
        width: 100% !important;
        max-width: 100% !important;
        display: block !important;
        margin-top: 0 !important;
        &:nth-of-type(2) {
          margin-bottom: 40px;
        }
      }
      picture {
        max-width: 100%;
        width: 600px;
        margin-top: 40px;
        img {
          width: 600px;
        }
      }
      &:nth-child(2) {
        picture {
          display: none;
        }
      }
    }
    @include respond-below(tablet) {
      article {
        width: 100% !important;
        max-width: 100% !important;
        display: block !important;
        margin-bottom: 0px;
        margin-top: 0;
        .download-app {
          margin-top: 40px !important;
        }
      }
      picture {
        display: none;
      }
      a {
        width: 100% !important;
      }
    }
  }
  @include respond-below(tablet_max) {
    margin-bottom: 120px !important;
  }
  @include respond-below(tablet_max) {
    margin-bottom: 80px !important;
  }
}

.book-section {
  @include respond-above(desktop) {
    padding-top: 112px;
  }
  @include respond-below(desktop) {
    padding-top: 120px;
  }
  @include respond-below(tablet) {
    padding-bottom: 120px;
  }
  .book-feed {
    position: relative;
    border-radius: 8px;
    @include respond-above(desktop) {
      padding-left: 100px;
    }
    @include respond-below(desktop) {
      padding: 40px 0px 40px 40px !important;
      margin-left: 84px;
      margin-right: 84px;
      article {
        max-width: 240px;
        .text-lg {
          font-size: 32px !important;
          line-height: 40px !important;
        }
      }
    }
    @include respond-below(tablet) {
      padding: 20px !important;
      max-width: 335px;
      margin: 0 auto;
      article {
        margin-bottom: 40px;
        max-width: none;
      }
    }
    @include respond-below(mobile) {
      article {
        margin: 0 0 40px;
      }
    }

    img {
      width: 240px;
      position: absolute;
      top: -40px;
      right: 209px;
      box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
        0 1px 3px 0 rgba(48, 58, 71, 0.1);
      @include respond-below(desktop) {
        right: 10%;
      }
      @include respond-below(tablet_max) {
        top: -66px;
        right: 40px;
      }
      @include respond-below(tablet) {
        display: block;
        margin: 0 auto;
        position: static;
        margin-bottom: -96px;
      }
    }
  }
}

.signup-cta-section {
  background-color: $green-500;

  .signup-cta-warper {
    max-width: 460px;

    h2 {
      max-width: 410px;
      color: #f5f8fa;
      text-align: center;

      @include respond-below(desktop) {
        margin: 0 auto;
      }

      @include respond-below(tablet) {
        text-align: left;
      }
    }

    .email-cont {
      div {
        margin-bottom: 0;

        .index-email {
          padding: 12px 0px 12px 16px;
        }
      }

      @include respond-below(tablet) {
        display: none;
      }

      @include respond-below(desktop) {
        max-width: 300px;
      }
    }

    .index-form {
      @include respond-below(desktop) {
        display: flex;

        div:last-child {
          max-width: 140px;
          width: 140px;
        }
      }

      @include respond-below(tablet) {
        div:last-child {
          max-width: 100%;
          width: 100%;
        }
      }
    }

    button {
      background: #ffffff;
      border-radius: 8px;
      color: $color-brand;
    }

    @include respond-below(tablet) {
      padding: 40px !important;
    }
  }
}

// Carousel

.carousel-cont {
  overflow: hidden;
  padding: 10px 0;

  @include respond-below(tablet) {
    overflow-x: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent; /* Chrome/Safari/Webkit */
    }
  }
}
.carousel {
  width: 2400px;
  transform: translate(-260px);
  transition: all 1s;
  @include respond-below(tablet) {
    width: 1890px;
    transform: translate(-275px);
  }
  &-item {
    max-width: calc((100% - (40px * 6)) / 6);
    width: 100%;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
      0 1px 3px 0 rgba(48, 58, 71, 0.1);
    background-color: #fff;
    h3,
    p {
      max-width: 288px;
    }
    h3 {
      color: #8996a3;
      text-transform: capitalize;
    }
    p {
      font-size: 24px;
      line-height: 32px;
      color: #303a47;
      font-weight: bold;
    }
    @include respond-below(tablet) {
      max-width: calc((100% - (20px * 6)) / 6);
    }
  }
}
// Video styles
.video-section {
  @include respond-between(mobile_max, tablet_max) {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
  }
}
.has-align-middle {
  @include respond-above(desktop) {
    article:nth-child(2) {
      padding-left: 140px;
      max-width: 570px !important;
    }
  }
  @include respond-above(tablet_max) {
    .video-highlight {
      width: 50%;
    }
  }
  @include respond-below(tablet_max) {
    display: flex;
    flex-wrap: wrap;

    .video-highlight {
      width: 100%;
      padding-right: 0px;
    }

    article {
      margin-top: 30px;
      width: 100% !important;
      max-width: 100% !important;
      h2 {
        margin-top: 0px !important;
      }
    }
  }
}
.video-highlight {
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  filter: brightness(0.9);
  .video-timg {
    position: relative;
    display: inline-block;
    border-radius: 8px;
    width: 100%;
    @include respond-below(tablet) {
      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 10px;
        position: absolute;
        bottom: 0;
        background: #f6f8fc;
      }
    }
    background-color: transparent;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 8px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &:focus {
      outline: none !important;
    }
    overflow: hidden;
    & video {
      width: 101%;
      margin: -1px 0 -3px -2px;
    }
  }
  .video-timg:hover {
    cursor: pointer;
    &::before {
      opacity: 0.25;
      cursor: pointer;
    }
    path {
      transform: scale(1.3);
      transform-origin: 40px 40px;
    }
  }
  .video-timg:active {
    &::before {
      opacity: 0.5;
    }
    circle {
      fill-opacity: 1;
      fill: #fff;
    }
  }
}
.video-content {
  position: relative;
  border-radius: 8px;
  width: 960px;
  // height: 539px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  @include respond-below(desktop_standard) {
    width: 760px;
  }

  @include respond-below(tablet) {
    width: 100%;
  }
}

.video-cont {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-cont iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#videoUtube {
  border-radius: 8px;
  @include respond-below(tablet) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 99.8%;
    height: 100%;
    z-index: 1;
  }
}
.play-btn {
  width: 80px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
}
.play-btn:not(:focus) {
  outline: none;
}
.is-played {
  @include respond-below(tablet) {
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
  }
}

// Testimonial modal
.testimonial-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(25, 31, 41, 0.95);
  align-items: center;
  justify-content: center;
  z-index: 10;

  .btn-primary {
    min-width: 242px;
  }
}

#closeBtn {
  font-size: 16px;
  line-height: 1.5;
  color: #f5f8fa;
  position: absolute;
  top: 32px;
  right: 32px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    vertical-align: middle;
  }

  @include respond-below(tablet) {
    top: 30px;
    right: 14px;
  }
}

.home-masonry {
  @include respond-between(mobile_max, tablet_max) {
    margin-bottom: 120px !important;
  }
  &.bg-offset::after {
    bottom: -32%;
    height: 54%;
    @include respond-below(tablet) {
      height: 32%;
      bottom: -26%;
    }
  }
}

// APP DOWNLOAD SECTION
.app-download {
  .container {
    display: flex;

    h2 {
      @include respond-above(desktop_sm) {
        max-width: 430px;
        margin-top: 43px;
      }
    }

    @include respond-below(tablet) {
      margin: 0 !important;

      article {
        margin: 0 20px !important;
      }
    }
  }

  .appdownload-desktop {
    @include respond-below(tablet_max) {
      display: none;
    }
  }
  .appdownload-mobile {
    @include respond-above(tablet_max) {
      display: none;
    }
  }
  picture {
    @include respond-above(desktop) {
      img {
        width: auto;
      }
    }
    @include respond-between(tablet, desktop) {
      min-width: 400px;

      img {
        margin-top: 0;
      }
    }
    @include respond-below(tablet_max) {
      width: 100%;
    }
    @include respond-below(tablet) {
      img {
        margin-top: -40px;
      }
    }
  }
  @include respond-above(tablet) {
    > div {
      max-width: 1160px !important;
      margin: 0 auto;
    }
    article {
      margin-left: 40px;
    }
    picture {
      img {
        margin-top: -80px;
      }
    }
    h2 {
      margin-bottom: 24px;
    }
    .btn-primary {
      margin-top: 40px;
    }
  }
  @include respond-below(tablet_max) {
    > div {
      flex-direction: column-reverse;
    }

    article {
      max-width: 100%;
      margin: 0 84px !important;
    }
  }

  @include respond-below(tablet) {
    article {
      margin: 0 0px !important;
    }

    h2 {
      margin-bottom: 20px;
    }

    .btn-primary {
      margin-top: 20px;
    }
  }

  @include respond-between(tablet, desktop) {
    > div {
      align-items: center;
    }

    article {
      margin-left: 20px;
    }

    picture {
      min-width: 157px;
    }
  }
}

// PEOPLE LOVE SETMORE
.people-love-setmore {
  .heart-glyph {
    @extend .ff-euclid;
    font-size: 40px;
    line-height: 64px;

    @include respond-below(tablet) {
      font-size: 28px;
      line-height: 48px;
    }
  }
  @include respond-below(tablet) {
    font-size: 32px !important;
    line-height: 40px !important;
    span {
      display: inline-block;
    }
  }
}

// Homepage v3

.index-form {
  .btn-primary {
    min-width: 120px;
    padding: 12px 25px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.email-cont {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  max-width: 300px;
  padding-right: 45px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
    0 1px 3px 0 rgba(48, 58, 71, 0.1);

  .index-email {
    width: 100%;
    padding: 12px 0 12px 24px;
    background-color: #fff;
    font-family: $default-font;
    font-size: 16px;
    border-radius: 8px;
    line-height: 1.5;
    color: #8996a3;
  }

  &:hover {
    box-shadow: 0px 10px 20px rgba(48, 58, 71, 0.15),
      0px 3px 6px rgba(48, 58, 71, 0.08);
  }

  &:focus-within {
    box-shadow: 0px 10px 20px rgba(48, 58, 71, 0.15),
      0px 3px 6px rgba(48, 58, 71, 0.08);
    .index-email {
      color: #556270;
    }
  }

  .index-email {
    ::placeholder {
      font-family: $default-font;
      font-size: 16px;
      line-height: 1.5;
      color: #8996a3;
    }
  }

  .error-msg {
    display: none;
    margin-left: 24px;
    color: #dd2727;
    font-family: $default-font;
    font-size: 12px;
    line-height: 19px;
    position: absolute;
    bottom: -20px;
  }

  .input-loader {
    display: none;
    width: 22px;
    position: absolute;
    right: 24px;
    top: 14px;
    height: 22px;
  }

  @include respond-below(tablet) {
    max-width: 100%;
  }
}

.error {
  .error-msg {
    display: block;
  }
}

.input-check {
  .input-loader {
    display: inline-block;
    animation: spin 1.2s linear infinite;
  }
  .btn-primary {
    opacity: 0.3;
    pointer-events: none;
  }
  .index-email {
    color: #8996a3;
  }
}

.no-webp {
  video {
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    height: 100%;
  }
  .layered-images-wrap {
    video {
      background-image: url("https://assets.setmore.com/website/v2/images/homepage-v2/setmore-website-homepage-hero-cover.jpg");
    }
  }

  video[data-id="8y019nxEPK0"] {
    background-image: url("https://assets.setmore.com/website/v2/images/homepage-v2/setmore-booking-page-overview.png");
  }
  video[data-id="qtHA-uPt8Wg"] {
    background-image: url("https://assets.setmore.com/website/v2/images/homepage-v2/setmore_laura-gomez-video--cover.jpg");
  }
  video[data-id="ia8mjW2eVBY"] {
    background-image: url("https://assets.setmore.com/website/v2/images/homepage-v2/setmore_happy-dental-video--cover.jpg");
  }
  video[data-id="2cJ92s1IBzU"] {
    background-image: url("https://assets.setmore.com/website/v2/images/homepage-v2/video/setmore_law-offices-of-athar-video.mp4");
  }
  video[data-id="2Fc9dCLlVbk"] {
    background-image: url("https://assets.setmore.com/website/v2/images/homepage-v2/setmore_lendale-johnson-video--cover.jpg");
  }
  video[data-id="Msn1lRdo0l4"] {
    background-image: url("https://assets.setmore.com/website/v2/images/homepage-v2/setmore_pet-dental-video--cover.jpg");
  }
}

.santa-promo {
  .santa-home {
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #068f86 0%, #04655e 100%);
    .snow {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
    }
    @function random_range($min, $max) {
      $rand: random();
      $random_range: $min + floor($rand * (($max - $min) + 1));
      @return $random_range;
    }
    .snow__particle {
      $total: 100; // align with the amount of elements specified in HTML
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      background: white;
      border-radius: 50%;

      @for $i from 1 through $total {
        $random-x: random(1000000) * 0.0001vw;

        $random-offset: random_range(-100000, 100000) * 0.0001vw;
        $random-x-end: $random-x + $random-offset;
        $random-x-end-yoyo: $random-x + math.div($random-offset, 2);
        $random-yoyo-time: math.div(random_range(30000, 80000), 100000);
        $random-yoyo-y: $random-yoyo-time * 100vh;
        $random-scale: random(10000) * 0.0001;
        $fall-duration: random_range(10, 30) * 1s;
        $fall-delay: random(30) * -1s;

        &:nth-child(#{$i}) {
          opacity: random(10000) * 0.0001;
          transform: translate($random-x, -10px) scale($random-scale);
          animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
        }

        @keyframes fall-#{$i} {
          #{percentage($random-yoyo-time)} {
            transform: translate($random-x-end, $random-yoyo-y)
              scale($random-scale);
          }

          to {
            transform: translate($random-x-end-yoyo, 100vh) scale($random-scale);
          }
        }
      }
    }
    .hero--default {
      figure {
        .fg-layer {
          bottom: -75px;
        }
        .bg-layer {
          position: relative;
          top: 40px;
        }
        @include respond-below(desktop_standard) {
          .fg-layer {
            left: -60px;
          }
        }
        @include respond-below(tablet) {
          .fg-layer {
            display: block;
            left: 20px;
            width: 100%;
          }
        }
      }
    }
    .bottom-cloud {
      bottom: 0px;
      width: 100%;
    }
    @include respond-below(tablet) {
      .top-cloud {
        object-fit: cover;
        height: 30px;
      }
      .bottom-cloud {
        object-fit: cover;
        height: 60px;
      }
    }
    .grid--default {
      .btn-primary {
        min-width: 240px;
      }
    }
  }
  #Christmas_Lights {
    z-index: 1000;
    width: 100%;
    @include respond-below(tablet) {
      height: auto;
    }
    .red_bulb {
      fill: #e04209;
      stroke: #fffefe00;
      stroke-miterlimit: 10;
    }

    .green_bulb {
      fill: #068f86;
      stroke: #fffefe00;
      stroke-miterlimit: 10;
    }

    .blue_bulb {
      fill: #1b9fe0;
      stroke: #fffefe00;
      stroke-miterlimit: 10;
    }

    .violet_bulb {
      fill: #8955a3;
      stroke: #fffefe00;
      stroke-miterlimit: 10;
    }

    .yellow_bulb {
      fill: #ffb41f;
      stroke: #fffefe00;
      stroke-miterlimit: 10;
    }

    .orange_bulb {
      fill: #f57f31;
      stroke: #fffefe00;
      stroke-miterlimit: 10;
    }

    /* Simply changing the opacity of the SVG fill to create the glow effect for the bulbs */

    .bulb {
      animation-name: glow;
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      animation-direction: alternate;
    }

    @-webkit-keyframes glow {
      0% {
        fill-opacity: 0.25;
      }
      100% {
        fill-opacity: 1;
      }
    }

    @keyframes glow {
      0% {
        fill-opacity: 0.25;
      }
      100% {
        fill-opacity: 1;
      }
    }

    /* Made a set of ten animation delays to create a more random effect of lights blinking.  Simplest way without jumping through SVG hoops. */

    .blink-1 {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s;
    }

    .blink-2 {
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
    }

    .blink-3 {
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
    }

    .blink-4 {
      -webkit-animation-delay: 6s;
      animation-delay: 6s;
    }

    .blink-5 {
      -webkit-animation-delay: 2.5s;
      animation-delay: 2.5s;
    }

    .blink-6 {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }

    .blink-7 {
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
    }

    .blink-8 {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }

    .blink-9 {
      -webkit-animation-delay: 0.95s;
      animation-delay: 0.95s;
    }

    .blink-10 {
      -webkit-animation-delay: 1.2s;
      animation-delay: 1.2s;
    }
  }
}
