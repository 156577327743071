.promo-22 {
  &-banner {
    border: 1px solid #fff;
    background-color: rgba(206, 235, 233, 0.5);
    position: relative;
    top: 88px;
    border-radius: 8px;
    margin-top: 24px;
    width: auto;
    height: auto;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
    @include z-index(1);

    @include respond-below(tablet) {
      top: 56px;
    }
    &:hover {
      box-shadow: 0 1px 2px rgba(48, 58, 71, 0.2),
        0 1px 3px rgba(48, 58, 71, 0.2);
    }
    &.is-fixed {
      box-shadow: none;
    }
    .content-div {
      padding: 16px 0 16px 24px;
      width: 96%;
    }
    .details {
      margin-right: 20px;
      color: #05857c;
      font-size: 18px;
      line-height: 28px;
      text-align: left;
      span {
        color: inherit;
        border-bottom: 1px solid currentColor;
      }
    }
    .close-element {
      float: right;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 14px;
      z-index: 10;

      svg {
        transition: transform 0.3s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: rotate(180deg);
        }
      }
      @include respond-below(tablet) {
        top: 4px;
        right: 4px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    .mobile-message {
      display: none;
    }
    @include respond-below(tablet_max) {
      .desktop-message {
        display: none;
      }
      .mobile-message {
        display: inline;
      }
    }
  }
}
