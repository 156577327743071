.p-bottom-custom {
  padding-bottom: 36px !important;
}
@media (max-width: 991px) {
  .free-plan .app-review-wrap {
    width: 100%;
    position: absolute;
    margin: 0 auto;
    bottom: -300px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
  -webkit-text-fill-color: #3b4c66 !important;
  color: #3b4c66 !important;
}

.booking-page-demo .launch-demo {
  cursor: pointer;
}
.booking-page-demo .active {
  display: block !important;
}

/* Video styles which is the last one added in styles-all.scss, 
the styles in this would override styles in same specificity 
as it would in styles-all.scss*/
.video-highlight {
  width: 760px;
  height: 427px;
}
@media (min-width: 1200px) {
  .video-highlight {
    margin-top: -40px;
  }
}
.video-highlight .video-timg img {
  width: 100%;
  height: 100%;
}
.video-highlight {
  #videoUtube {
    display: none;
    border-radius: 8px;
    @include respond-below(tablet) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 99.8%;
      height: 100%;
      z-index: 1;
    }
  }
  .play-btn {
    width: 80px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
  }
  .play-btn:not(:focus) {
    outline: none;
  }
}

@media (max-width: 767px) {
  .video-highlight {
    width: auto !important;
    height: 100%;
  }
}

.video-highlight {
  position: relative;
  border-radius: 8px;
  width: 560px;
  height: 314px;
  overflow: hidden;
  cursor: pointer;
}

@media (max-width: 767px) {
  .video-highlight {
    width: auto !important;
    height: 100% !important;
  }
}

.video-highlight .video-timg {
  position: relative;
  display: inline-block;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  overflow: hidden;
}

@media (max-width: 767px) {
  .video-highlight .video-timg::after {
    display: block;
    content: "";
    width: 100%;
    height: 10px;
    position: absolute;
    bottom: 0;
    background: #f6f8fc;
  }
}

.video-highlight .video-timg::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.video-highlight .video-timg:focus {
  outline: 0 !important;
}

.video-highlight .video-timg video {
  width: 101%;
  margin: -1px 0 -3px -2px;
  cursor: auto;
}

.video-highlight .video-timg:hover {
  cursor: pointer;
}

.video-highlight .video-timg:hover::before {
  opacity: 0.25;
  cursor: pointer;
}

.video-highlight .video-timg:hover path {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transform-origin: 40px 40px;
  transform-origin: 40px 40px;
}

.video-highlight .video-timg:active::before {
  opacity: 0.5;
}

.video-highlight .video-timg:active circle {
  fill-opacity: 1;
  fill: #fff;
}

.video-content {
  position: relative;
  border-radius: 8px;
  width: 960px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 auto;
}

@media (max-width: 1399px) {
  .video-content {
    width: 760px;
  }
}

@media (max-width: 767px) {
  .video-content {
    width: 100%;
  }
}

.video-cont {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.video-cont iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#videoUtube {
  border-radius: 8px;
}

@media (max-width: 767px) {
  #videoUtube {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 99.8%;
    height: 100%;
    z-index: 1;
  }
}

.play-btn {
  width: 80px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
}
.play-btn:not(:focus) {
  outline: 0;
}

@media (max-width: 767px) {
  .is-played {
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
  }
}
