// FONT
// ______________________

// Sizes
@each $name, $sizes in $f-sizes {
  %text-#{$name} {
    font-size: nth($sizes, 1);
    line-height: nth($sizes, 2);
  }
}

// Weights
%text-bold {
  color: $color-dark;
  font-weight: $semibold;
}

%text-bolder {
  color: $color-dark;
  font-weight: $bold;
}
%text-align-center {
  text-align: center;
}
%text-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

%d-flex {
  @include respond-above(tablet) {
    display: flex;
  }
}
%bg-white {
  background-color: $color-white;
}
//
%border-radius-sm {
  border-radius: 4px;
}

%border-radius-md {
  border-radius: 8px;
}

%box-shadow {
  box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
}

// Others

%link-hover {
  & {
    display: inline-block;
    position: relative;
    line-height: 24px;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: currentColor;
      transform-origin: bottom right;
      transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
    }
    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}
