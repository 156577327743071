// Helpers
%d-inline-block {
  display: inline-block;
}
%hover-shadow {
  box-shadow: 0 2px 7px -1px #dedede;
}

%btn-default {
  @extend %d-inline-block;
  min-width: 160px;
  padding: 12px 40px;
  border-radius: 6px;
  text-align: center;
  transition: 0.4s box-shadow, 0.3s background-color linear, 0.4s border-color;
  > * {
    @extend %d-inline-block;
    vertical-align: middle;
  }
  > span {
    font-size: 16px;
    line-height: 24px;
  }
  i {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

%btn-default-sm {
  min-width: 100px;
  height: 32px;
  padding: 0px 24px;
  > span {
    font-size: 14px;
    line-height: 30px;
  }
}
%btn-default-lg {
  min-height: 48px;
  padding: 12px 40px;
  > span {
    display: inline-block;
    min-width: 120px;
    font-size: 16px;
    line-height: 24px;
  }
}

// Variant
.btn-primary {
  @extend %btn-default;
  background-color: $green-800;
  color: $color-white;
  &:hover {
    background-color: $green-900;
  }
}

.btn-secondary {
  @extend %btn-default;
  border: 1px solid $green-800;
  color: $green-800;
  img {
    vertical-align: middle;
    margin-right: 6px;
  }
  svg {
    margin-right: 6px;
  }
  &:hover {
    @extend %hover-shadow;
  }
}

.btn-ternary {
  @extend %btn-default;
  @extend %bg-white;
  color: $green-800;
  &:hover {
    background-color: $light-white;
  }
}

.btn-quaternary {
  @extend %btn-default;
  background-color: transparent;
  color: $color-white;
  border: 1px solid $color-white;
}

.btn-outline {
  @extend %btn-default;
  border: 1px solid $black-300;
  > span {
    color: $black-600;
  }
  &:hover {
    @extend %hover-shadow;
    border-color: $black-400;
  }
}

.btn-negative {
  @extend %d-inline-block;
  margin: 0 32px;
  > span {
    @extend %d-inline-block;
    line-height: 24px;
  }
}

.btn-text {
  color: $color-brand;
}

// Sizes
.btn-sm {
  @extend %btn-default-sm;
}

.btn-lg {
  @extend %btn-default-lg;
}
