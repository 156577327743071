//Features
.features {
  #core-features::before,
  #online-presence::before,
  #confirmations-and-reminders::before,
  #online-scheduling::before,
  #calendar-sync-and-mobile-apps::before {
    content: "";
    padding-top: 40px;
    margin-top: -40px;
    width: 1px; /* '0' will not work for Opera */
    display: inline-block;
  }
  @include respond-below(tablet) {
    .fg-layer {
      margin-left: -20px;
      margin-bottom: 40px;
    }

    .cta-contrast.has-image picture {
      display: block;
    }

    .industry-masonry {
      &.bg-offset::after {
        height: 280px;
      }
    }
  }
  @include respond-below(desktop_standard) {
    .fg-layer {
      width: 120% !important;
    }
  }
  @include respond-below(tablet) {
    .fg-layer {
      width: auto !important;
    }
  }
  .btn-outline {
    cursor: pointer;
    @extend %btn-default;
    border: 1px solid $black-300;
    transition: 0.4s box-shadow, 0.3s background-color linear;

    > span {
      color: $black-600;
    }
    &:hover {
      @extend .hover-review-card;
    }
  }
}
.features-booking-page,
.featuresClassBooking,
.featuresWebsiteWidgets,
.staffScheduling,
.featuresCustomerReviews,
.featuresGoogleCalendarSync,
.featuresAppointments,
.featuresVirtualMeetings,
.featuresOnlinePayments,
.featuresYourBrandedApp .features-live-receptionist {
  .bg-offset-top {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 120px;
      left: 0;
      background-color: $black-200;
      @include z-index(minus-1);
    }
  }
  .bg-offset-bottom {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 115px;
      left: 0;
      background-color: $black-200;
      @include z-index(minus-1);
    }
  }
  .block--align-center {
    margin: 0 auto;
  }
  .cta-contrast.has-image h2 {
    font-size: 38px !important;
    line-height: 48px !important;
    @include respond-below(tablet) {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }
  h2.text-xl {
    margin-bottom: 0px !important;
  }

  @include respond-above(tablet) {
    .use-setmore-wrap {
      margin-top: 40px;
    }
    .use-setmore-wrap .btn-primary {
      margin-top: 0px;
    }
  }
  @include respond-below(tablet) {
    .use-setmore-wrap picture img {
      margin-top: 0px;
    }
    .use-setmore-wrap .btn-primary {
      margin-top: 0px;
    }
    .bg-offset-top {
      &::before {
        height: 45px;
      }
    }
    .bg-offset-bottom {
      &::after {
        height: 45px;
      }
    }
    .features h2 {
      font-size: 32px !important;
      line-height: 1.25 !important;
    }
  }
  .features .btn-outline {
    border-radius: 8px !important;
  }
  .hero-logo {
    width: 157px;
  }
}

.featuresClassBooking {
  .lg\:maxw-mds {
    max-width: 480px !important;
  }
}

.features-booking-page {
  .highlight picture {
    transform: scale(1.18);
  }
  @include respond-below(tablet) {
    .highlight {
      .md\:fx {
        overflow-y: hidden;
        overflow-x: hidden;
      }
      picture {
        transform: scale(1.24);
      }
    }
  }
}

.featuresClassBooking {
  .highlight picture {
    transform: scale(1.1);
    margin-top: 12px !important;
  }
  @include respond-below(tablet) {
    .highlight {
      .md\:fx {
        overflow-y: hidden;
        overflow-x: hidden;
      }
    }
  }
}

.featuresOnlinePayments {
  .hero--default {
    .btn-primary {
      @include respond-above(tablet_max) {
        width: 242px;
      }
    }
  }
}

.featuresWebsiteWidgets {
  .hero--default {
    picture img {
      height: 560px;
    }
  }
  .highlight {
    picture {
      transform: scale(1.05);
      margin-top: 12px !important;
      margin-left: 12px !important;
    }
  }
  @include respond-below(tablet) {
    .hero--default {
      picture {
        transform: scaleX(1.05);
        margin-right: 8px;
        img {
          height: 328px;
        }
      }
    }
    .highlight {
      picture {
        transform: scale(1.08);
        margin-top: 40px;
        margin-left: 16px;
      }
    }
  }
}

.features-desktop-app {
  .device-btn-wrap {
    a:last-child {
      vertical-align: middle;
      & > * {
        vertical-align: baseline !important;
      }
    }
  }
}

.staffScheduling {
  @include respond-below(desktop) {
    .hero--default {
      &.use-setmore-wrap article {
        margin-left: 40px;
      }
    }
    .layered-images-wrap {
      max-width: 760px;
      position: absolute;
      right: 0;
      .bg-layer {
        // margin-left: -110px;
        height: 480px;
      }
    }
  }
  @include respond-above(desktop_standard) {
    .hero--default {
      &.use-setmore-wrap article {
        margin-left: 140px;
      }
    }
  }
  @include respond-above(tablet) {
    .hero--default {
      & > div {
        flex-wrap: nowrap;
        overflow: hidden;
        max-width: initial;
        justify-content: center;
        & > figure {
          overflow: hidden;
          width: 760px;
          height: 560px;
          position: relative;
          .fg-layer {
            width: 760px;
            position: absolute;
            left: 128px;
            top: 0px;
          }
          .bg-layer {
            width: 560px;
            height: 480px;
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }
  @include respond-above(desktop_md) {
    .hero--default {
      & > div {
        & > figure {
          overflow: visible;
        }
      }
    }
  }
  @include respond-below(tablet) {
    .hero--default.container {
      margin-left: 0;
      margin-right: 0;
    }
    .hero--default {
      figure {
        position: relative;
      }
      .fg-layer {
        right: -1px;
        left: initial;
        top: 0;
        width: 296px;
        height: 328px;
        img {
          height: 100%;
        }
      }
      .bg-layer {
        height: 289px;
        img {
          height: 100%;
        }
      }
    }
    .highlight {
      margin-top: 38px;
    }
  }
}
// Features FAQ section

.features-faq {
  .faq-section h2.text-xl {
    margin-bottom: 80px !important;
  }
  .faq-section .faq-ques {
    padding: 26px 30px 28px 20px;
    &::after {
      position: absolute;
      top: 50%;
      right: 20px;
      width: 24px;
      height: 24px;
      border: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2348A36C' fill-rule='evenodd' d='M21.797 15.864c.27.26.27.681 0 .941-.245.234-.625.257-.897.07l-.086-.07L12 8.36l-8.814 8.445c-.244.234-.625.257-.896.07l-.087-.07c-.244-.234-.268-.599-.073-.859l.074-.082 8.866-8.495c.246-.236.581-.369.93-.369.3 0 .588.098.819.274l.11.095 8.868 8.495z' clip-rule='evenodd'/%3E%3C/svg%3E");
      transform: translateY(-50%) rotate(180deg);
    }
    @include respond-below(tablet) {
      padding-right: 0;
      .faq-section h2.text-xl {
        margin-bottom: 24px !important;
      }
    }
  }
  .faq-section input:checked + .faq-ques::after {
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
  }

  @include respond-below(tablet) {
    .faq-section.bg-offset-top::before {
      height: 95px;
    }
    .faq-section input:checked ~ .faq-point {
      padding-bottom: 24px;
      max-height: initial;
    }
  }
  @include respond-above(tablet) {
    .faq-section input:checked ~ .faq-point {
      max-height: initial !important;
    }
    .faq-section.bg-offset-top::before {
      height: 200px;
    }
  }
}

.features-virtual-meetings {
  h2 {
    font-size: 40px !important;
    line-height: 48px !important;
  }
  .has-align-top {
    &:nth-child(1) {
      article {
        p {
          max-width: 450px;
        }
        img {
          width: 76px;
          height: 24px;
          &:last-child {
            width: 144px;
          }
        }
      }
    }
  }
  .cta-contrast {
    img {
      height: 506px;
      padding-right: 90px;
    }
    .btn-lg {
      padding: 12px 32px;
    }
    @include respond-above(desktop) {
      padding: 80px 100px !important;
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
      }
      &.has-noimage {
        article {
          max-width: 720px;
          h2 {
            font-size: 40px !important;
            line-height: 48px !important;
          }
        }
      }
    }
  }
  section {
    &:nth-child(5) {
      background-color: $light-grey-100 !important;
    }
    &:nth-child(9) {
      background: transparent !important;
      padding-bottom: 160px !important;
      &:after {
        height: 290px !important;
        background: $light-grey-100;
        @include respond-below(mobile_max) {
          height: 600px !important;
        }
      }
      @include respond-below(mobile_max) {
        padding-bottom: 80px !important;
        h2 {
          text-align: center;
          margin-bottom: 0;
        }
      }
    }
  }
  .has-align-middle {
    @include respond-below(mobile_max) {
      article {
        margin-top: 40px;
      }
    }
    @include respond-above(desktop) {
      article:nth-child(2) {
        padding-left: 120px !important;
        max-width: 574px !important;
      }
      &:nth-child(3) {
        margin-bottom: 160px !important;
      }
    }
  }
  ul.card--feature {
    @include respond-below(mobile_max) {
      padding-bottom: 80px;
      margin-bottom: 80px;
    }
    li {
      @include respond-above(desktop) {
        margin-bottom: 160px !important;
      }
    }
  }
  div.card--feature {
    & > a {
      background: $white-900;
      box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
        0 1px 3px 0 rgba(48, 58, 71, 0.1);
      border: none;
      padding: 24px 24px 32px !important;
      h3 {
        margin-bottom: 16px !important;
      }
      @include respond-below(mobile_max) {
        padding: 20px 20px 24px !important;
        &:not(:first-child) {
          margin-top: 24px !important;
        }
        h3 {
          margin-bottom: 8px !important;
        }
        p {
          margin-top: 0px !important;
        }
      }
    }
  }
  .faq-section {
    li {
      box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1),
        0 4px 8px 0 rgba(48, 58, 71, 0.14);
    }
  }
  @include respond-below(mobile_max) {
    .hero--default {
      h1 {
        margin-bottom: 16px;
      }
      p {
        margin: 0 10px 24px 0 !important;
      }
    }
    .trustpilot-widget-micro {
      height: 30px !important;
    }
    h2 {
      font-size: 32px !important;
      line-height: 40px !important;
    }
    .has-align-top {
      &:nth-child(1) {
        article {
          margin-top: 32px !important;
          h2 {
            margin-right: 0px !important;
            margin-bottom: 24px !important;
          }
        }
      }
      div {
        display: block !important;
      }
    }
    .faq-section {
      h2.text-xl {
        margin-bottom: 40px !important;
      }
    }
  }
}

.features-booking-page,
.features-your-branded-app {
  .features {
    background-color: transparent !important;
  }

  .svg-wrap {
    position: absolute;
    top: 8%;

    &.svg-align-left {
      left: -34px;
    }

    &.svg-align-right {
      right: -34px;
    }

    .svg-item {
      text-align: center;
      border-radius: 8px;
      padding: 19px;
      background-color: $color-white;
      box-shadow: 0px 4px 4px 0px #00000040;
    }
  }

  .product-card {
    background-color: $color-white !important;
    border: none !important;
    padding: 24px 24px 32px !important;
  }

  .card--default.card--feature .btn-outline {
    background-color: $color-white;
    border: none;
    box-shadow: 0px 2px 4px 0px #303a471a, 0px 4px 8px 0px #303a4724;
  }

  .bg-offset-bottom {
    position: relative;

    &::after {
      background-color: $light-grey-100 !important;
    }
  }

  .bg-offset::after {
    background-color: $light-grey-100 !important;
    height: 140px;
    top: 0;
  }

  .grid--default {
    h2 {
      font-size: 32px !important;
      line-height: 40px !important;
    }
  }

  .grid--default.hero--default::after {
    height: 50px !important;
  }

  @include respond-below(tablet_sm) {
    .features.bg-offset-bottom::after {
      height: 75% !important;
    }

    .block--align-center {
      margin: 0 20px !important;

      br {
        display: none;
      }
    }

    .bg-offset {
      margin-bottom: 80px;

      &::after {
        height: 90% !important;
      }
    }

    .video-section {
      margin-top: 60px;
      .video-highlight {
        margin-top: -6%;
      }
    }

    .has-align-middle article {
      margin-top: 40px;
    }

    .svg-wrap {
      top: 16%;

      &.svg-align-left {
        left: 90%;
      }

      &.svg-align-right {
        right: -10px;
      }

      .svg-item {
        svg {
          width: 26px;
          height: 26px;
        }

        margin-block: 8px !important;
        padding: 12px;
      }
    }

    .hero--default {
      h1 {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 16px !important;
      }
    }

    .cta-contrast.has-image h2,
    .use-setmore-wrap h2 {
      font-size: 32px !important;
      line-height: 40px !important;
    }

    .people-love-setmore > span {
      display: inline !important;
    }
  }

  @include respond-above(desktop_sm) {
    .cta-w-image article {
      padding: 80px 100px !important;

      a {
        padding: 12px 32px;
      }
    }
  }

  @include respond-above(tablet_sm) {
    .bg-offset.bg-offset--adapt {
      padding-bottom: 0 !important;
    }

    .cta-contrast picture {
      right: 99px !important;
    }

    .faq-section.bg-offset-top::before {
      height: 0 !important;
    }

    .features::after {
      height: 290px !important;
    }

    .faq-section {
      background-color: $black-200;
    }

    .video-section {
      margin-top: 45px;
      article {
        h2 {
          padding: 80px 0 0;
          font-size: 40px !important;
          line-height: 48px !important;
        }
      }
    }

    .video-highlight {
      margin-top: -12%;
    }

    h2 {
      font-size: 40px !important;
      line-height: 48px !important;
    }

    .people-love-setmore * {
      font-size: 32px !important;
      line-height: 40px !important;
    }

    .hero--default {
      h1 {
        font-size: 40px;
        line-height: 48px;
      }

      .layered-images-wrap {
        vertical-align: top;
      }
    }

    .use-setmore-wrap article {
      margin-top: 40px;
    }

    .use-setmore-wrap picture img {
      margin-top: -40px;
      width: 560px !important;
      height: 378px !important;
    }

    .use-setmore-wrap div {
      justify-content: space-evenly;
      margin-bottom: 40px;
    }
  }

  @include respond-below(tablet) {
    .use-setmore-wrap picture {
      display: flex;
      justify-content: center;

      img {
        aspect-ratio: 385/255 !important;
        width: 100% !important;
        max-width: calc(100% - 34px);
      }
    }

    .use-setmore-wrap h2 {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }
}

.features-your-branded-app {
  .hero--default {
    h1 {
      max-width: 440px;
      margin-bottom: 24px;
    }
    @include respond-above(tablet_sm) {
      margin-bottom: 50px;
    }
  }
  .highlight {
    @include respond-above(tablet_sm) {
      padding-bottom: 120px !important;
    }
    background-color: $light-grey-100 !important;
  }
  .highlight + section {
    .card--default {
      li {
        cursor: default;
      }
    }
  }
  .cta-contrast {
    background-color: $green-500 !important;
    article {
      .btn-ternary {
        background-color: $green-800 !important;
        color: $white-900 !important;
        &:hover {
          background-color: $green-900 !important;
        }
      }
    }
  }
  .card--default {
    .icon-title-wrap {
      margin-bottom: 0px !important;
    }
    h3 {
      color: $green-500 !important;
      @include respond-above(tablet_sm) {
        margin-bottom: 16px !important;
      }
    }
    li:hover,
    a:hover {
      h3 {
        color: $green-400 !important;
      }
    }
    a:hover {
      box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
        0 1px 4px 0 rgba(22, 45, 61, 0.1) !important;
    }
  }
  .text-brandcolor {
    color: $green-500 !important;
    &:hover {
      color: $green-400 !important;
    }
  }
  .bg-offset-bottom::after {
    background-color: $white-900 !important;
  }
  section {
    @include respond-above(tablet_sm) {
      &:nth-of-type(5) {
        padding-top: 190px !important;
        .grid--default {
          margin-bottom: 180px !important;
        }
      }
      &:nth-of-type(3) {
        .grid--default {
          picture {
            margin-left: -5%;
          }
        }
      }
      .grid--default {
        h2 {
          font-size: 40px !important;
          line-height: 48px !important;
        }
        picture {
          margin-top: -10%;
          max-width: 670px;
          width: 100%;
          height: 410px;
        }
      }
    }
    .cta-contrast.has-image {
      margin-top: 40px;
    }
    &.features {
      @include respond-above(tablet_sm) {
        padding-bottom: 160px !important;
      }
      article {
        max-width: 600px;
      }
    }
  }
  .faq-section {
    background-color: $light-grey-100 !important;
    &.bg-offset-top::before {
      height: 270px !important;
      background-color: $light-grey-100 !important;
      position: absolute;
      top: -265px;
      z-index: -1;
      width: 100%;
    }
    li label {
      &::after {
        color: green !important;
      }
    }
    @include respond-above(tablet_sm) {
      h2 {
        font-size: 56px !important;
        line-height: 64px !important;
      }
    }
  }
  @include respond-below(tablet_sm) {
    .hero--default {
      padding-bottom: 80px !important;
      h1 {
        font-size: 32px;
        line-height: 40px;
      }
      p {
        margin: 0 0 24px 0 !important;
      }
      figure {
        margin-top: 20px !important;
      }
    }
    .highlight {
      padding-top: 0px !important;
      position: relative;
      .grid--default {
        p {
          margin-bottom: 0px !important;
        }
        picture {
          margin-top: -5%;
        }
      }
    }
    .card--default {
      a {
        margin-top: 24px !important;
      }
    }
    .features {
      h2 {
        font-size: 32px;
        line-height: 40px;
        text-align: center;
      }
    }
    .faq-section {
      &.bg-offset-top::before {
        height: 600px !important;
        top: -540px;
      }
      h2.text-xl {
        margin-bottom: 40px !important;
      }
    }
  }
}

.features-live-receptionist {
  section {
    &:nth-of-type(n + 3) {
      background-color: $light-grey-100 !important;
    }
    &.use-setmore-wrap,
    &:nth-of-type(5) {
      h2 {
        font-size: 32px;
        line-height: 40px;
      }
    }
    &.hero--default {
      position: relative;
      @include respond-above(tablet) {
        padding-bottom: 120px !important;
        h1 {
          font-size: 40px;
          line-height: 48px;
        }
      }
      article {
        max-width: 460px;
      }
      figure {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
      }

      figure video {
        width: calc(50vw - 20px) !important ;
        height: 600px !important;
        object-fit: cover;
        @include respond-below(desktop) {
          width: calc(50vw - 5px) !important ;
        }
      }
    }
    &.use-setmore-wrap {
      @include respond-above(tablet) {
        h2 {
          margin-top: 80px !important;
        }
      }
      div {
        justify-content: space-evenly;
      }
      @include respond-above(desktop_sm) {
        picture img {
          margin: -40px 0 0 -40px;
          width: 670px;
          height: 414px;
        }
      }
    }
    .card--feature {
      padding-bottom: 16px;
      li {
        p {
          padding-right: 0 !important;
        }
      }
    }
    &:nth-of-type(5) {
      @include respond-above(tablet) {
        padding-bottom: 160px;
        article {
          &:nth-child(odd) {
            padding-right: 100px;
          }
          &:nth-child(even) {
            padding-left: 100px;
          }
        }
      }

      div:last-child {
        margin-bottom: 0px !important;
      }
    }
    .cta-light-green-theme {
      @include respond-above(tablet) {
        padding-inline: 100px;
      }
      article {
        h2 {
          font-size: 24px;
          line-height: 32px;
        }
        p {
          margin-bottom: 40px !important;
          & > a {
            border-bottom: 1px solid $white-900;
          }
        }
        .audio-btn {
          background-color: transparent;
          border: none;
          cursor: pointer;
        }
        .btn-ternary {
          background-color: $green-800;
          color: $white-900;
        }
      }
      picture img {
        width: 460px;
        height: 554px;
      }
    }
    &.faq-section {
      h2 {
        font-size: 40px;
        line-height: 48px;
      }
      .faq-point a {
        border-bottom: 1px solid $grey-800;
      }
    }
    @include respond-below(tablet) {
      &.use-setmore-wrap {
        padding-top: 40px !important;
        margin-top: 10px;
        picture img {
          margin-top: -60px;
          width: 100% !important;
          aspect-ratio: 406 / 252 !important ;
        }
      }
      &.use-setmore-wrap,
      &:nth-of-type(5) {
        h2 {
          font-size: 24px;
          line-height: 32px;
        }
      }
      .card--feature {
        li:last-child {
          margin-bottom: 80px;
        }
      }
      &:nth-of-type(5) {
        margin-bottom: 0 !important;
        padding-bottom: 80px;
        article {
          margin-top: 40px;
        }
      }
      .cta-light-green-theme {
        p {
          margin-bottom: 40px !important;
        }
        a {
          margin-top: 0px !important;
        }
      }
      &.faq-section {
        h2.text-xl {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 40px !important;
        }
      }
    }
  }
}
