// For Integration Root Page - Integration Cards
.integration-new {
  .cta-contrast.has-image {
    @include respond-above(desktop) {
      padding-top: 80px;
      padding-bottom: 88px;
    }
    h2 {
      @extend .text-lg;
    }
  }
  .root-integrations {
    &.bg-offset::after {
      height: 36%;

      @include respond-below(tablet) {
        height: 15%;
      }
    }
  }
  .custom-bg-offset {
    .bg-offset::after {
      bottom: 0;
      height: 1000px;

      @include respond-below(tablet) {
        height: 1000px;
        bottom: 0;
      }
    }
  }
  .home-integrations::before,
  .root-integrations::before {
    content: "";
    padding-top: 80px;
    margin-top: -80px;
    width: 1px; /* '0' will not work for Opera */
    display: inline-block;
  }
  @include respond-below(tablet) {
    .c-padding {
      > a {
        padding: 20px 20px 24px 20px;

        figure {
          margin-right: 24px;

          svg {
            width: 40px;
            height: 40px;
          }

          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  .cta-contrast .dp-block {
    // background-color: #fff!important;
    @include respond-below(tablet) {
      display: block;
    }
  }

  .mg-right {
    margin-right: 5px;
  }

  .jc-fs {
    justify-content: flex-start;
  }

  .custom-size {
    .see-all {
      background-color: transparent !important;
      text-align: center;

      &:hover {
        box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
          0 1px 4px 0 rgba(22, 45, 61, 0.1);
        border: none;
        transition: 0.3s;
      }

      p {
        font-size: 18px;
        line-height: 28px;
        color: #3b4c66;
      }

      // text-align: center;

      p {
        font-size: 18px;
        line-height: 28px;
        color: #3b4c66;
      }

      .text-sm {
        color: $color-brand !important;
      }
    }
  }
  .font-italic {
    font-style: italic;
  }
}
.integration-new-ab-testing {
  .animated-sub-nav {
    box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
    border-radius: 8px;
    padding: 6px 20px 22px 20px;
    .nav-categories {
      max-width: 800px;
      > li {
        display: inline-block;
        max-width: 170px;
        margin-top: 16px;
        margin-left: 16px;
        width: auto;
        opacity: 1;
        transition: 0.2s opacity;
        border-radius: 18px;
        @include respond-below(tablet) {
          margin-top: 0px;
        }
        &:first-child {
          margin-left: 0;
        }
        a {
          display: block;
          border-radius: inherit;
          padding: 6px 30px 6px 30px;
          text-align: center;
          border: 1px solid $black-300;
          &:hover {
            border-color: $color-brand;
            color: $color-brand;
          }
          &:active {
            border-color: $color-brand;
            color: white;
            background-color: $blue-500;
          }
          &.active {
            border-color: $black-200;
            background-color: $black-200;
            color: $black-600;
          }
        }
      }
    }
    .nav-form {
      border-radius: 18px !important;
      border: 1px solid $black-300;
      padding: 6px 8px 6px 16px;
      margin-top: 16px;
      margin-left: 16px;
      min-width: 240px;
      box-shadow: none;
      height: 36px;
      display: flex;
      align-items: center;
      @include respond-below(tablet) {
        & {
          width: 100%;
        }
      }
      &:hover {
        border-color: $color-brand;
      }
      &.focus {
        border-color: $color-brand;
        background-color: white;
        .search-btn svg .svg-color {
          fill: $blue-500;
        }
        .search-btn:hover svg .svg-color {
          fill: white;
        }
        input {
          background-color: white;
        }
      }
      input[name="search-field"] {
        width: calc(100% - 52px);
        height: 100%;
        vertical-align: middle;
        color: $black-600;
        background-color: transparent;
        font-size: 14px;
        line-height: 24px;
        font-family: $default-font;
        padding: 0;
        @include respond-below(tablet) {
          font-size: 16px;
        }
        &::placeholder {
          color: $black-400;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: $black-400;
        }
        & ::-ms-input-placeholder {
          color: $black-400;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          box-shadow: 0 0 0px 1000px white inset !important;
          -webkit-box-shadow: 0 0 0px 1000px white inset !important;
          -webkit-text-fill-color: $black-600 !important;
          color: $black-600 !important;
        }
      }
      .clear-search-btn {
        visibility: hidden;
        vertical-align: middle;
        margin-right: 8px;
        @include respond-below(tablet) {
          margin-right: 0;
        }
        &:hover {
          cursor: pointer;
          .svg-color {
            fill: $blue-500;
          }
        }
        &.show {
          visibility: visible;
        }
      }
      button {
        background-color: transparent;
        padding: 0;
        margin: 0;
        outline: 0;
        border: 0;
      }
      .search-btn {
        // position: absolute;
        // top: 3px;
        height: 28px;
        // right: 4px;
        padding: 4px;
        &:hover {
          cursor: pointer;
          background-color: $blue-500;
          border-radius: 50%;
          .svg-color {
            fill: white;
          }
        }
      }
    }
    &.has-form {
      display: flex;
      justify-content: space-between;
    }

    &.animate-nav {
      & > * {
        opacity: 0;
      }
      transform: scaleY(0);
    }
    @include respond-below(tablet) {
      &.has-mobile-scroll {
        box-shadow: none;
        border-radius: 0px;
        background-color: #edf0f2;
        margin-left: 0;
        margin-right: 0;
        padding: 20px 20px 0;
        flex-direction: column;
      }
      .nav-form {
        background-color: transparent;
      }
      & .mobile-scroll {
        width: 100%;
        overflow-x: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
          background: transparent; /* Chrome/Safari/Webkit */
        }
      }
      & .nav-categories {
        white-space: nowrap;
      }
    }
  }
  .industry-masonry.bg-offset::after {
    top: 0;
    height: 1165px;
    @include respond-below(tablet) {
      height: 2700px;
    }
  }
  .all-integrations,
  .home-integrations,
  .results-container,
  .no-results-container {
    display: none;
  }
  .results-container {
    #integration-tile {
      display: none;
    }
  }
  .show {
    display: inherit !important;
  }
  .hide {
    display: none !important;
  }
  .search-results {
    justify-content: flex-start;
    & a {
      margin-right: 40px;
      margin-top: 40px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      @include respond-above(tablet) {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0;
        }
      }
      @include respond-between(tablet, tablet_max) {
        margin-right: 20px;
      }
      @include respond-below(tablet) {
        margin-top: 20px;
        margin-right: 0px;
        &:first-child {
          margin-top: 0px;
        }
      }
    }
  }
  @include respond-below(tablet) {
    #no-results-container {
      h2 {
        font-size: 24px;
        line-height: 1.33;
      }
    }
  }
}
.freshdesk,
.zoom,
.clio {
  .hero--default a {
    display: inline-block;
  }
  @include respond-below(tablet) {
    & .integrations-faq h3 {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

.freshdesk {
  @include respond-above(tablet) {
    & .industry-features.bg-offset--adapt::after {
      height: 112px;
    }
  }
  @include respond-below(tablet) {
    & .industry-features.bg-offset::after {
      height: 413px;
    }
  }
}

.clio {
  @include respond-above(tablet) {
    & .industry-features.bg-offset--adapt::after {
      height: 144px;
    }
  }
  @include respond-below(tablet) {
    & .industry-features.bg-offset::after {
      height: 443px;
    }
  }
}

.zoom {
  .industry-features.bg-offset--adapt::after {
    height: 160px;
  }
  @include respond-below(tablet) {
    & .industry-features.bg-offset--adapt::after {
      height: 456px;
    }
    & .bg-offset-top::after {
      height: 124px;
    }
  }
}
.square-online {
  .industry-features.bg-offset--adapt::after {
    height: 160px;
  }
  .square-online-services li:last-child {
    picture {
      transform: scale(1.04);
      margin-left: 12px;
    }
  }
  @include respond-below(tablet) {
    & .industry-features.bg-offset--adapt::after {
      height: 510px;
    }
    & .bg-offset-top::before {
      height: 124px;
    }
    & .square-online-services li:last-child {
      picture {
        transform: scale(1.08);
      }
    }
  }
}
.clio,
.facebook,
.teleport {
  .faq-section.bg-offset-top::before {
    @include respond-above(tablet) {
      height: 150px;
    }
    @include respond-below(tablet) {
      height: 120px;
    }
  }
}

.freshdesk,
.square-online,
.zoom {
  .faq-section.bg-offset-top::before {
    @include respond-above(tablet) {
      height: 210px;
    }
  }
}
.freshdesk,
.zoom {
  .faq-section.bg-offset-top::before {
    @include respond-below(tablet) {
      height: 202px;
    }
  }
}
.integration-listing-page {
  .logo-holder figure {
    max-width: 260px;
  }
  .faq-section .faq-ques {
    padding: 26px 30px 28px 20px;
    &::after {
      position: absolute;
      top: 50%;
      right: -56px;
      width: 24px;
      height: 24px;
      border: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2348A36C' fill-rule='evenodd' d='M21.797 15.864c.27.26.27.681 0 .941-.245.234-.625.257-.897.07l-.086-.07L12 8.36l-8.814 8.445c-.244.234-.625.257-.896.07l-.087-.07c-.244-.234-.268-.599-.073-.859l.074-.082 8.866-8.495c.246-.236.581-.369.93-.369.3 0 .588.098.819.274l.11.095 8.868 8.495z' clip-rule='evenodd'/%3E%3C/svg%3E");
      transform: translateY(-50%) rotate(180deg);
    }
    @include respond-below(tablet) {
      &::after {
        right: -63px;
      }
      padding-right: 0;
    }
  }
  .faq-section input:checked + .faq-ques::after {
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
  }
  .ordered-bulletin {
    & > li {
      position: relative;
      padding-left: 38px;
      margin-bottom: 12px;
      &::before {
        position: absolute;
        left: 0px;
        top: 5px;
        width: 18px;
        height: 18px;
        line-height: 18px;
      }
    }
  }
  @include respond-below(tablet) {
    .cta-contrast.has-image h2 {
      font-size: 24px !important;
      line-height: 30px !important;
    }
    .lander-cta {
      .cta-contrast.has-image h2 {
        font-size: 24px !important;
        line-height: 32px !important;
      }
    }
    .faq-section.bg-offset-top::before {
      height: 95px;
    }
    .faq-section input:checked ~ .faq-point {
      padding-bottom: 24px;
      max-height: initial;
    }
    &.integrations-faq h3 {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  @include respond-above(tablet) {
    .faq-section input:checked ~ .faq-point {
      max-height: initial !important;
    }
    .logo-holder {
      &.is-sticky {
        position: fixed !important;
        top: 180px !important;
        @include z-index(10);
      }
      &.is-mutable {
        opacity: 0;
      }
      &.is-visible {
        opacity: 1;
      }
      &.is-fixed {
        position: absolute;
        bottom: 0;
      }
    }
    .logo-content-holder {
      &.content-sticky {
        position: relative;
        left: 400px;
      }
    }

    .faq-section.bg-offset-top::before {
      height: 200px;
    }
    .cta-contrast.has-image h2 {
      font-size: 38px;
      line-height: 48px;
    }
    .lander-cta {
      .cta-contrast.has-image h2 {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
}

.integration-listing-google-meet {
  @include respond-above(tablet) {
    .hero--default {
      margin-bottom: 80px;
    }
  }
}

.integration-lander-square-payment {
  .hero--default img {
    aspect-ratio: 560/562;
  }
  @include respond-above(tablet) {
    .cta-contrast {
      min-height: 368px;
    }
  }
  &.integration-listing-page .faq-section.bg-offset-top::before {
    @include respond-above(tablet) {
      height: 150px;
    }
    @include respond-below(tablet) {
      height: 120px;
    }
  }
  .faq-section h2 {
    margin-bottom: 40px !important;
  }
}

.integrations-google-analytics .hero--default article {
  margin-bottom: 168px;
}
@media (max-width: 768px) {
  .integrations-google-analytics .hero--default article {
    margin-bottom: 0px;
  }
}

// To fix cls in highlight section
.fixed-fx {
  flex-direction: row-reverse;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .fixed-fx article {
    margin-right: 100px;
  }
}

// CUSTOMISE SECTION
.customise-wrap {
  align-items: center;
  &.has-content-left {
    > article {
      margin-left: 100px;
      @include respond-between(tablet, desktop) {
        margin-left: 20px;
      }
      @include respond-below(tablet) {
        margin-left: 0px;
      }
    }
  }
  &.has-content-right {
    > article {
      margin-right: 100px;
      @include respond-between(tablet, desktop) {
        margin-right: 20px;
      }
      @include respond-below(tablet) {
        margin-right: 0;
      }
    }
  }
  @include respond-above(tablet) {
    h2 {
      margin-bottom: 24px;
    }
  }
  @include respond-below(tablet) {
    h2 {
      margin-bottom: 20px;
    }
    article {
      padding: 40px 20px 20px;
    }
  }
}

.integration-google-reviews {
  section h1,
  h2,
  p {
    color: $black-900;
  }
  @include respond-above(tablet) {
    .hero--default {
      padding-bottom: 120px !important;
      img {
        width: 680px;
        height: 414px;
      }
    }
    section:nth-of-type(3) {
      .card--default h3 {
        margin-top: 24px !important;
      }
    }

    .logo-content-holder {
      article:first-child {
        p {
          max-width: 730px;
        }
      }
      h2 {
        font-size: 40px;
      }
      p {
        margin-top: 24px !important;
      }
    }
    .cta-contrast.has-image {
      picture {
        right: 200px !important;
        img {
          width: 460px;
          height: 476px;
        }
      }
    }
    .highlight-section {
      padding-bottom: 160px !important;
    }
  }

  @include respond-above(desktop) {
    .logo-content-holder {
      .video-highlight,
      iframe {
        width: 760px !important;
        height: 420px;
      }
    }
  }
  @include respond-below(tablet) {
    h1 {
      font-size: 32px;
      line-height: 40px;
    }
    .hero--default figure {
      margin-bottom: 60px !important;
      picture {
        text-align: center;
        img {
          max-width: 336px;
          max-height: 204px;
        }
      }
    }
    .card--feature {
      a:not(:first-child) {
        margin-top: 20px !important;
      }
    }
    .highlight-section h2 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 0px;
    }
    .faq-section {
      h2 {
        font-size: 40px;
        margin-bottom: 40px !important;
      }
      &.bg-offset-top::before {
        height: 134px !important;
      }
    }
    .logo-content-holder {
      .video-highlight {
        margin-top: 16px;
      }
    }
    .cta-contrast.has-image article {
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
      }
    }
  }
  .cta-contrast {
    h2,
    p {
      color: $white-900;
    }
    .cta-section {
      @include respond-above(tablet) {
        .cta-contrast {
          padding-inline: 100px;
        }
      }
    }
  }

  footer p {
    color: $white-900;
  }
  section {
    &:nth-of-type(3),
    &:nth-of-type(7) {
      &::before,
      &::after {
        background-color: $light-grey-100;
      }
    }

    &:nth-of-type(n + 3):not(:nth-of-type(7)):not(:nth-of-type(8)) {
      background-color: $light-grey-100 !important;
    }
  }
}
