.tour-landing-page {
  .cta-contrast.has-image h2 {
    font-size: 24px;
    line-height: 32px;
  }
  @include respond-above(tablet) {
    .card--default.card--feature.has-shadow li:hover {
      box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
        0 1px 4px 0 rgba(22, 45, 61, 0.1);
    }
    .grid--default > * {
      width: initial !important;
    }
    .grid--default > picture {
      margin-right: -40px;
      max-width: initial;
      & > img {
        aspect-ratio: 640/480;
      }
    }
    .cta-contrast.has-image h2 {
      font-size: 38px;
      line-height: 48px;
    }
  }
  .billing-savings-off {
    display: initial !important;
    @include respond-above(tablet) {
      & p:nth-child(1) {
        margin-top: 18px;
      }
    }
    & p:nth-child(2) {
      display: none;
    }
  }
}
