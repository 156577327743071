.hide {
  display: none;
}
.show {
  display: block;
}
.response-screen {
  width: 460px;
  height: 500px;
  padding: 90px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(48, 58, 71, 0.15),
    0px 3px 6px rgba(48, 58, 71, 0.08);
}
#form-btn {
  border: none;
  vertical-align: middle;
  cursor: pointer;
  outline: 0;
}
//form
.form-holder {
  position: absolute;
  right: 85px;
  @include respond-below(tablet) {
    position: relative;
    right: 0px;
  }
  .toast {
    left: 0%;
    right: 0%;
  }
  .custom-select {
    position: relative;
    select {
      display: none;
    }
  }
  .select-selected {
    padding: 32px 0 10px 0;
    border-bottom: 1px solid #d3d9e5 !important;
    background-color: transparent;
  }
  /*style the arrow inside the select element:*/
  .select-selected:after {
    position: absolute;
    content: "";
    top: 40px;
    right: 10px;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    min-width: 8px;
    border: 1px solid #181818;
    border-width: 0 0 1px 1px;
    transition: all 0.3s ease;
  }

  /*point the arrow upwards when the select box is open (active):*/
  .select-selected.select-arrow-active:after {
    transform: rotate(135deg);
  }

  /*style the items (options), including the selected item:*/
  .select-items div,
  .select-selected {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    border: none;
    width: 100%;
    color: #8495b1;
    font-size: 16px;
    line-height: 24px;
  }

  /*style items (options):*/
  .select-items {
    position: absolute;
    background-color: #fff;
    padding-bottom: 24px;
    box-shadow: 0px 2px 4px rgba(22, 45, 61, 0.2);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid $color-brand;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    div {
      padding-left: 12px;
      padding-top: 24px;
      padding-bottom: 0px;
    }
  }

  /*hide the items when the select box is closed:*/
  .select-hide {
    display: none;
  }
  .same-as-selected {
    color: $color-brand !important;
    &::after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='%231D90F5' fill-rule='evenodd' transform='translate(-36 -174)'%3E%3Cpath d='M52.225 178.975c.244-.244.64-.244.884 0 .221.222.242.569.06.814l-.06.07-9.167 9.166c-.222.222-.57.242-.814.06l-.07-.06-4.167-4.166c-.244-.244-.244-.64 0-.884.222-.222.57-.242.814-.06l.07.06 3.725 3.724 8.725-8.724z'/%3E%3C/g%3E%3C/svg%3E%0A")
        no-repeat;
      background-size: contain;
      position: absolute;
      right: 18px;
    }
  }
}
@include respond-above(tablet) {
  .bg-offset-top::before {
    content: "";
    height: 400px;
  }
  .industry-masonry {
    .text-xl {
      font-size: 48px;
    }
    .lg\:maxw-xls {
      max-width: 680px;
      align-items: center;
    }
  }
  .cta-contrast.has-image {
    padding-left: 80px;
    h2 {
      font-size: 38px;
      line-height: 48px;
    }
  }
  .industry-features {
    h2 {
      font-size: 48px;
      line-height: 48px;
    }
    .card--default.card--showcase > * {
      padding: 32px 26px;
    }
    .icon-title-wrap {
      .numbered-list {
        width: 50px;
        height: 50px;
        font-family: $primary-font;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        color: #8996a3;
        border-radius: 100%;
        margin-top: 10px;
        padding: 3px 13px;
        border: solid 1.5px #8996a3;
      }
    }
  }
}
@include respond-below(tablet) {
  .highlight {
    .container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
  }
  .home-integrations {
    .text-lg {
      font-size: 38px;
      line-height: 48px;
    }
  }
  .industry-features {
    .card--default.card--showcase > * {
      padding: 15px 15px 24px;
      margin-bottom: 20px;
    }
    .icon-title-wrap {
      .numbered-list {
        width: 24px;
        height: 24px;
        font-family: $primary-font;
        font-size: 14.4px;
        font-weight: bold;
        line-height: 1.17;
        text-align: center;
        color: #8996a3;
        margin-top: 5px;
        border-radius: 100%;
        padding: 2px 8px;
        border: solid 0.9px #8996a3;
      }
    }
    &.bg-offset::after {
      height: 830px;
    }
  }
}
