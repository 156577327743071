body {
  color: $color-primary;
}
.ff-euclid {
  font-family: $primary-font;
}
.ff-inter {
  font-family: $secondary-font;
}

input,
input:focus {
  font-size: 16px;
}
