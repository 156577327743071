* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*:focus-visible {
  outline: 4px solid rgba(0, 125, 250, 0.6);
  outline-offset: 1px;
}

*:focus:not(:focus-visible) {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

cite {
  font-style: normal;
}

.dot {
  margin: 0;
  color: $blue-500;
  vertical-align: top;
  font-size: inherit;
}
.dot--no-color {
  margin: 0;
  vertical-align: top;
  font-size: inherit;
}
.quot-glyph {
  font-family: Georgia;
  font-size: 80px;
  line-height: 70px;
  color: $color-white;
}

h1,
h2 {
  font-family: $primary-font;
  color: $color-dark;
  font-weight: $semibold;
}
h1 {
  margin-bottom: 32px;
  @include respond-below(tablet) {
    margin-bottom: 20px;
  }
}
.text-xl {
  font-size: 56px;
  line-height: 64px;
  margin-bottom: 32px;
  @include respond-below(tablet) {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 20px;
  }
}

.text-lg {
  font-size: 38px;
  line-height: 48px;
  @include respond-below(tablet) {
    font-size: 32px;
    line-height: 40px;
  }
}

.text-mdl {
  font-size: 32px;
  line-height: 40px;
  @include respond-below(tablet) {
    font-size: 24px;
    line-height: 32px;
  }
}

.text-md {
  font-size: 20px;
  line-height: 28px;
}

p,
.text-sm {
  font-size: 18px;
  line-height: 28px;
}

.text-xs {
  font-size: 14px;
  line-height: 24px;
}

.text-xxs {
  font-size: 12px !important;
  line-height: 20px;
}

.text-smx {
  font-size: 16px !important;
  line-height: 28px;
}

.md\:text-smx {
  @include respond-below(tablet) {
    font-size: 16px !important;
    line-height: 28px;
  }
}
.md\:text-mds {
  @include respond-below(tablet) {
    font-size: 32px;
    line-height: 40px;
  }
}

picture,
figure {
  display: block;
  font-size: 0;
  img {
    width: 100%;
  }
  figcaption {
    font-size: initial;
  }
}

input,
input:focus {
  border: 0;
  outline: 0;
}

.container {
  max-width: 1160px;
  margin-right: auto;
  margin-left: auto;
  @include respond-below(desktop) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

// Others
// _________________________
.ordered-bulletin {
  li {
    margin-bottom: 20px;
    &::before {
      content: attr(data-id);
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 18px;
      font-size: 12px;
      line-height: 20px;
      border: 1px solid;
      border-radius: 50%;
      text-align: center;
      position: relative;
      top: -2px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ticked-bulletin {
  > li {
    @include tick(20px, 20px);
  }
}

.hover-link {
  @extend %link-hover;
}

.card--default {
  h3 {
    &.text-brandcolor:hover {
      color: $color-brand;
    }
  }
}
.hover-review-card {
  &:hover {
    @extend .hover-shadow;
    background-color: $color-white;
    border-color: transparent;
    transition: none;
  }
}

.hover\:box-shadow {
  &:hover {
    box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
      0 1px 4px 0 rgba(22, 45, 61, 0.1) !important;
    background-color: #fff !important;
    border-color: transparent !important;
    transition: 0.3s;
    cursor: pointer;
  }
}

.hover-link-blue {
  @extend %link-hover;
  &:hover {
    color: $blue-800;
    &::after {
      background-color: $blue-800;
    }
  }
}

.hover-link,
.hover-link-blue {
  &.has-dropdown {
    &::after {
      display: none !important;
    }
  }
}

.bg-offset {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: $black-200;
    @include z-index(minus-1);
  }
}
.bg-offset-top {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 250px;
    left: 0;
    background-color: $black-200;
    @include z-index(minus-1);
  }
  @include respond-below(tablet) {
    &::before {
      height: 230px;
    }
  }
}
.bg-offset-bottom {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    left: 0;
    background-color: $black-200;
    @include z-index(minus-1);
  }
}
@include respond-below(tablet) {
  .icon-title-wrap {
    figure {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.emoji-lg {
  font-size: 40px;
  @include respond-below(tablet) {
    font-size: 28px;
  }
}

.lazy-image {
  transition: 0.5s filter;
  filter: blur(3px);
  &.is-loaded {
    filter: blur(0px);
  }
}

// Navigation Indicator
.integration_active .integration_list,
.mission_active .mission_list,
.industries_active .industries_list,
.pricing_active .pricing_list,
.feature_active .feature_list {
  border-bottom: 1px solid #3b4c66;
  color: #3b4c66;
  &::after {
    height: 0;
  }
  // Nav - no bottom border
}
.no-border {
  &:hover {
    color: #1265b8;
  }
  &::after {
    height: 0 !important;
  }
}

//Dummy element for hiding
.slash {
  top: -3000px;
  left: -3000px;
  position: absolute;
}
//numbered list
.numbered-list {
  border: solid 2px #556784;
  border-radius: 25px;
  padding: 0px 5px;
  text-align: center;
  font-size: 11px;
  margin-right: 16px;
  height: 20px;
  line-height: normal;
  margin-top: 6px;
}
.list-with-icon > li::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%2348A36C'%3E%3Cg%3E%3Cpath d='M11.925 1.925c5.523 0 10 4.477 10 10s-4.477 10-10 10-10-4.477-10-10 4.477-10 10-10zm0 1.39c-4.755 0-8.61 3.855-8.61 8.61 0 4.755 3.855 8.61 8.61 8.61 4.755 0 8.61-3.855 8.61-8.61 0-4.755-3.855-8.61-8.61-8.61zm3.704 5.366c.268-.268.702-.268.97 0 .241.241.268.621.052.91l-.052.06-5.657 5.657c-.241.24-.622.267-.91.052l-.06-.052-2.571-2.572c-.268-.267-.268-.702 0-.97.241-.24.621-.267.91-.052l.06.053 2.086 2.086z' transform='translate(-740 -1513) translate(740 1513)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  margin-right: 12px;
  content: "";
  display: inline-block;
  position: relative;
  top: 5px;
  width: 24px;
  height: 24px;
  background-size: contain;
}

.page-with-widget {
  @include respond-below(tablet) {
    .trustpilot-widget-micro {
      width: 272px;
      margin: 0 auto;
    }
    .trustpilot-widget-macro .trustpilot-widget > iframe {
      height: 300px;
    }
    .app-review-wrap {
      position: static;
    }
  }
}

// Carousel
@include respond-above(tablet) {
  .video-highlight {
    position: relative;
    border-radius: 8px;
    background-color: transparent;
    overflow: hidden;
    width: 560px;
    height: 316px;
  }
  .cta-img-gradient {
    background-image: linear-gradient(
      to bottom,
      white,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    width: 452px;
    height: 100px;
    display: flex;
    top: 176px;
    position: absolute;
    transform: rotate(-90deg);
    z-index: 1;
  }
}
@include respond-below(tablet) {
  .video-highlight #videoUtube,
  .video-highlight #videoUtubeOne {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 99.8%;
    height: 100%;
    z-index: 1;
  }
  #blogPostsContainer {
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    margin-left: -20px;
    width: 100%;
    padding: 0px 0px 13px 0px;
    margin-right: 0px;
    a {
      display: inline-block;
      padding: 24px 26px 24px 24px;
      margin: 0px 10px;
      width: 295px;
      vertical-align: bottom;
      height: 232px;
      h3,
      p {
        white-space: pre-line;
      }
    }
  }
  .video-section {
    .video-highlight {
      height: auto !important;
    }
  }
}

.video-section {
  .video-highlight {
    width: 560px;
    height: 314px;
  }
}
// Video styles
.video-highlight {
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  // cursor: pointer;

  .video-timg {
    position: relative;
    display: inline-block;
    border-radius: 8px;
    width: 100%;
    @include respond-below(tablet) {
      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 10px;
        position: absolute;
        bottom: 0;
        background: #f6f8fc;
      }
    }
    background-color: transparent;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 8px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s;
    }
    &:focus {
      outline: none !important;
    }
    overflow: hidden;
    & video {
      width: 103% !important;
      margin: -1px 0 -3px -2px;
    }
  }
  .video-timg:hover {
    cursor: pointer;
    &::before {
      opacity: 0.25;
      cursor: pointer;
    }
    path {
      transform: scale(1.3);
      transform-origin: 40px 40px;
    }
  }
  .video-timg:active {
    &::before {
      opacity: 0.5;
    }
    circle {
      fill-opacity: 1;
      fill: #fff;
    }
  }
}
.video-content {
  position: relative;
  border-radius: 8px;
  width: 960px;
  // height: 539px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  @include respond-below(desktop_standard) {
    width: 760px;
  }

  @include respond-below(tablet) {
    width: 100%;
  }
}

.video-cont {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-cont iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#videoUtube {
  border-radius: 8px;
  @include respond-below(tablet) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 99.8%;
    height: 100%;
    z-index: 1;
  }
}
.play-btn {
  width: 80px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
}
.play-btn:not(:focus) {
  outline: none;
}
.is-played {
  @include respond-below(tablet) {
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
  }
}

// Testimonial modal
.testimonial-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(25, 31, 41, 0.95);
  align-items: center;
  justify-content: center;
  z-index: 10;

  .btn-primary {
    min-width: 242px;
  }
}

#closeBtn {
  font-size: 16px;
  line-height: 1.5;
  color: #f5f8fa;
  position: absolute;
  top: 32px;
  right: 32px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    vertical-align: middle;
  }

  @include respond-below(tablet) {
    top: 30px;
    right: 14px;
  }
}
.contact-section {
  display: flex;
  button {
    background-color: transparent;
    border: transparent;
  }
  a,
  button {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      width: auto;
      display: flex;
      cursor: pointer;
      span {
        line-height: 24px;
      }
    }
    &:nth-child(1) {
      padding: 0px 30px 0px 0px;
    }
    &:nth-child(2) {
      border-right: 1px solid #d9e1ee;
      border-left: 1px solid #d9e1ee;
      padding: 0px 30px 0px 30px;
    }
    &:nth-child(3) {
      padding: 0px 0px 0px 30px;
    }
  }
  @include respond-below(tablet) {
    margin-left: -10px;
    a,
    button {
      &:nth-child(1) {
        padding: 0 22px 0 0;
      }
      &:nth-child(2) {
        padding: 0 21px 0 10px;
      }
      &:nth-child(3) {
        padding: 0 0 0 7px;
      }
    }
  }
}

/* // Feature Hero Section */

.header-v2 .page-links-container > li i {
  width: 24px;
  display: inline-block;
  margin-right: 16px;
}

.img-fit {
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    width: 100% !important;
    height: auto !important;
  }
}

.featuresBookingPage .highlight picture {
  transform: scale(1);
}

/* // Text Reminders Custom Styles */
@media (min-width: 768px) {
  .text-rem .ml-custom {
    margin-left: 44px;
  }
  .text-rem .mt-custom {
    margin-top: 28px;
  }
  .text-rem .mx-wd-custom {
    max-width: 475px;
  }
  .text-rem .pb-custom {
    padding-bottom: 104px;
  }
}
@media (max-width: 768px) {
  .ml-neg-custom {
    margin-left: -20px;
  }
}
@media (min-width: 1200px) {
  .featuresBookingPage .use-setmore-wrap article {
    margin-left: 100px;
  }
}

/* // Email Reminders Custom Styles */
@media (min-width: 768px) {
  .text-rem .ml-custom {
    margin-left: 44px;
  }
  .text-rem .mt-custom {
    margin-top: 28px;
  }
  .text-rem .mx-wd-custom {
    max-width: 475px;
  }
  .text-rem .pb-custom {
    padding-bottom: 104px;
  }
}
@media (max-width: 768px) {
  .ml-neg-custom {
    margin-left: -20px;
  }
}
.email-rem .ul-white {
  color: #fff;
  text-decoration: underline;
  font-weight: 700;
}
@media only screen and (min-width: 768px) {
  .email-rem .hero--default .fg-layer {
    left: 150px;
  }
}
@media only screen and (max-width: 400px) {
  .email-rem .hero--default .fg-layer {
    bottom: -76px;
  }
  .email-rem .bg-custom-width {
    width: 90% !important;
  }
  .custom-mt {
    margin-top: -34px;
  }
}
@media (min-width: 1200px) {
  .featuresBookingPage .use-setmore-wrap article {
    margin-left: 100px;
  }
}

.d-block {
  display: block;
}
.dblock {
  display: block !important;
}
@media (min-width: 992px) {
  .cta-contrast.has-image h2 {
    font-size: 38px;
    line-height: 48px;
  }
}
@media (min-width: 992px) {
  .cta-contrast.has-image h2 {
    font-size: 38px;
    line-height: 48px;
  }

  .mission-cta .cta-contrast.has-image {
    padding-left: 80px;
  }

  .mission-cta .cta-contrast.has-image.has-img-offset picture {
    right: 62px;
    top: -56px;
  }
}
.revel-box * {
  box-shadow: 0 0 0 1px green;
}

.arrow-right {
  font-size: 20px !important;
  line-height: 38px !important;
  + a {
    vertical-align: text-bottom;
  }
}
.fixed-fx {
  flex-direction: row-reverse;
  justify-content: flex-start;
}
@media (min-width: 768px) {
  .fixed-fx article {
    margin-right: 100px;
  }
}

// Custom elements
.globe {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  background-color: $pale-blue;
}
.wall {
  &::before {
    content: "|";
    margin: 0 7px;
    @include respond-below(tablet_max) {
      display: none;
    }
  }
  &::after {
    content: "|";
    margin: 0 7px;
  }
}
.arrow {
  display: inline-block;
  margin: 5px;
  transform-origin: center;
  animation: back-and-forth 1.5s ease infinite;
}

.swiper-slide-custom {
  @include respond-above(tablet) {
    width: 560px !important;
  }
  @include respond-above(desktop_md) {
    width: 35% !important;
    min-height: 300px;
  }
  @include respond-above(desktop_max) {
    width: 30% !important;
    min-height: 380px;
  }
  padding: 40px;
  border-radius: 8px;
  background-color: #ffffff;
  opacity: 0.5;
  box-shadow: 0px 1px 2px 0px #303a4733;
  box-shadow: 0px 1px 3px 0px #303a471a;
  .quot-glyph {
    font-size: 24px;
    line-height: 36px;
    color: #303a47;
  }

  @include respond-below(mobile) {
    width: 70% !important;
    padding: 20px;
    height: 340px !important;
    margin-right: 20px !important;
    .quot-glyph {
      font-size: 18px;
      line-height: 28px;
    }
    img {
      width: auto;
      height: 24px;
    }
  }
  @include respond-below(mobile_md) {
    height: 390px !important;
  }
  @include respond-below(mobile_sm) {
    height: 430px !important;
  }
}

@include respond-below(tablet) {
  .company-logo img {
    width: 120px !important;
    height: 20px !important;
  }
}
