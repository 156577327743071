//pricing page styles
.pricing-layout {
  .cta-contrast.has-image h2 {
    @extend .text-lg;
  }
  .pricing-wrap {
    .card--default {
      .ticked-bulletin {
        &.list-item {
          margin: 0px;
          li {
            margin-bottom: 0px;
          }
        }
      }
      .p-block {
        @extend .fx;
        @extend .br-4;
        box-shadow: 0 1px 2px 0 rgba(51, 57, 61, 0.2),
          0 1px 4px 0 rgba(51, 57, 61, 0.1);
        padding: 20px;
        width: 100%;
        z-index: 1;
        > * {
          @extend .text-md;
          color: $black;
        }
        li {
          @extend .w-auto;
          @extend .fw-700;
          margin-left: 80px;
        }
      }
      .list-item {
        @extend .fx;
        @extend .boxshadow-none;
        width: 100%;
        margin-bottom: 0px;
        padding: 14px 19px 16px 20px;
        > * {
          @extend .text-xs;
          font-size: 16px;
        }
      }
      &.pr-card {
        > * {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            @extend .br-8;
            padding: 24px 48px 44px 48px;
            @include respond-below(tablet) {
              padding: 24px 40px 55px 44px;
            }
            &:hover {
              box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
                0 1px 4px 0 rgba(22, 45, 61, 0.1);
            }
          }
          &:nth-child(1) {
            @include respond-below(tablet) {
              .plan-benefits {
                margin-top: 16px;
              }
            }
          }
          &:nth-child(2) {
            .plan-duration-wrap {
              margin-left: 40px;
              transition: 0.3s all ease-out;
            }
            .counter + .plan-duration-wrap {
              margin-left: 20px;
              transition: 0.3s all ease-in;
            }
          }
          &:nth-child(3) {
            .plan-duration-wrap {
              margin-left: 22px;
            }
          }
        }
        &.pr-card-toggle {
          .plan-price {
            overflow: hidden;
            position: relative;
            .highlight {
              span:nth-child(2) {
                visibility: hidden;
              }
              span:nth-child(3) {
                visibility: hidden;
              }
              span {
                visibility: visible;
                font-size: 38px;
                line-height: 48px;
                transition: 0.3s all ease-in;
                position: absolute;
                &:last-child {
                  margin-top: 40px;
                }
              }
            }
            .counter {
              span {
                transition: 0.3s all ease-in;
                &:first-child {
                  margin-top: -40px;
                }
                &:last-child {
                  margin-top: 0px;
                }
              }
            }
          }
          .plan-price > .counter {
            span:nth-child(1) {
              visibility: hidden;
            }
            span:nth-child(3) {
              visibility: visible;
            }
          }
        }
        a.btn-primary,
        a.btn-secondary {
          font-size: 16px;
          padding: 11px 7px;
          @include respond-below(tablet_max) {
            background-color: $green-800;
            color: #fff;
          }
        }
      }

      .pricing-toggle-cta {
        &.pr-card {
          height: 65px;
          > li {
            margin-right: 0px;
            margin-left: 80px;
            width: 125px;
            &:nth-child(3) {
              .plan-duration-wrap {
                margin-left: 20px;
                transition: 0.3s all ease-out;
              }
              .counter + .plan-duration-wrap {
                margin-left: 10px;
                transition: 0.3s all ease-in;
              }
            }
            &:nth-child(4) {
              .plan-duration-wrap {
                margin-left: 12px;
              }
            }
            .plan-price {
              overflow: hidden;
              position: relative;
              text-align: left;
              .highlight {
                font-size: 16px;
                line-height: 24px;
                color: $black;
                span {
                  transition: 0.3s all ease-in;
                  position: absolute;
                  line-height: 25px;
                  visibility: visible;
                  &:last-child {
                    margin-top: 40px;
                    visibility: hidden;
                  }
                }
              }
              .counter {
                span {
                  transition: 0.3s all ease-in;
                  line-height: 25px;
                  &:first-child {
                    margin-top: -40px;
                    visibility: hidden;
                  }
                  &:last-child {
                    margin-top: 0px;
                    visibility: visible;
                  }
                }
              }
              strong,
              p,
              span {
                font-size: 16px;
                line-height: 24px;
                color: $black;
              }
            }
          }
        }
      }
    }
    @include respond-below(tablet) {
      .highlight {
        font-size: 38px;
      }
    }
  }
  .pr-features {
    max-width: 960px;
    .header {
      text-align: left;
      width: 320px;
    }
    .none {
      opacity: 0;
    }
    li {
      text-align: right;
      width: 160px;
      margin-right: 40px;
    }
    .pr-card-toggle {
      .plan-duration-wrap {
        @extend .text-xs;
        font-size: 16px;
      }
    }
  }
  .mobile-view {
    display: none;
    @include respond-below(tablet) {
      background-color: $black-200;
      .card--default {
        scroll-behavior: auto;
        overflow: auto;
        margin-left: 20px;
        padding-bottom: 10px;
        &:hover {
          .p-block {
            box-shadow: 0 1px 2px 0 rgba(51, 57, 61, 0.2),
              0 1px 4px 0 rgba(51, 57, 61, 0.1);
          }
        }
        .btn-primary,
        .btn-secondary {
          min-width: auto;
          padding: 6px 0px;
          span {
            width: 85px !important;
          }
        }
        a.btn-primary,
        a.btn-secondary {
          font-size: 12px;
          line-height: 19.4px;
          width: 95px;
          border: 1px solid $color-brand;
        }
        .header {
          font-size: 16px !important;
          line-height: 24px !important;
          margin-right: 16px;
          color: $black-600;
        }
        .sub-header {
          font-size: 12px;
          margin-bottom: 15px;
          line-height: 16px;
          color: $black-600;
        }
        .p-block {
          width: 160px;
          position: absolute;
          @include z-index(1);
          padding: 16px;
          box-shadow: none;
        }
        .list-item {
          background-color: $black-200;
          width: 90%;
          border-radius: 0px;
          left: 175px;
          padding: 0px;
          li,
          p,
          span {
            color: $black;
            text-align: center;
            font-size: 12px;
            line-height: 16px;
            width: 100px;
          }
          li,
          p {
            margin-right: 16px;
            margin-bottom: 12px !important;
          }
          li {
            &:before {
              margin-right: 0px;
            }
          }
        }
        .pricing-toggle-mobile-cta {
          &.pr-card {
            > li {
              width: 100px;
              &:nth-child(2) {
                .plan-duration-wrap {
                  margin-left: 12px;
                  transition: 0.3s all ease-out;
                }
                .counter + .plan-duration-wrap {
                  margin-left: 10px;
                  transition: 0.3s all ease-in;
                }
              }
              &:nth-child(3) {
                .plan-duration-wrap {
                  margin-left: 8px;
                }
              }
              .plan-price {
                overflow: hidden;
                position: relative;
                width: 87px;
                .highlight {
                  span {
                    transition: 0.3s all ease-in;
                    position: absolute;
                    width: 10px;
                    &:last-child {
                      margin-top: 40px;
                    }
                  }
                }
                .counter {
                  span {
                    transition: 0.3s all ease-in;
                    &:first-child {
                      margin-top: -40px;
                    }
                    &:last-child {
                      margin-top: 0px;
                    }
                  }
                }
                strong,
                p,
                span {
                  font-size: 12px;
                  line-height: 16px;
                  color: $black;
                }
              }
            }
          }
        }
      }
    }
    #swipeimage {
      position: absolute;
      @include z-index(11);
      left: 90px;
      margin-top: 227px;
    }
  }
}
//Premium -pro page
.premium-pro {
  overflow: hidden;
  .pricing-wrap {
    .card--default {
      &.pr-card-header {
        .abs-img-custom-one {
          #monthly-dis-img-cta {
            left: 73px;
            top: 90px;
            @include respond-below(tablet) {
              left: 73px;
              top: 90px;
            }
          }
        }
        .abs-img-custom-two {
          #monthly-dis-img-cta,
          #annual-dis-img-cta {
            left: 65px;
            left: 70px;
            top: 90px;
            @include respond-below(tablet) {
              left: 75px;
              top: 94px;
            }
          }
        }
        > * {
          &:nth-child(1) {
            background-color: $white-900 !important;
            border: solid 1.5px $blue-500;
            @include respond-below(tablet) {
              margin-bottom: 20px;
              border-color: $white-900;
            }
            a.btn-primary,
            a.btn-secondary {
              background-color: $green-800;
              color: $white-900;
            }
            .plan-cta {
              a {
                height: 32px;
                line-height: 32px;
                padding: 0;
              }
              @include respond-below(tablet) {
                width: 100%;
                left: 40px;
              }
            }

            .plan-duration-wrap {
              margin-left: 35px;
              transition: 0.3s all ease-out;
            }

            .counter + .plan-duration-wrap {
              margin-left: 20px;
              transition: 0.3s all ease-in;
            }

            @include respond-above(tablet) {
              @include z-index(1);
            }
          }

          &:nth-child(2) {
            border: solid 1.5px $white-900;
            .plan-cta {
              a {
                height: 32px;
                line-height: 32px;
                padding: 0;
              }
              @include respond-below(tablet) {
                min-height: 40px;
                width: 100%;
                position: unset;
              }
            }
            .plan-duration-wrap {
              margin-left: 20px;
              transition: 0.3s all ease-out;
            }
            .counter + .plan-duration-wrap {
              margin-left: 22px;
              transition: 0.3s all ease-in;
            }
          }
        }

        @include respond-above(tablet) {
          margin-left: -75px;
        }
      }
    }
  }
  .pricing-wrap-mobile {
    @include respond-below(tablet_max) {
      top: 360px;
      position: relative;
      padding-bottom: 305px;
      @include z-index(1);
    }
    @include respond-below(mobile) {
      top: 0;
      padding-bottom: 48px;
    }
  }

  .app-review-wrap {
    figure {
      width: 64px;
    }

    span {
      color: $color-olive;
      @extend .text-xxs;
      @extend .fw-600;
    }

    .getapp-review {
      display: block;
    }
    @include respond-below(tablet_max) {
      position: absolute;
      top: unset;
      bottom: 35px;
      margin: auto;
      width: 100%;
    }
  }
  .layered-images-wrap {
    z-index: -11;
    bottom: 0;
    right: 0;
    position: absolute;
    @include respond-above(desktop_sm) {
      @include z-index(minus-1);
      img {
        width: 145%;
        height: 100%;
      }
    }
    @include respond-below(desktop) {
      img {
        display: none;
      }
    }
    @include respond-below(tablet_max) {
      left: 0;
      top: 260px;
      img {
        display: block;
      }
    }
    @include respond-between(mobile, tablet_max) {
      img {
        width: auto;
      }
    }
    @include respond-below(mobile) {
      position: static;
      display: block;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include respond-below(tablet) {
    .cta-contrast.has-image picture {
      display: block;
      margin-left: -20px;
    }

    .window-layout {
      .has-align-top {
        padding-bottom: 230px;
        @include respond-below(mobile) {
          padding-bottom: 0;
          display: flex;
          flex-direction: column-reverse;
        }

        picture {
          position: absolute;
          margin-top: 260px;
          @include respond-below(mobile) {
            margin-top: 0;
            position: static;
          }
        }
      }
    }
  }
}

// New Free Plan Page
.free-plan {
  .m-top-mob {
    @include respond-below(mobile) {
      margin-top: 390px;
    }
  }
  .app-review-wrap {
    .logo-wrapper {
      width: auto;

      .logo-adaptive {
        width: 48px;
        height: 48px;
      }
    }
  }
  .card-list-adaptive {
    border-color: $white-900 !important;
    .btn-primary {
      padding: 11px 7px !important;
      line-height: 24px !important;
      height: unset !important;
    }
  }

  #bg-color-override,
  #bg-coloroverride {
    background-color: #fff !important;
  }

  @include respond-above(desktop_sm) {
    .layered-images-wrap {
      left: calc(100% - 703px);
      position: absolute;
      bottom: 0px;
    }
  }

  @include respond-below(tablet_max) {
    .hero--default {
      .layered-images-wrap {
        display: none;
      }

      .app-review-wrap {
        top: 0;
      }

      @include respond-below(mobile) {
        .layered-images-wrap {
          display: block;
        }
      }

      @include respond-below(mobile) {
        .width-adaptive {
          .card-width-adaptive {
            margin-left: auto !important;
          }
          top: 360px;
        }
      }
    }
  }

  @include respond-below(tablet_max) {
    .hero--default {
      .app-review-wrap {
        margin-left: 0;
      }
    }
  }

  @include respond-below(mobile) {
    .hero--default {
      .app-review-wrap {
        top: 1100px;
      }
    }
  }

  @include respond-below(mobile) {
    .dp-none {
      display: none;
    }

    .dp-block {
      display: block;
    }
  }

  @include respond-above(mobile) {
    .dp-none {
      display: block;
    }

    .dp-block {
      display: none;
    }
  }

  @include respond-above(tablet) {
    .grid--default {
      picture {
        max-width: 472px;
      }
      .custom-img-size {
        max-width: 560px;
      }
    }
  }

  @include respond-below(mobile) {
    .grid--default {
      .custom-img-size {
        img {
          height: 280px;
        }
      }
    }
    .window-layout {
      .grid--default {
        .custom-img-size {
          margin-top: 260px;
        }
      }
    }
  }

  .custom-height {
    @include respond-above(desktop) {
      max-height: 452px;
    }

    .adaptive-image {
      @include respond-above(tablet_max) {
        bottom: -19%;
      }
    }

    @include respond-below(mobile) {
      .dp-fx {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn-secondary {
          min-width: 140px;
          padding: 11px 24px;
        }
      }
    }
  }

  .custom-pricing-wrap {
    .custom-list-item {
      padding-bottom: 104px;

      .custom-margin-bottom {
        margin-bottom: 28px;

        @include respond-below(mobile) {
          li {
            margin-bottom: 6;
          }
        }
      }
    }

    .m-top {
      margin-top: 20px;

      @include respond-below(mobile) {
        margin-top: auto;
      }
    }
  }

  .pricing-wrap-mobile {
    @include respond-below(tablet_max) {
      top: 0;
      padding-bottom: 0;
    }
  }
}

.free-plan {
  .layered-images-wrap {
    @include respond-between(desktop_standard, desktop_md) {
      left: calc(100% - 50%);
    }
    @include respond-above(desktop_md) {
      right: 0;
      left: auto;
    }
  }
}

//inline styles in free-plan
@media (max-width: 575px) {
  .free-plan .m-top-mob {
    margin-top: 390px;
  }
}
.free-plan .app-review-wrap .logo-wrapper {
  width: auto;
}
.free-plan .app-review-wrap .logo-wrapper .logo-adaptive {
  width: 48px;
  height: 48px;
}
@media (max-width: 991px) {
  .free-plan .app-review-wrap {
    width: 100%;
    position: absolute;
    margin: 0 auto;
    bottom: -300px;
  }
}
@media (min-width: 768px) {
  .free-plan .width-adaptive .card-width-adaptive .card-list-adaptive li {
    // margin-bottom: 6px;
  }
}
.free-plan #bg-color-override,
.free-plan #bg-coloroverride {
  background-color: #fff !important;
}
@media (min-width: 1024px) {
  .free-plan .layered-images-wrap {
    right: 20px;
    bottom: 0;
    position: absolute;
  }
}
@media (min-width: 1400px) and (max-width: 1499px) {
  .free-plan .layered-images-wrap {
    right: 0;
  }
}
@media (max-width: 991px) {
  .free-plan .hero--default .layered-images-wrap {
    display: none;
  }
}
@media (max-width: 991px) and (max-width: 575px) {
  .free-plan .hero--default .layered-images-wrap {
    display: block;
  }
}
@media (max-width: 991px) and (max-width: 575px) {
  .free-plan .hero--default .width-adaptive {
    top: 360px;
  }
  .free-plan .hero--default .width-adaptive .card-width-adaptive {
    margin-left: auto !important;
  }
}
@media (max-width: 575px) {
  .free-plan .dp-none {
    display: none;
  }
  .free-plan .dp-block {
    display: block;
  }
}
@media (min-width: 576px) {
  .free-plan .dp-none {
    display: block;
  }
  .free-plan .dp-block {
    display: none;
  }
}
@media (min-width: 768px) {
  .free-plan .grid--default picture {
    max-width: 472px;
  }
  .free-plan .grid--default .custom-img-size {
    max-width: 560px;
  }
}
@media (min-width: 1200px) {
  .free-plan .custom-height {
    max-height: 452px;
  }
}
@media (min-width: 992px) {
  .free-plan .custom-height .adaptive-image {
    bottom: -19%;
  }
}
@media (max-width: 575px) {
  .free-plan .custom-height .dp-fx {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .free-plan .custom-height .dp-fx .btn-secondary {
    min-width: 140px;
    padding: 11px 24px;
  }
}
.free-plan .custom-pricing-wrap .custom-list-item {
  padding-bottom: 104px;
}
.free-plan .custom-pricing-wrap .custom-list-item .custom-margin-bottom {
  margin-bottom: 28px;
}
@media (max-width: 575px) {
  .free-plan .custom-pricing-wrap .custom-list-item .custom-margin-bottom li {
    margin-bottom: 6;
  }
}
.free-plan .custom-pricing-wrap .m-top {
  margin-top: 20px;
}
@media (max-width: 575px) {
  .free-plan .custom-pricing-wrap .m-top {
    margin-top: auto;
  }
}
@media (max-width: 991px) {
  .free-plan .pricing-wrap-mobile {
    top: 0;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 992px) {
  .free-plan .main-header .main-nav-lg .hover-link,
  .free-plan
    .main-header
    .main-nav-lg
    footer.is-default
    .dark-footer-wrapper
    nav
    a,
  footer.is-default
    .dark-footer-wrapper
    nav
    .free-plan
    .main-header
    .main-nav-lg
    a {
    margin-left: 16px !important;
  }
  .free-plan .main-header .main-contacts {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
  .free-plan .pricing-wrap .card--default.pr-card-header {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 768px) and (min-width: 500px) {
  .free-plan .width-adaptive .card-width-adaptive {
    margin-left: 0 !important;
  }
  .free-plan h1 {
    font-size: 44px;
  }
  .free-plan .hero--default .app-review-wrap {
    top: 58% !important;
  }
  .free-plan .full-width article {
    max-width: 100% !important;
  }
  .free-plan .window-layout .grid--default h2 {
    margin-top: 0 !important;
  }
  .free-plan .industry-with-video .use-setmore-wrap .container {
    margin-top: -75px;
  }
}
@media only screen and (max-width: 340px) {
  .free-plan .full-width .adaptive-image img,
  .free-plan .layered-images-wrap img {
    max-width: 100% !important;
  }
  .free-plan .fx-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .free-plan .fx-wrap a {
    margin-top: 20px !important;
  }
  .free-plan .hero--default .app-review-wrap {
    top: 1020px !important;
  }
  .free-plan .window-layout .grid--default .custom-img-size {
    margin-top: 300px !important;
  }
  .free-plan .hero--default .width-adaptive {
    top: 250px !important;
  }
}
