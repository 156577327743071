// Pricing section
.pricing-wrap {
  .card--default {
    > * {
      position: relative;
      @extend .br-8;
      // @extend %card-shadow;
      @extend .bg-white;
      padding: 20px 32px 104px;
      @include respond-below(tablet) {
        padding: 20px 40px 104px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:hover {
        box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
          0 1px 4px 0 rgba(22, 45, 61, 0.1);
      }
    }
    * {
      font-size: 14px;
      line-height: 24px;
    }
    .plan-cta {
      // min-height: 88px;
      margin-top: 24px;
      @include respond-below(tablet) {
        // min-height: 40px;
        width: 100%;
        position: unset;
      }
    }
    .highlight {
      @extend .ff-euclid;
      font-size: 38px;
      line-height: 48px;
      color: $black-700;
    }
    .plan-user {
      margin-bottom: 5px;
    }
    .ticked-bulletin {
      margin-top: 20px;
      margin-bottom: 24px;
      li {
        // margin-bottom: 6px;
        &::before {
          margin-right: 8px;
        }
      }
    }
  }
  @include respond-below(tablet) {
    h2 {
      font-size: 32px;
      line-height: 40px;
    }
  }
}
