.intl-dropdown .flag-ele {
  display: inline-block;
  min-width: 20px;
  height: 15px;
  border-radius: 0;
  background-image: url(https://assets.setmore.com/front-office/images/phone-tab/flags.png);
  background-repeat: no-repeat;
  background-color: #dbdbdb;
  background-position: 20px 0;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2 / 1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .intl-dropdown .flag-ele {
    background-image: url(https://assets.setmore.com/front-office/images/common/flags@2x.png);
    background-size: 5652px 15px;
  }
}

.intl {
  &-field-group {
    margin-bottom: -4px;
  }
  &-dropdown-wrap {
    width: 100%;
    border-bottom: 1px solid #d9e1ee;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    position: relative;
    margin: 40px 0 8px;

    input {
      outline: 0;
    }
  }
  &-phone-input {
    border: 0;
    width: 160px;
    margin-left: 16px;
    font-size: 16px;
    color: #3b4c66;
    &::placeholder {
      color: #8495b1;
    }
  }
  &-dropdown {
    &.open {
      .intl-menu {
        display: block;
      }
      .intl-select-arrow {
        transform: rotate(224deg);
        top: 3px;
      }
    }
  }
  &-select {
    cursor: pointer;
    code {
      margin: 0 6px;
      font-family: inherit;
    }
    .intl-select-arrow {
      position: relative;
      top: -1px;
      width: 8px;
      height: 8px;
      display: inline-block;
      cursor: pointer;
      border: 1px solid #3b4c66;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
      transition: 0.2s transform linear;
    }
  }
  &-country-set {
    > * {
      vertical-align: middle;
    }
  }
  &-menu {
    display: none;
    position: absolute;
    top: 30px;
    left: 0;
    max-width: 256px;
    max-height: 270px;
    overflow: auto;
    background-color: #fff;
    z-index: 9;
    box-shadow: 0 3px 4px 0 rgb(10 31 68 / 10%), 0 0 1px 0 rgb(10 31 68 / 8%);
    cursor: pointer;

    .intl-radio {
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      border-radius: 50%;
      box-shadow: 0 0 0 1px #8495b1;
      border: 1px solid transparent;
      background-color: white;
      transform: none !important;
      padding: 0;
    }

    ul {
      li {
        padding: 12px;
        display: flex;
        align-items: center;
        &:hover {
          background-color: #f3f6fc;
        }
        &.selected {
          > * {
            color: #6285ff;
          }
          .intl-radio {
            box-shadow: 0 0 0 1px #6285ff;
            border-width: 4px;
            border-color: #fff;
            background-color: #6285ff;
          }
        }
        .flag-ele {
          margin: 0 8px 0 12px;
        }
        .intl-country-name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 75%;
          line-height: 1.2;
        }
      }
    }
  }
  &-searchbox {
    position: sticky;
    top: 0px;
    width: 100%;
    background-color: #fff;
    padding: 12px 12px 12px;
    svg {
      width: 22px;
      height: 22px;
      fill: #8495b1;
      position: absolute;
      left: 24px;
      top: 20px;
      pointer-events: none;
    }
    input {
      width: 100%;
      border: 1px solid #e6e9f0;
      padding: 6px 12px 6px 44px;
      border-radius: 40px;
      font-size: 14px;
      line-height: 24px;
      color: #3b4c66;

      &::placeholder {
        color: #8495b1;
      }
    }
  }
}
.be.flag-ele {
  width: 18px;
}
.ch.flag-ele {
  width: 15px;
}
.mc.flag-ele {
  width: 19px;
}
.ne.flag-ele {
  width: 18px;
}
.np.flag-ele {
  width: 13px;
}
.va.flag-ele {
  width: 15px;
}
.flag-ele.ac {
  height: 10px;
  background-position: 0 0;
}
.flag-ele.ad {
  height: 14px;
  background-position: -22px 0;
}
.flag-ele.ae {
  height: 10px;
  background-position: -44px 0;
}
.flag-ele.af {
  height: 14px;
  background-position: -66px 0;
}
.flag-ele.ag {
  height: 14px;
  background-position: -88px 0;
}
.flag-ele.ai {
  height: 10px;
  background-position: -110px 0;
}
.flag-ele.al {
  height: 15px;
  background-position: -132px 0;
}
.flag-ele.am {
  height: 10px;
  background-position: -154px 0;
}
.flag-ele.ao {
  height: 14px;
  background-position: -176px 0;
}
.flag-ele.aq {
  height: 14px;
  background-position: -198px 0;
}
.flag-ele.ar {
  height: 13px;
  background-position: -220px 0;
}
.flag-ele.as {
  height: 10px;
  background-position: -242px 0;
}
.flag-ele.at {
  height: 14px;
  background-position: -264px 0;
}
.flag-ele.au {
  height: 10px;
  background-position: -286px 0;
}
.flag-ele.aw {
  height: 14px;
  background-position: -308px 0;
}
.flag-ele.ax {
  height: 13px;
  background-position: -330px 0;
}
.flag-ele.az {
  height: 10px;
  background-position: -352px 0;
}
.flag-ele.ba {
  height: 10px;
  background-position: -374px 0;
}
.flag-ele.bb {
  height: 14px;
  background-position: -396px 0;
}
.flag-ele.bd {
  height: 12px;
  background-position: -418px 0;
}
.flag-ele.be {
  height: 15px;
  background-position: -440px 0;
}
.flag-ele.bf {
  height: 14px;
  background-position: -460px 0;
}
.flag-ele.bg {
  height: 12px;
  background-position: -482px 0;
}
.flag-ele.bh {
  height: 12px;
  background-position: -504px 0;
}
.flag-ele.bi {
  height: 12px;
  background-position: -526px 0;
}
.flag-ele.bj {
  height: 14px;
  background-position: -548px 0;
}
.flag-ele.bl {
  height: 14px;
  background-position: -570px 0;
}
.flag-ele.bm {
  height: 10px;
  background-position: -592px 0;
}
.flag-ele.bn {
  height: 10px;
  background-position: -614px 0;
}
.flag-ele.bo {
  height: 14px;
  background-position: -636px 0;
}
.flag-ele.bq {
  height: 14px;
  background-position: -658px 0;
}
.flag-ele.br {
  height: 14px;
  background-position: -680px 0;
}
.flag-ele.bs {
  height: 10px;
  background-position: -702px 0;
}
.flag-ele.bt {
  height: 14px;
  background-position: -724px 0;
}
.flag-ele.bv {
  height: 15px;
  background-position: -746px 0;
}
.flag-ele.bw {
  height: 14px;
  background-position: -768px 0;
}
.flag-ele.by {
  height: 10px;
  background-position: -790px 0;
}
.flag-ele.bz {
  height: 14px;
  background-position: -812px 0;
}
.flag-ele.ca {
  height: 10px;
  background-position: -834px 0;
}
.flag-ele.cc {
  height: 10px;
  background-position: -856px 0;
}
.flag-ele.cd {
  height: 15px;
  background-position: -878px 0;
}
.flag-ele.cf {
  height: 14px;
  background-position: -900px 0;
}
.flag-ele.cg {
  height: 14px;
  background-position: -922px 0;
}
.flag-ele.ch {
  height: 15px;
  background-position: -944px 0;
}
.flag-ele.ci {
  height: 14px;
  background-position: -961px 0;
}
.flag-ele.ck {
  height: 10px;
  background-position: -983px 0;
}
.flag-ele.cl {
  height: 14px;
  background-position: -1005px 0;
}
.flag-ele.cm {
  height: 14px;
  background-position: -1027px 0;
}
.flag-ele.cn {
  height: 14px;
  background-position: -1049px 0;
}
.flag-ele.co {
  height: 14px;
  background-position: -1071px 0;
}
.flag-ele.cp {
  height: 14px;
  background-position: -1093px 0;
}
.flag-ele.cr {
  height: 12px;
  background-position: -1115px 0;
}
.flag-ele.cu {
  height: 10px;
  background-position: -1137px 0;
}
.flag-ele.cv {
  height: 12px;
  background-position: -1159px 0;
}
.flag-ele.cw {
  height: 14px;
  background-position: -1181px 0;
}
.flag-ele.cx {
  height: 10px;
  background-position: -1203px 0;
}
.flag-ele.cy {
  height: 14px;
  background-position: -1225px 0;
}
.flag-ele.cz {
  height: 14px;
  background-position: -1247px 0;
}
.flag-ele.de {
  height: 12px;
  background-position: -1269px 0;
}
.flag-ele.dg {
  height: 10px;
  background-position: -1291px 0;
}
.flag-ele.dj {
  height: 14px;
  background-position: -1313px 0;
}
.flag-ele.dk {
  height: 15px;
  background-position: -1335px 0;
}
.flag-ele.dm {
  height: 10px;
  background-position: -1357px 0;
}
.flag-ele.do {
  height: 14px;
  background-position: -1379px 0;
}
.flag-ele.dz {
  height: 14px;
  background-position: -1401px 0;
}
.flag-ele.ea {
  height: 14px;
  background-position: -1423px 0;
}
.flag-ele.ec {
  height: 14px;
  background-position: -1445px 0;
}
.flag-ele.ee {
  height: 13px;
  background-position: -1467px 0;
}
.flag-ele.eg {
  height: 14px;
  background-position: -1489px 0;
}
.flag-ele.eh {
  height: 10px;
  background-position: -1511px 0;
}
.flag-ele.er {
  height: 10px;
  background-position: -1533px 0;
}
.flag-ele.es {
  height: 14px;
  background-position: -1555px 0;
}
.flag-ele.et {
  height: 10px;
  background-position: -1577px 0;
}
.flag-ele.eu {
  height: 14px;
  background-position: -1599px 0;
}
.flag-ele.fi {
  height: 12px;
  background-position: -1621px 0;
}
.flag-ele.fj {
  height: 10px;
  background-position: -1643px 0;
}
.flag-ele.fk {
  height: 10px;
  background-position: -1665px 0;
}
.flag-ele.fm {
  height: 11px;
  background-position: -1687px 0;
}
.flag-ele.fo {
  height: 15px;
  background-position: -1709px 0;
}
.flag-ele.fr {
  height: 14px;
  background-position: -1731px 0;
}
.flag-ele.ga {
  height: 15px;
  background-position: -1753px 0;
}
.flag-ele.gb {
  height: 10px;
  background-position: -1775px 0;
}
.flag-ele.gd {
  height: 12px;
  background-position: -1797px 0;
}
.flag-ele.ge {
  height: 14px;
  background-position: -1819px 0;
}
.flag-ele.gf {
  height: 14px;
  background-position: -1841px 0;
}
.flag-ele.gg {
  height: 14px;
  background-position: -1863px 0;
}
.flag-ele.gh {
  height: 14px;
  background-position: -1885px 0;
}
.flag-ele.gi {
  height: 10px;
  background-position: -1907px 0;
}
.flag-ele.gl {
  height: 14px;
  background-position: -1929px 0;
}
.flag-ele.gm {
  height: 14px;
  background-position: -1951px 0;
}
.flag-ele.gn {
  height: 14px;
  background-position: -1973px 0;
}
.flag-ele.gp {
  height: 14px;
  background-position: -1995px 0;
}
.flag-ele.gq {
  height: 14px;
  background-position: -2017px 0;
}
.flag-ele.gr {
  height: 14px;
  background-position: -2039px 0;
}
.flag-ele.gs {
  height: 10px;
  background-position: -2061px 0;
}
.flag-ele.gt {
  height: 13px;
  background-position: -2083px 0;
}
.flag-ele.gu {
  height: 11px;
  background-position: -2105px 0;
}
.flag-ele.gw {
  height: 10px;
  background-position: -2127px 0;
}
.flag-ele.gy {
  height: 12px;
  background-position: -2149px 0;
}
.flag-ele.hk {
  height: 14px;
  background-position: -2171px 0;
}
.flag-ele.hm {
  height: 10px;
  background-position: -2193px 0;
}
.flag-ele.hn {
  height: 10px;
  background-position: -2215px 0;
}
.flag-ele.hr {
  height: 10px;
  background-position: -2237px 0;
}
.flag-ele.ht {
  height: 12px;
  background-position: -2259px 0;
}
.flag-ele.hu {
  height: 10px;
  background-position: -2281px 0;
}
.flag-ele.ic {
  height: 14px;
  background-position: -2303px 0;
}
.flag-ele.id {
  height: 14px;
  background-position: -2325px 0;
}
.flag-ele.ie {
  height: 10px;
  background-position: -2347px 0;
}
.flag-ele.il {
  height: 15px;
  background-position: -2369px 0;
}
.flag-ele.im {
  height: 10px;
  background-position: -2391px 0;
}
.flag-ele.in {
  height: 14px;
  background-position: -2413px 0;
}
.flag-ele.io {
  height: 10px;
  background-position: -2435px 0;
}
.flag-ele.iq {
  height: 14px;
  background-position: -2457px 0;
}
.flag-ele.ir {
  height: 12px;
  background-position: -2479px 0;
}
.flag-ele.is {
  height: 15px;
  background-position: -2501px 0;
}
.flag-ele.it {
  height: 14px;
  background-position: -2523px 0;
}
.flag-ele.je {
  height: 12px;
  background-position: -2545px 0;
}
.flag-ele.jm {
  height: 10px;
  background-position: -2567px 0;
}
.flag-ele.jo {
  height: 10px;
  background-position: -2589px 0;
}
.flag-ele.jp {
  height: 14px;
  background-position: -2611px 0;
}
.flag-ele.ke {
  height: 14px;
  background-position: -2633px 0;
}
.flag-ele.kg {
  height: 12px;
  background-position: -2655px 0;
}
.flag-ele.kh {
  height: 13px;
  background-position: -2677px 0;
}
.flag-ele.ki {
  height: 10px;
  background-position: -2699px 0;
}
.flag-ele.km {
  height: 12px;
  background-position: -2721px 0;
}
.flag-ele.kn {
  height: 14px;
  background-position: -2743px 0;
}
.flag-ele.kp {
  height: 10px;
  background-position: -2765px 0;
}
.flag-ele.kr {
  height: 14px;
  background-position: -2787px 0;
}
.flag-ele.kw {
  height: 10px;
  background-position: -2809px 0;
}
.flag-ele.ky {
  height: 10px;
  background-position: -2831px 0;
}
.flag-ele.kz {
  height: 10px;
  background-position: -2853px 0;
}
.flag-ele.la {
  height: 14px;
  background-position: -2875px 0;
}
.flag-ele.lb {
  height: 14px;
  background-position: -2897px 0;
}
.flag-ele.lc {
  height: 10px;
  background-position: -2919px 0;
}
.flag-ele.li {
  height: 12px;
  background-position: -2941px 0;
}
.flag-ele.lk {
  height: 10px;
  background-position: -2963px 0;
}
.flag-ele.lr {
  height: 11px;
  background-position: -2985px 0;
}
.flag-ele.ls {
  height: 14px;
  background-position: -3007px 0;
}
.flag-ele.lt {
  height: 12px;
  background-position: -3029px 0;
}
.flag-ele.lu {
  height: 12px;
  background-position: -3051px 0;
}
.flag-ele.lv {
  height: 10px;
  background-position: -3073px 0;
}
.flag-ele.ly {
  height: 10px;
  background-position: -3095px 0;
}
.flag-ele.ma {
  height: 14px;
  background-position: -3117px 0;
}
.flag-ele.mc {
  height: 15px;
  background-position: -3139px 0;
}
.flag-ele.md {
  height: 10px;
  background-position: -3160px 0;
}
.flag-ele.me {
  height: 10px;
  background-position: -3182px 0;
}
.flag-ele.mf {
  height: 14px;
  background-position: -3204px 0;
}
.flag-ele.mg {
  height: 14px;
  background-position: -3226px 0;
}
.flag-ele.mh {
  height: 11px;
  background-position: -3248px 0;
}
.flag-ele.mk {
  height: 10px;
  background-position: -3270px 0;
}
.flag-ele.ml {
  height: 14px;
  background-position: -3292px 0;
}
.flag-ele.mm {
  height: 14px;
  background-position: -3314px 0;
}
.flag-ele.mn {
  height: 10px;
  background-position: -3336px 0;
}
.flag-ele.mo {
  height: 14px;
  background-position: -3358px 0;
}
.flag-ele.mp {
  height: 10px;
  background-position: -3380px 0;
}
.flag-ele.mq {
  height: 14px;
  background-position: -3402px 0;
}
.flag-ele.mr {
  height: 14px;
  background-position: -3424px 0;
}
.flag-ele.ms {
  height: 10px;
  background-position: -3446px 0;
}
.flag-ele.mt {
  height: 14px;
  background-position: -3468px 0;
}
.flag-ele.mu {
  height: 14px;
  background-position: -3490px 0;
}
.flag-ele.mv {
  height: 14px;
  background-position: -3512px 0;
}
.flag-ele.mw {
  height: 14px;
  background-position: -3534px 0;
}
.flag-ele.mx {
  height: 12px;
  background-position: -3556px 0;
}
.flag-ele.my {
  height: 10px;
  background-position: -3578px 0;
}
.flag-ele.mz {
  height: 14px;
  background-position: -3600px 0;
}
.flag-ele.na {
  height: 14px;
  background-position: -3622px 0;
}
.flag-ele.nc {
  height: 10px;
  background-position: -3644px 0;
}
.flag-ele.ne {
  height: 15px;
  background-position: -3666px 0;
}
.flag-ele.nf {
  height: 10px;
  background-position: -3686px 0;
}
.flag-ele.ng {
  height: 10px;
  background-position: -3708px 0;
}
.flag-ele.ni {
  height: 12px;
  background-position: -3730px 0;
}
.flag-ele.nl {
  height: 14px;
  background-position: -3752px 0;
}
.flag-ele.no {
  height: 15px;
  background-position: -3774px 0;
}
.flag-ele.np {
  height: 15px;
  background-position: -3796px 0;
}
.flag-ele.nr {
  height: 10px;
  background-position: -3811px 0;
}
.flag-ele.nu {
  height: 10px;
  background-position: -3833px 0;
}
.flag-ele.nz {
  height: 10px;
  background-position: -3855px 0;
}
.flag-ele.om {
  height: 10px;
  background-position: -3877px 0;
}
.flag-ele.pa {
  height: 14px;
  background-position: -3899px 0;
}
.flag-ele.pe {
  height: 14px;
  background-position: -3921px 0;
}
.flag-ele.pf {
  height: 14px;
  background-position: -3943px 0;
}
.flag-ele.pg {
  height: 15px;
  background-position: -3965px 0;
}
.flag-ele.ph {
  height: 10px;
  background-position: -3987px 0;
}
.flag-ele.pk {
  height: 14px;
  background-position: -4009px 0;
}
.flag-ele.pl {
  height: 13px;
  background-position: -4031px 0;
}
.flag-ele.pm {
  height: 14px;
  background-position: -4053px 0;
}
.flag-ele.pn {
  height: 10px;
  background-position: -4075px 0;
}
.flag-ele.pr {
  height: 14px;
  background-position: -4097px 0;
}
.flag-ele.ps {
  height: 10px;
  background-position: -4119px 0;
}
.flag-ele.pt {
  height: 14px;
  background-position: -4141px 0;
}
.flag-ele.pw {
  height: 13px;
  background-position: -4163px 0;
}
.flag-ele.py {
  height: 11px;
  background-position: -4185px 0;
}
.flag-ele.qa {
  height: 8px;
  background-position: -4207px 0;
}
.flag-ele.re {
  height: 14px;
  background-position: -4229px 0;
}
.flag-ele.ro {
  height: 14px;
  background-position: -4251px 0;
}
.flag-ele.rs {
  height: 14px;
  background-position: -4273px 0;
}
.flag-ele.ru {
  height: 14px;
  background-position: -4295px 0;
}
.flag-ele.rw {
  height: 14px;
  background-position: -4317px 0;
}
.flag-ele.sa {
  height: 14px;
  background-position: -4339px 0;
}
.flag-ele.sb {
  height: 10px;
  background-position: -4361px 0;
}
.flag-ele.sc {
  height: 10px;
  background-position: -4383px 0;
}
.flag-ele.sd {
  height: 10px;
  background-position: -4405px 0;
}
.flag-ele.se {
  height: 13px;
  background-position: -4427px 0;
}
.flag-ele.sg {
  height: 14px;
  background-position: -4449px 0;
}
.flag-ele.sh {
  height: 10px;
  background-position: -4471px 0;
}
.flag-ele.si {
  height: 10px;
  background-position: -4493px 0;
}
.flag-ele.sj {
  height: 15px;
  background-position: -4515px 0;
}
.flag-ele.sk {
  height: 14px;
  background-position: -4537px 0;
}
.flag-ele.sl {
  height: 14px;
  background-position: -4559px 0;
}
.flag-ele.sm {
  height: 15px;
  background-position: -4581px 0;
}
.flag-ele.sn {
  height: 14px;
  background-position: -4603px 0;
}
.flag-ele.so {
  height: 14px;
  background-position: -4625px 0;
}
.flag-ele.sr {
  height: 14px;
  background-position: -4647px 0;
}
.flag-ele.ss {
  height: 10px;
  background-position: -4669px 0;
}
.flag-ele.st {
  height: 10px;
  background-position: -4691px 0;
}
.flag-ele.sv {
  height: 12px;
  background-position: -4713px 0;
}
.flag-ele.sx {
  height: 14px;
  background-position: -4735px 0;
}
.flag-ele.sy {
  height: 14px;
  background-position: -4757px 0;
}
.flag-ele.sz {
  height: 14px;
  background-position: -4779px 0;
}
.flag-ele.ta {
  height: 10px;
  background-position: -4801px 0;
}
.flag-ele.tc {
  height: 10px;
  background-position: -4823px 0;
}
.flag-ele.td {
  height: 14px;
  background-position: -4845px 0;
}
.flag-ele.tf {
  height: 14px;
  background-position: -4867px 0;
}
.flag-ele.tg {
  height: 13px;
  background-position: -4889px 0;
}
.flag-ele.th {
  height: 14px;
  background-position: -4911px 0;
}
.flag-ele.tj {
  height: 10px;
  background-position: -4933px 0;
}
.flag-ele.tk {
  height: 10px;
  background-position: -4955px 0;
}
.flag-ele.tl {
  height: 10px;
  background-position: -4977px 0;
}
.flag-ele.tm {
  height: 14px;
  background-position: -4999px 0;
}
.flag-ele.tn {
  height: 14px;
  background-position: -5021px 0;
}
.flag-ele.to {
  height: 10px;
  background-position: -5043px 0;
}
.flag-ele.tr {
  height: 14px;
  background-position: -5065px 0;
}
.flag-ele.tt {
  height: 12px;
  background-position: -5087px 0;
}
.flag-ele.tv {
  height: 10px;
  background-position: -5109px 0;
}
.flag-ele.tw {
  height: 14px;
  background-position: -5131px 0;
}
.flag-ele.tz {
  height: 14px;
  background-position: -5153px 0;
}
.flag-ele.ua {
  height: 14px;
  background-position: -5175px 0;
}
.flag-ele.ug {
  height: 14px;
  background-position: -5197px 0;
}
.flag-ele.um {
  height: 11px;
  background-position: -5219px 0;
}
.flag-ele.un {
  height: 14px;
  background-position: -5241px 0;
}
.flag-ele.us {
  height: 11px;
  background-position: -5263px 0;
}
.flag-ele.uy {
  height: 14px;
  background-position: -5285px 0;
}
.flag-ele.uz {
  height: 10px;
  background-position: -5307px 0;
}
.flag-ele.va {
  height: 15px;
  background-position: -5329px 0;
}
.flag-ele.vc {
  height: 14px;
  background-position: -5346px 0;
}
.flag-ele.ve {
  height: 14px;
  background-position: -5368px 0;
}
.flag-ele.vg {
  height: 10px;
  background-position: -5390px 0;
}
.flag-ele.vi {
  height: 14px;
  background-position: -5412px 0;
}
.flag-ele.vn {
  height: 14px;
  background-position: -5434px 0;
}
.flag-ele.vu {
  height: 12px;
  background-position: -5456px 0;
}
.flag-ele.wf {
  height: 14px;
  background-position: -5478px 0;
}
.flag-ele.ws {
  height: 10px;
  background-position: -5500px 0;
}
.flag-ele.xk {
  height: 15px;
  background-position: -5522px 0;
}
.flag-ele.ye {
  height: 14px;
  background-position: -5544px 0;
}
.flag-ele.yt {
  height: 14px;
  background-position: -5566px 0;
}
.flag-ele.za {
  height: 14px;
  background-position: -5588px 0;
}
.flag-ele.zm {
  height: 14px;
  background-position: -5610px 0;
}
.flag-ele.zw {
  height: 10px;
  background-position: -5632px 0;
}
