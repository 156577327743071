.dropdown {
  display: inline-block;
}

.dropdown--default {
  @extend .br-8;
  display: none;
  min-width: 240px;
  // max-width: 408px;
  padding: 16px 24px 24px;
  font-size: 16px;
  box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
    0 1px 4px 0 rgba(22, 45, 61, 0.1);
  background-color: white;
  li > a {
    display: inline-block;
    line-height: 24px;
  }
  &.is-open {
    display: block;
  }
}

// Dropdown New Variant
// _________________________
.header-v2 {
  .dropdown--default {
    width: 414px;
  }
  .page-links-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 8px;
    & > li {
      width: 160px;
      a > * {
        vertical-align: middle;
      }
      svg {
        margin-right: 12px;
      }
      a > span {
        margin-left: -4px;
        @extend %link-hover;
      }
      a:hover {
        span {
          color: $green-800;
          &::after {
            background-color: $blue-700;
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }
      }
    }
  }
  .section-links-container {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    a {
      padding: 4px 12px;
      border-radius: 14px;
      border: solid 1px #d2d9e0;
      display: block;
      font-size: 14px;
      line-height: 1.43;
      transition: all 0.4s linear;
      transition: border-color 0.4s linear, color 0.4s linear;
      margin-top: 12px;
      margin-left: 8px;
    }
  }
  .integration_list {
    .page-links-container a:hover {
      svg path:first-child,
      svg g path:first-child {
        fill: #1265b8;
      }
    }
    .section-links-container a:hover {
      color: #1265b8;
      border-color: #1265b8;
    }
    .header-dropdown-wordpress a:hover {
      svg path {
        fill: #1265b8;
      }
    }
    .header-dropdown-google-calendar a:hover {
      svg path {
        fill: #1265b8;
        &:first-child {
          fill: white !important;
        }
      }
    }
  }
  .industries_list {
    .page-links-container a:hover {
      svg > path {
        stroke: #1265b8;
      }
    }
    .section-links-container a:hover {
      color: #f57f31;
      border-color: #f57f31;
    }
  }
  .feature_list {
    .page-links-container a:hover {
      svg > path {
        stroke: #1265b8;
      }
    }
    .section-links-container {
      a {
        padding: 4px 11px;
      }
    }
    .section-links-container a:hover {
      color: #1265b8;
      border-color: #1265b8;
    }
  }
  .pricing_list {
    .dropdown--default {
      width: initial;
    }
    .page-links-container {
      flex-wrap: initial;
      padding-left: 0px;
      li {
        width: initial;
        min-width: 141px;
        a {
          display: block;
          padding: 8px 16px 16px;
          border-radius: 4px;
          border: solid 1px #d2d9e0;
        }
        &:nth-child(2) {
          margin-left: 16px;
          margin-right: 16px;
          a {
            border-color: $color-brand;
          }
        }
        .ff-euclid {
          font-size: 24px;
          line-height: 32px;
          color: #303a47;
          margin-bottom: 8px;
        }
        .text-xs {
          line-height: 20px;
        }
        .text-xs:last-child {
          color: #556270;
          margin-top: 2px;
        }
        a:hover {
          border-color: #1265b8;
          background-color: #ebf8ff;
          .ff-euclid,
          .text-xs:last-child {
            color: #1265b8;
          }
          p:nth-child(2) {
            color: $color-brand;
          }
        }
      }
      & + div {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .learn_list {
    .dropdown--default {
      width: initial;
      left: -35px !important;
    }
    .page-links-container {
      flex-wrap: initial;
      padding-left: 0px;
      li {
        width: initial;
        min-width: 160px;
        margin-right: 20px;
        padding-right: 8px;
        a {
          padding: 9px 12px 12px 0px;
          border-radius: 4px;
          &.btn-secondary {
            padding: 0px 20px;
            width: 123px;
            span {
              margin-left: 0px;
              &::after {
                background: none;
              }
            }
          }
        }
        &:nth-child(2) {
          margin-left: 18px;
          margin-right: 18px;
        }
        .ff-euclid {
          font-size: 20px;
          line-height: 32px;
          color: #303a47;
          margin-bottom: 8px;
        }
        .text-xs {
          line-height: 20px;
        }
        a:hover {
          .ff-euclid,
          .text-xs:last-child {
            color: #1265b8;
          }
          p:nth-child(2) {
            color: $color-brand;
          }
          svg > path {
            stroke: #1265b8;
          }
          &.btn-secondary span::after {
            background: none;
          }
        }
      }
      & + div {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
