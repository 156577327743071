//Home page
.page--home {
  .cta-contrast.has-image {
    @include respond-above(desktop) {
      padding-top: 80px;
      padding-bottom: 88px;
    }

    h2 {
      @extend .text-lg;
    }
  }

  .home-masonry {
    &.bg-offset::after {
      bottom: -32%;
      height: 54%;

      @include respond-below(tablet) {
        height: 32%;
        bottom: -31%;
      }
    }
  }

  .home-integrations {
    &.bg-offset::after {
      bottom: 0;
      height: 38%;

      @include respond-below(tablet) {
        height: 15%;
        bottom: 0;
      }
    }
  }

  .root-integrations {
    &.bg-offset::after {
      height: 36%;

      @include respond-below(tablet) {
        height: 15%;
      }
    }
  }
  .custom-bg-offset {
    .bg-offset::after {
      bottom: 0;
      height: 1000px;

      @include respond-below(tablet) {
        height: 1000px;
        bottom: 0;
      }
    }
  }

  .pricing-wrap {
    .card--default {
      @include respond-below(tablet) {
        > * {
          padding: 20px 40px 40px;
        }
        a.btn-primary,
        a.btn-secondary {
          background-color: $green-800;
          color: $white-900;
        }
      }

      .ticked-bulletin li {
        margin-bottom: 6px;
      }
    }
    &.bg-offset::after {
      top: 0;
      height: 50%;
      @include respond-below(tablet) {
        height: 18%;
        top: 0;
      }
    }
  }

  .use-setmore-wrap {
    .app-review-wrap {
      margin-top: -270px;
    }
  }

  .ordered-bulletin {
    @include respond-below(tablet) {
      li {
        margin-bottom: 15px;

        &::before {
          margin-right: 10px;
        }
      }
    }
  }
  .premium-bg-cta {
    background-color: $white-900;
  }
}
.landing-pages {
  &.bg-offset::after {
    height: 82%;

    @include respond-below(tablet) {
      height: 77%;
    }
  }
}
.lander {
  .pricing-wrap {
    .card--default {
      .plan-cta {
        @include respond-below(tablet) {
          min-height: 72px;
        }
      }
      .ticked-bulletin {
        li {
          margin-bottom: 6px;
        }
      }
    }
  }
  .app-review-wrap {
    @include respond-below(tablet) {
      position: absolute;
      top: 210px;
      margin-left: 60px;
    }
  }
  .faq-section {
    &.bg-offset::after {
      top: 0;
      height: 210px;

      @include respond-below(tablet) {
        height: 130px;
      }
    }
  }
  #dis-img-toggle {
    left: -110px;
    top: -15px;
    @include respond-below(tablet) {
      left: 80px;
      top: -60px;
    }
  }
}

.features {
  @include respond-below(tablet) {
    .fg-layer {
      margin-left: -20px;
      margin-bottom: 40px;
    }

    .cta-contrast.has-image picture {
      display: block;
    }

    .industry-masonry {
      &.bg-offset::after {
        height: 280px;
      }
    }
  }

  @include respond-below(desktop_standard) {
    .fg-layer {
      width: 120% !important;
    }
  }
  .btn-outline {
    cursor: pointer;
    @extend %btn-default;
    border: 1px solid $black-300;
    transition: 0.4s box-shadow, 0.3s background-color linear;

    > span {
      color: $black-600;
    }
    &:hover {
      @extend .hover-review-card;
    }
  }
}

// Signup Pages
// ____________________

.page-signup {
  .hero-wrap {
    padding-top: 120px;
    @include respond-below(tablet) {
      padding-top: 90px;
    }
  }
  .bottom-cta {
    .btn-primary {
      min-width: 240px;
      @include respond-below(tablet) {
        width: auto;
        min-width: 335px;
      }
    }
    @include respond-below(tablet) {
      h2 {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
  @include respond-below(tablet) {
    .resolution-image {
      position: absolute;
      top: 970px;
      width: 90%;
    }
  }
  .minimal-nav-desktop {
    .btn-outline {
      min-width: 120px;
      height: 32px;
      margin: 0 0 0 32px;
      padding: 4px 44px 4px 42px;
      border-radius: 6px;
      span {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.hero-terms {
  font-size: 14px;
  line-height: 24px;
  color: $black-400;
  @include respond-below(mobile) {
    width: 60%;
  }
  a {
    color: $color-brand;
  }
}

.signup-content-wrap {
  margin-top: -180px;

  @include respond-below(tablet_max) {
    margin-top: -140px;
  }

  @include respond-below(tablet) {
    margin-top: -25%;
  }

  @include respond-below(mobile_max) {
    margin-top: -35%;
  }

  > div {
    @include respond-below(desktop) {
      max-width: 460px;
    }

    @include respond-below(tablet_max) {
      max-width: 310px;
    }

    @include respond-below(tablet) {
      max-width: 560px;
    }
  }
}

// Missions
// ____________________
.try-setmore-wrap {
  text-align: left;

  @include respond-above(desktop) {
    article {
      margin-left: 40px;
    }
  }

  @include respond-between(tablet, desktop) {
    article {
      margin-left: 0;
    }
  }

  @include respond-below(tablet) {
    picture {
      img {
        width: calc(100% + 40px);
        margin-left: -20px;
      }
    }
  }

  > img {
    left: 0;
    top: -60px;

    @include respond-below(mobile) {
      left: -20px;
      top: -40px;
      max-width: initial;
      width: calc(100% + 40px);
    }

    @include respond-above(tablet) {
      left: -40px;
      top: -68px;
    }
  }
}

.mission-contribution {
  &.bg-offset::after {
    bottom: 0;
    height: 285px;

    @include respond-below(tablet) {
      height: 120px;
    }
  }
}

// Industry pages
.industry-masonry {
  &.bg-offset::after {
    top: 0;
    height: 210px;

    @include respond-below(tablet) {
      height: 224px;
    }
  }

  &.bg-offset-lg::after {
    top: 0;
    height: 262px;

    @include respond-below(tablet) {
      height: 224px;
    }
  }

  &.bg-offset-btm::after {
    top: auto;
    bottom: 0;
    height: 320px;

    @include respond-below(tablet) {
      height: 37px;
    }
  }
}

.industry-features {
  &.bg-offset::after {
    bottom: 0;
    height: calc(100% - 264px);

    @include respond-below(tablet) {
      height: 124px;
    }
  }

  @include respond-above(tablet) {
    &.bg-offset--adapt::after {
      height: calc(100% - 325px);
    }
  }
}

// Devices section
// ____________________
.device-wrap {
  .desktop-wrap {
    max-width: 540px;
  }

  @include respond-below(tablet) {
    .desktop-wrap,
    .mobile-wrap {
      width: 100%;
      margin: 0 auto;
    }
  }

  @include respond-below(mobile_sm) {
    [class^="btn-"] {
      margin: 0 0 20px 0;
    }
  }

  [class^="btn-"] {
    padding: 11px 24px;
  }

  .device-btn-wrap {
    > * {
      @include respond-below(tablet) {
        max-width: 160px;
        padding: 12px 20px;
      }
    }

    > *:first-child {
      @include respond-below(tablet) {
        margin-right: 15px;
      }

      @include respond-below(mobile_sm) {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
  }
}

// Use setmore section
// ____________________
.use-setmore-wrap {
  @include respond-above(tablet) {
    > div {
      max-width: 1360px;
      margin: 0 auto;
    }

    article {
      margin-left: 40px;
    }

    picture {
      img {
        margin-top: -80px;
      }
    }

    h2 {
      margin-bottom: 24px;
    }

    .btn-primary {
      margin-top: 40px;
    }
  }

  @include respond-between(tablet, desktop) {
    > div {
      align-items: center;
    }

    picture {
      min-width: 400px;

      img {
        margin-top: 0;
      }
    }

    article {
      margin-left: 20px;
    }
  }

  @include respond-below(tablet) {
    article {
      margin: 0 20px;
    }

    picture {
      img {
        margin-top: -40px;
      }
    }

    h2 {
      margin-bottom: 20px;
    }

    .btn-primary {
      margin-top: 20px;
    }
  }
}

// People love setmore

.people-love-setmore {
  .heart-glyph {
    @extend .ff-euclid;
    font-size: 40px;
    line-height: 64px;

    @include respond-below(tablet) {
      font-size: 28px;
      line-height: 48px;
    }
  }

  @include respond-below(tablet) {
    font-size: 38px;
    line-height: 48px;
  }
}

.customise-wrap {
  align-items: center;

  &.has-content-left {
    > article {
      margin-left: 100px;

      @include respond-between(tablet, desktop) {
        margin-left: 20px;
      }

      @include respond-below(tablet) {
        margin-left: 0px;
      }
    }
  }

  &.has-content-right {
    > article {
      margin-right: 100px;

      @include respond-between(tablet, desktop) {
        margin-right: 20px;
      }

      @include respond-below(tablet) {
        margin-right: 0;
      }
    }
  }

  @include respond-above(tablet) {
    h2 {
      margin-bottom: 24px;
    }
  }

  @include respond-below(tablet) {
    h2 {
      margin-bottom: 20px;
    }

    article {
      padding: 40px 20px 20px;
    }
  }
}

// Custom Margins, Paddings, Font-Sizes - Integration Root Page
.ng-m-top {
  margin-top: -48px;
}

// For Integration Root Page - Integration Cards
.integration-new {
  @include respond-below(tablet) {
    .c-padding {
      > a {
        padding: 20px 20px 24px 20px;

        figure {
          margin-right: 24px;

          svg {
            width: 40px;
            height: 40px;
          }

          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  .cta-contrast .dp-block {
    // background-color: #fff!important;
    @include respond-below(tablet) {
      display: block;
    }
  }

  .mg-right {
    margin-right: 5px;
  }

  .jc-fs {
    justify-content: flex-start;
  }

  .custom-size {
    .see-all {
      background-color: transparent !important;
      text-align: center;

      &:hover {
        box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
          0 1px 4px 0 rgba(22, 45, 61, 0.1);
        border: none;
        transition: 0.3s;
      }

      p {
        font-size: 18px;
        line-height: 28px;
        color: #3b4c66;
      }

      // text-align: center;

      p {
        font-size: 18px;
        line-height: 28px;
        color: #3b4c66;
      }

      .text-sm {
        color: $color-brand !important;
      }
    }
  }

  .font-italic {
    font-style: italic;
  }
}

// New Free Plan Page
.free-plan {
  .m-top-mob {
    @include respond-below(mobile) {
      margin-top: 390px;
    }
  }

  .app-review-wrap {
    .logo-wrapper {
      width: auto;

      .logo-adaptive {
        width: 48px;
        height: 48px;
      }
    }
  }

  //  Pricing Section Hero
  @include respond-above(tablet) {
    .width-adaptive {
      .card-width-adaptive {
        // margin-left: -175px !important;

        .card-list-adaptive {
          li {
            margin-bottom: 6px;
          }
        }
      }
    }
  }

  @include respond-above(desktop_sm) {
    .layered-images-wrap {
      left: calc(100% - 703px);
      position: absolute;
      bottom: 0px;
    }
  }

  @include respond-below(tablet_max) {
    .hero--default {
      .layered-images-wrap {
        display: none;
      }

      .app-review-wrap {
        top: 0;
      }

      .width-adaptive {
        .card-width-adaptive {
          // margin-left: 20% !important;
        }
      }

      @include respond-below(mobile) {
        .layered-images-wrap {
          display: block;
        }
      }

      @include respond-below(mobile) {
        .width-adaptive {
          .card-width-adaptive {
            margin-left: auto !important;
          }

          top: 360px;
        }
      }
    }
  }

  @include respond-below(tablet_max) {
    .hero--default {
      .app-review-wrap {
        // top: 42%;
        margin-left: 0;
      }
    }
  }

  @include respond-below(tablet) {
    .hero--default {
      .app-review-wrap {
        // top: 50%;
        // left: 34%;
      }
    }
  }

  @include respond-below(mobile) {
    .hero--default {
      .app-review-wrap {
        top: 1100px;
      }
    }
  }

  @include respond-below(mobile) {
    .dp-none {
      display: none;
    }

    .dp-block {
      display: block;
    }
  }

  @include respond-above(mobile) {
    .dp-none {
      display: block;
    }

    .dp-block {
      display: none;
    }
  }

  @include respond-above(tablet) {
    .grid--default {
      picture {
        max-width: 472px;
      }

      .custom-img-size {
        max-width: 560px;
      }
    }
  }

  .custom-height {
    @include respond-above(desktop) {
      max-height: 452px;
    }

    .adaptive-image {
      @include respond-above(tablet_max) {
        bottom: -19%;
      }
    }

    @include respond-below(mobile) {
      .dp-fx {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn-secondary {
          min-width: 140px;
          padding: 11px 24px;
        }
      }
    }
  }

  .custom-pricing-wrap {
    .custom-list-item {
      padding-bottom: 104px;

      .custom-margin-bottom {
        margin-bottom: 28px;

        @include respond-below(mobile) {
          li {
            margin-bottom: 6;
          }
        }
      }
    }

    .m-top {
      margin-top: 20px;

      @include respond-below(mobile) {
        margin-top: auto;
      }
    }
  }

  .pricing-wrap-mobile {
    @include respond-below(tablet_max) {
      top: 0;
      padding-bottom: 0;
    }
  }
}

.pricing-wrap .card--default.pr-card-header #bg-color-override,
.pricing-wrap .card--default.pr-card-header #bg-coloroverride {
  background-color: #fff !important;
}

.free-plan {
  .layered-images-wrap {
    @include respond-between(desktop_standard, desktop_md) {
      left: calc(100% - 50%);
    }
  }
}

.free-plan {
  .layered-images-wrap {
    @include respond-above(desktop_md) {
      right: 0;
      left: auto;
    }
  }
}

//Features
.featuresBookingPage.text-rem .highlight picture {
  transform: scale(1);
}

.featuresBookingPage,
.featuresClassBooking,
.featuresWebsiteWidgets,
.staffScheduling,
.featuresCustomerReviews,
.featuresGoogleCalendarSync,
.featuresAppointments,
.featuresVirtualMeetings,
.featuresOnlinePayments {
  .bg-offset-top {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 120px;
      left: 0;
      background-color: $black-200;
      @include z-index(minus-1);
    }
  }
  .bg-offset-bottom {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 115px;
      left: 0;
      background-color: $black-200;
      @include z-index(minus-1);
    }
  }
  .block--align-center {
    margin: 0 auto;
  }
  .cta-contrast.has-image h2 {
    font-size: 38px !important;
    line-height: 48px !important;
    @include respond-below(tablet) {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }
  h2.text-xl {
    margin-bottom: 0px !important;
  }

  @include respond-above(tablet) {
    .use-setmore-wrap picture img {
      margin-top: 0px !important;
    }
    .use-setmore-wrap .btn-primary {
      margin-top: 0px;
    }
  }
  @include respond-below(tablet) {
    .use-setmore-wrap picture img {
      margin-top: 0px;
    }
    .use-setmore-wrap .btn-primary {
      margin-top: 0px;
    }
    .bg-offset-top {
      &::before {
        height: 45px;
      }
    }
    .bg-offset-bottom {
      &::after {
        height: 45px;
      }
    }
    .features h2 {
      font-size: 32px !important;
      line-height: 1.25 !important;
    }
  }
  .features .btn-outline {
    border-radius: 8px !important;
  }
  .hero-logo {
    width: 157px;
  }
}

.featuresClassBooking {
  .lg\:maxw-mds {
    max-width: 480px !important;
  }
}

.featuresBookingPage {
  .highlight picture {
    transform: scale(1.18);
  }
  @include respond-below(tablet) {
    .highlight {
      .md\:fx {
        overflow-y: hidden;
        overflow-x: hidden;
      }
      picture {
        transform: scale(1.24);
      }
    }
  }
}

.featuresClassBooking {
  .highlight picture {
    transform: scale(1.1);
    margin-top: 12px !important;
  }
  @include respond-below(tablet) {
    .highlight {
      .md\:fx {
        overflow-y: hidden;
        overflow-x: hidden;
      }
    }
  }
}

.featuresVirtualMeetings {
  .hero--default {
    .btn-primary {
      @include respond-above(tablet_max) {
        width: 242px;
      }
    }
  }
  .cta-contrast {
    .btn-ternary {
      @include respond-below(tablet) {
        padding: 12px 16px;
      }
    }
  }
}

.featuresOnlinePayments {
  .feature-moreinfo {
    margin-top: 30px;
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 2;
    color: #556784;
  }
  .hero--default {
    .btn-primary {
      @include respond-above(tablet_max) {
        width: 242px;
      }
    }
  }
}

.online-agenda-software,
.online-booking-diary-software,
.online-scheduling-assistant {
  .app-review-wrap {
    background-color: white;
  }
  .bg-offset-bottom {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 260px;
      left: 0;
      background-color: $black-200;
      @include z-index(minus-1);
    }
  }
  @include respond-below(tablet) {
    .app-review-wrap {
      background-color: transparent;
      margin-top: 22px;
      width: 100%;
    }
    .highlight {
      .btn-secondary {
        width: 100%;
      }
    }
    .list-with-icon li > span {
      display: inline-block;
      width: 86%;
      vertical-align: top;
      margin-top: 3px;
    }
    .card--masonry li.bg-green {
      picture {
        margin-top: -36px !important;
        margin-left: -23px !important;
      }
    }
    .ticked-bulletin li {
      margin-bottom: 6px !important;
    }
    .pricing-wrap.bg-offset-bottom {
      &::after {
        height: 67%;
      }
    }
  }
}
.online-booking-diary-software,
.online-scheduling-assistant {
  .list-with-icon li > span {
    display: inline-block;
    width: 86%;
    vertical-align: top;
    margin-top: 3px;
  }
}
.online-scheduling-assistant {
  .app-review-wrap {
    background-color: transparent;
  }
}
.industry-root-page {
  scroll-behavior: smooth;
  .industry__nav {
    &.bg-offset::after {
      bottom: 0;
      height: 50%;
    }
    nav {
      box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
      padding: 22px 40px;
      transition: 0.7s transform;
      transform: scaleY(1);
      a {
        display: inline-block;
        opacity: 1;
        transition: 0.2s opacity;
        padding: 6px 30px;
        margin-left: 29px;
        border-radius: 18px;
        border: 1px solid $black-300;
        &:first-child {
          margin-left: 0;
        }
        &:active {
          color: white !important;
        }
      }
    }
    &.animate-nav {
      nav {
        a {
          opacity: 0;
        }
        transform: scaleY(0);
      }
    }
  }
  .client-card.bg-green picture {
    margin-top: -18px !important;
  }
  @include respond-below(tablet) {
    .categories .card--default > a {
      display: block !important;
    }
    .industry__nav {
      width: 100%;
      overflow-x: auto;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
      }
      nav {
        white-space: nowrap;
        box-shadow: none;
        padding-left: 0;
        a {
          margin-left: 16px;
          &:last-child {
            margin-right: 20px;
          }
        }
      }
      &.bg-offset::after {
        content: none;
      }
    }
    .client-card.bg-green picture {
      margin-top: -48px !important;
    }
  }
  .categories {
    article h2::after {
      content: "";
      display: block;
      width: 80px;
      height: 8px;
      border-radius: 8px;
      margin-top: 16px;
    }
    .categories__beauty::after {
      @extend .bg-yellow;
    }
    .categories__education::after {
      @extend .bg-orange;
    }
    .categories__wellbeing::after {
      @extend .bg-red;
    }
    .categories__professional::after {
      @extend .bg-green;
    }
    .categories__health::after {
      @extend .bg-blue;
    }
    .categories__trade::after {
      @extend .bg-purple;
    }
    .categories__creative::after {
      @extend .bg-light-grey;
    }
    .card--default {
      justify-content: flex-start;
    }
    .btn-outline {
      .animated-dot {
        display: none;
      }
      cursor: pointer;
    }
    .icon-title-wrap {
      position: relative;
      display: block;
      .animated-dot {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        animation: dot-animation 0.3s linear;
        width: 16px;
        height: 16px;
      }
      @keyframes dot-animation {
        from {
          transform: scale(0, 0);
        }
        to {
          transform: scale(1, 1);
        }
      }
    }
    .btn-outline:hover {
      .animated-dot {
        display: inline-block;
      }
      background-color: #fff;
      border-color: transparent;
    }
  }
}

.featuresWebsiteWidgets {
  .hero--default {
    picture img {
      height: 560px;
    }
  }
  .highlight {
    picture {
      transform: scale(1.05);
      margin-top: 12px !important;
      margin-left: 12px !important;
    }
  }
  @include respond-below(tablet) {
    .hero--default {
      picture {
        transform: scaleX(1.05);
        margin-right: 8px;
        img {
          height: 328px;
        }
      }
    }
    .highlight {
      picture {
        transform: scale(1.08);
        margin-top: 40px;
        margin-left: 16px;
      }
    }
  }
}

// White paper

.whitepaper {
  .fw-900 {
    font-weight: 900;
  }
  .fw-bold {
    font-weight: bold;
  }
  .title-lg {
    margin-bottom: 22px;
  }
  .dropdown-field {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23181818'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d='M6.576.469c.173-.18.454-.18.627 0 .156.163.172.417.047.598l-.047.057L1.573 7l5.63 5.876c.156.163.172.417.047.598l-.047.057c-.156.163-.399.18-.572.049l-.055-.049L.913 7.62C.755 7.456.667 7.233.667 7c0-.2.065-.392.183-.546l.063-.074L6.576.47z' transform='translate(-1196 -420) translate(840 120) translate(80 264) rotate(90 128 164) translate(4 1) rotate(-180 4 7)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 36px;
    cursor: pointer;
  }
  .input-checkbox {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    .g-checkbox {
      min-width: 20px;
      max-width: 20px;
      height: 20px;
      padding: 0;
      outline: 0;
      border: 1px solid #8495b1;
      background-color: #fff;
      cursor: pointer;
      border-radius: 4px;
    }

    .g-checkbox:hover {
      border: 1px solid $color-brand;
    }

    .g-checkbox-disabled {
      cursor: default;
      pointer-events: none;
      border-color: #d9e1ee;
      background-color: #f6f8fc;
    }

    .g-checkbox-error {
      background-color: #ffefef;
      border-color: #fc4c4c;
    }

    .g-checkbox-error:hover {
      border-color: #fc4c4c;
    }
    .g-checkbox > .g-icon {
      display: none;
    }

    .g-checkbox:after {
      font-size: 9px;
    }

    .g-checkbox-checked {
      background-color: #1d90f5;
      border-color: $color-brand;
    }

    .g-checkbox-checked > .g-icon {
      fill: #fff;
      display: block;
    }

    .g-checkbox-disabled > .g-icon {
      fill: #f6f8fc;
      display: block;
    }
    span {
      font-size: 12px;
      color: $black-600;
      line-height: 1.67;
      margin-left: 8px;
    }
  }
  .signup-content-wrap > div {
    margin-top: -276px !important;
    figure picture {
      transform: scale(1.05);
      margin-left: -40px;
    }
  }
  .slider {
    display: flex;
    position: relative;
    max-width: 560px;
    align-items: center;
    & > div > img {
      box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
        0 1px 4px 0 rgba(22, 45, 61, 0.1);
    }
    .slide-one,
    .slide-three {
      width: 260px;
      height: 368px;
    }
    .slide-two {
      width: 360px;
      height: 510px;
      margin-left: -160px;
      margin-right: -160px;
      position: relative;
      @include z-index(1);
    }
    .slider div {
      overflow: hidden;
    }
    .controls {
      display: inline-block;
      font-weight: bold;
      height: 48px;
      width: 48px;
      background-color: rgba(29, 144, 245, 0.8);
      text-align: center;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      opacity: 0.8;
      cursor: pointer;
      svg {
        margin-top: 12px;
      }
    }
    .slide-right {
      right: 16px;
    }
    .slide-left {
      left: 16px;
    }
    .hide {
      display: none;
      cursor: initial;
    }
  }
  .custom-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
    position: relative;
    background-color: white;
    article {
      margin-left: 24px;
    }
    figure {
      min-width: 120px;
      height: 120px;
      font-family: $primary-font;
      .pie {
        width: 100% !important;
        height: 100% !important;
        svg {
          width: 100%;
          height: 100%;
          transform: scale(1.14);
        }
      }
    }
  }
  .bg-offset-bottom {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 315px;
      left: 0;
      background-color: $black-200;
      @include z-index(minus-1);
    }
  }
  .g-dropdown-wrap {
    position: relative;
    min-width: 120px;
    max-width: 400px;
  }

  .g-dropdown-wrap .g-drop-label {
    display: block;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #3b4c66;
    margin-bottom: 8px;
    .open {
      display: block;
    }
  }

  .g-dropdown-wrap .g-drop-btn {
    border: 0;
    border-bottom: 1px solid #d3d9e5;
    background-color: transparent;
    color: $black-400;
    padding: 0 16px 12px 0;
    font-size: 16px;
    outline: 0;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    cursor: pointer;
  }

  .g-dropdown-wrap .g-drop-btn > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
    line-height: 24px;
  }

  .g-dropdown-wrap .g-dropmenu,
  .g-dropdown-wrap .g-submenu {
    position: absolute;
    @include z-index(100);
    width: 100%;
    display: none;
  }

  .g-dropdown-wrap .g-drop-arrow {
    width: 10px;
    height: 10px;
    border: 1px solid #181818;
    border-width: 0 0 1px 1px;
    margin-left: auto;
    transform: rotate(-45deg);
    transition: all 0.3s ease;
    position: relative;
  }

  .g-dropdown-wrap.open .g-drop-btn {
    border-color: #4684ff;
  }

  .g-dropdown-wrap.open .g-drop-btn .g-drop-arrow {
    transform: rotate(135deg);
  }

  .g-dropdown-wrap.open .g-dropmenu {
    display: block;
  }

  .g-dropdown-wrap .g-dropmenu {
    position: absolute;
    top: 100%;
    left: 0;
  }

  .g-dropdown-wrap.top-pos .g-dropmenu {
    bottom: 100%;
    top: unset;
  }

  .g-dropdown-wrap.right-pos .g-dropmenu {
    left: 100%;
    top: calc(50% - 6px);
    transform: translateY(-50%);
  }

  .g-dropdown-wrap.left-pos .g-dropmenu {
    left: unset;
    right: 100%;
    top: calc(50% - 6px);
    transform: translateY(-50%);
  }

  .g-dropdown-wrap.has-search .g-searchbox {
    display: block;
  }

  .g-dropdown-wrap.warning .g-drop-btn {
    border-color: #ffa300;
  }

  .g-dropdown-wrap.error .g-drop-btn {
    border-color: #dd2727;
  }

  .g-dropdown-wrap.warning .g-drop-msg {
    color: #ffa300;
  }

  .g-dropdown-wrap.error .g-drop-msg {
    color: #dd2727;
  }

  .g-dropmenu,
  .g-submenu {
    box-shadow: 0 3px 4px 0 rgba(10, 31, 68, 0.1),
      0 0 1px 0 rgba(10, 31, 68, 0.08);
    background-color: #fff;
    border-radius: 4px;
    padding: 8px 0;
  }

  .g-dropmenu ul {
    max-height: 200px;
    overflow: auto;
  }

  .g-dropmenu ul li {
    display: flex;
    align-items: center;
    padding: 10px 16px 10px 24px;
    transition: all 0.2s ease;
    position: relative;
    cursor: pointer;
  }

  .g-dropmenu ul li > span {
    font-size: 14px;
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85%;
  }

  .g-dropmenu ul li > .g-drop-arrow {
    margin-left: auto;
    transform: rotate(-135deg);
  }

  .g-dropmenu ul li:hover {
    background-color: #f3f6fc;
  }

  .g-dropmenu ul li.has-check {
    padding: 10px 16px;
  }

  .g-dropmenu ul li.has-check:hover {
    background-color: inherit;
    color: inherit;
  }

  .g-dropmenu ul li.has-check .g-checkbox,
  .g-dropmenu ul li.has-check .g-radio-button {
    margin-right: 8px;
    vertical-align: middle;
  }

  .g-dropmenu ul li.has-check.selected {
    background-color: transparent;
  }

  .g-dropmenu ul li.has-avatar .g-avatar,
  .g-dropmenu ul li.has-avatar .g-avatar-lg {
    margin-right: 10px;
    vertical-align: middle;
  }

  .g-dropmenu ul li.has-avatar span {
    line-height: 30px;
  }

  .g-dropmenu ul li.selected {
    background-color: transparent;
  }

  .g-dropmenu ul li.selected > span {
    color: #6285ff;
  }

  .g-dropmenu ul li.selected:hover {
    background-color: #f3f6fc;
    color: inherit;
  }

  .g-dropmenu ul li.selected:hover > .g-drop-arrow,
  .g-dropmenu ul li.selected > .g-drop-arrow {
    border-color: inherit;
  }

  .g-dropdown-wrap .g-drop-msg,
  .g-input-msg {
    display: none;
    font-size: 0;
    width: 100%;
    padding-top: 8px;
  }

  .g-dropdown-wrap .g-drop-msg > *,
  .g-input-msg > * {
    vertical-align: middle;
  }

  .g-dropdown-wrap .g-drop-msg > span,
  .g-input-msg > span {
    font-size: 10px;
  }

  .g-dropdown-wrap .g-drop-msg .g-icon,
  .g-input-msg .g-icon {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  .g-dropdown-wrap.error .g-drop-msg,
  .g-dropdown-wrap.message .g-drop-msg,
  .g-dropdown-wrap.warning .g-drop-msg,
  .g-input-wrapper.error .g-drop-msg,
  .g-input-wrapper.message .g-drop-msg,
  .g-input-wrapper.warning .g-drop-msg,
  .g-input:invalid .g-drop-msg {
    display: block;
  }

  .g-dropdown-wrap.message .g-drop-msg .g-icon,
  .g-input-wrapper.message .g-drop-msg .g-icon {
    display: none;
  }

  .g-dropdown-wrap.has-submenu .g-dropmenu > ul {
    overflow: inherit;
    max-height: initial;
  }

  .g-dropdown-wrap.has-submenu .has-submenu.selected .g-submenu {
    display: block;
    left: 100%;
    top: -8px;
  }
  @include respond-below(tablet) {
    & {
      .signup-content-wrap > div {
        margin-top: -214px !important;
        figure picture {
          transform: scale(1.1);
          margin-left: 0;
        }
      }
      .hero-terms {
        max-width: 238px;
        width: 100%;
      }
    }
    .slider {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      .slide-one,
      .slide-three {
        max-width: 170px !important;
        height: 240px !important;
      }
      .slide-two {
        width: 226px !important;
        height: 320px !important;
        margin: 0 !important;
        margin-left: -116px !important;
        margin-right: -116px !important;
      }
      .slide-right {
        right: -10px;
      }
      .slide-left {
        left: -10px;
      }
    }
    & .custom-card {
      padding: 20px 22px 24px 30px;
      align-items: flex-start !important;
      figure {
        min-width: 64px !important;
        height: 64px !important;
      }
      article {
        margin-left: 34px;
        p {
          line-height: 26px !important;
        }
      }
    }
    .bg-offset-bottom {
      &::after {
        height: 150px !important;
      }
    }
    .signup-content-wrap picture {
      transform: scale(1.1);
      margin-top: 15px;
    }
  }
}
.home-repair {
  @include respond-below(tablet) {
    .custom-masonry {
      overflow: hidden;
      picture {
        margin-left: -11px !important;
      }
    }
    .industry-masonry article {
      margin-left: 20px;
      margin-right: 20px;
      width: inherit;
      & > ul {
        max-width: none;
      }
    }
  }
}

.freshdesk,
.zoom,
.clio {
  .hero--default a {
    display: inline-block;
  }
  @include respond-below(tablet) {
    & .integrations-faq h3 {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}

.freshdesk {
  @include respond-above(tablet) {
    & .industry-features.bg-offset--adapt::after {
      height: 112px;
    }
  }
  @include respond-below(tablet) {
    & .industry-features.bg-offset::after {
      height: 413px;
    }
  }
}

.clio {
  @include respond-above(tablet) {
    & .industry-features.bg-offset--adapt::after {
      height: 144px;
    }
  }
  @include respond-below(tablet) {
    & .industry-features.bg-offset::after {
      height: 443px;
    }
  }
}

.zoom {
  .industry-features.bg-offset--adapt::after {
    height: 160px;
  }
  @include respond-below(tablet) {
    & .industry-features.bg-offset--adapt::after {
      height: 456px;
    }
    & .bg-offset-top::after {
      height: 124px;
    }
  }
}
.square-online {
  .industry-features.bg-offset--adapt::after {
    height: 160px;
  }
  .square-online-services li:last-child {
    picture {
      transform: scale(1.04);
      margin-left: 12px;
    }
  }
  @include respond-below(tablet) {
    & .industry-features.bg-offset--adapt::after {
      height: 510px;
    }
    & .bg-offset-top::before {
      height: 124px;
    }
    & .square-online-services li:last-child {
      picture {
        transform: scale(1.08);
      }
    }
  }
}

.wedding-boutiques {
  @include respond-below(tablet) {
    .masonry-image {
      position: relative;
      right: 12px;
    }
  }
}

.staffScheduling {
  @include respond-below(desktop) {
    .hero--default {
      &.use-setmore-wrap article {
        margin-left: 40px;
      }
    }
    .layered-images-wrap {
      max-width: 760px;
      position: absolute;
      right: 0;
      .bg-layer {
        // margin-left: -110px;
        height: 480px;
      }
    }
  }
  @include respond-above(desktop_standard) {
    .hero--default {
      &.use-setmore-wrap article {
        margin-left: 140px;
      }
    }
  }
  @include respond-above(tablet) {
    .hero--default {
      & > div {
        flex-wrap: nowrap;
        overflow: hidden;
        max-width: initial;
        justify-content: center;
        & > figure {
          overflow: hidden;
          width: 760px;
          height: 560px;
          position: relative;
          .fg-layer {
            width: 760px;
            position: absolute;
            left: 128px;
            top: 0px;
          }
          .bg-layer {
            width: 560px;
            height: 480px;
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }
  @include respond-above(desktop_md) {
    .hero--default {
      & > div {
        & > figure {
          overflow: visible;
        }
      }
    }
  }
  @include respond-below(tablet) {
    .hero--default.container {
      margin-left: 0;
      margin-right: 0;
    }
    .hero--default {
      figure {
        position: relative;
      }
      .fg-layer {
        right: -1px;
        left: initial;
        top: 0;
        width: 296px;
        height: 328px;
        img {
          height: 100%;
        }
      }
      .bg-layer {
        height: 289px;
        img {
          height: 100%;
        }
      }
    }
    .highlight {
      margin-top: 38px;
    }
  }
}

.barber-shops {
  .hero--default {
    .fg-layer {
      bottom: -80px;
      @include respond-below(tablet) {
        bottom: -40px;
      }
    }
    .app-review-wrap {
      margin-top: 82px;
      @include respond-below(tablet) {
        margin-top: 42px;
      }
    }
  }

  .use-setmore-wrap {
    div {
      max-width: 1160px;
    }
    article {
      margin: 0;
      @include respond-above(desktop) {
        margin-left: 140px;
      }
    }
    picture {
      img {
        margin-top: 0;
      }
    }

    .video-highlight {
      position: relative;
      border-radius: 8px;
      width: 560px;
      height: 314px;
      @include respond-above(desktop) {
        margin-top: -40px;
      }
      @include respond-below(tablet) {
        width: 100%;
        height: 100%;
      }

      .video-timg {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: transparent;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          border-radius: 8px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: opacity 0.3s;
        }
        &:focus {
          outline: none;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .video-timg:hover {
        &::before {
          opacity: 0.25;
          cursor: pointer;
        }
        path {
          transform: scale(1.3);
          transform-origin: 40px 40px;
        }
      }
      .video-timg:active {
        &::before {
          opacity: 0.5;
        }
        circle {
          fill-opacity: 1;
          fill: #fff;
        }
      }
      #videoUtube {
        display: none;
        border-radius: 8px;
        @include respond-below(tablet) {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 99.8%;
          height: 100%;
          z-index: 1;
        }
      }
      .play-btn {
        width: 80px;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        cursor: pointer;
        border: 0;
        padding: 0;
        background-color: transparent;
        outline: none;
      }
    }
    .is-played {
      @include respond-below(tablet) {
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
      }
    }
  }
  .customise-wrap {
    box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
  }
  // .faq-section {
  //   input:checked ~ .faq-point {
  //     max-height: 380px;
  //     @include respond-below(tablet) {
  //       max-height: 600px;
  //     }
  //   }
  //   .faq {
  //     background-color: #fff;
  //     width: 560px;
  //     @include respond-below(tablet) {
  //       width: 335px;
  //     }
  //   }
  //   .faq-ques {
  //     position: relative;
  //     width: 100%;
  //     padding: 26px 64px 30px 20px;
  //     &::after {
  //       position: absolute;
  //       top: 50%;
  //       right: 25px;
  //       transform: translateY(-50%) rotate(135deg);
  //     }
  //     @include respond-below(tablet) {
  //       width: 100%;
  //       padding: 26px 50px 30px 20px;
  //     }
  //   }
  //   input:checked {
  //     + .faq-ques {
  //       color: $black;
  //       &::after {
  //         top: 50%;
  //         transform: translateY(-50%) rotate(-45deg);
  //       }
  //     }
  //     ~ .faq-point {
  //       padding-bottom: 32px;
  //       @include respond-below(tablet) {
  //         padding-bottom: 24px;
  //       }
  //     }
  //   }
  // }
  // .faq-section.bg-offset::after {
  //   bottom: 565px;
  //   @include respond-below(tablet) {
  //     bottom: 841px;
  //   }
  // }
  .card--masonry {
    .client-card {
      padding-bottom: 24px;
      a {
        text-decoration: underline;
      }
    }
  }

  @include respond-above(tablet_max) {
    .cta-contrast.has-image.has-img-offset {
      picture {
        right: 0;
        width: 660px;
      }
    }
  }

  .cta-contrast {
    @include respond-above(tablet_max) {
      min-height: 368px;
    }
  }
}
//accountants industry page

.accountants {
  .cta-contrast {
    @include respond-above(tablet_max) {
      min-height: 370px;
    }
  }
  .industry-features {
    @include respond-above(tablet_max) {
      &.bg-offset {
        &::after {
          height: 335px;
        }
      }
    }
  }
}

// Doctors industry page
.doctors {
  .industry-features {
    h2 {
      max-width: 1030px;
    }
  }
  @include respond-above(tablet_max) {
    .hover-link-blue {
      display: inline;
    }
  }
}

// Medical appointment scheduling software page
.medical {
  .industry-features {
    h2 {
      max-width: 1030px;
    }
  }
}

//ITprofessional page
.ITprofessional {
  .cta-contrast {
    @include respond-above(tablet_max) {
      min-height: 330px;
    }
  }
}

.animated-sub-nav {
  box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
  border-radius: 8px;
  padding: 22px 20px;
  transition: background-color 0.4s ease-in-out 0.4s,
    box-shadow 0.4s ease-in-out;
  transform: scaleY(1);
  .nav-categories > li {
    display: inline-block;
    min-width: 120px;
    opacity: 1;
    transition: 0.2s opacity;
    border-radius: 18px;
    margin-left: 20px;
    &:first-child {
      margin-left: 0;
    }
    a {
      display: block;
      border-radius: inherit;
      padding: 6px 0px;
      text-align: center;
      border: 1px solid $black-300;
      &:hover {
        border-color: $color-brand;
        color: $color-brand;
      }
      &:active {
        border-color: $color-brand;
        color: white;
        background-color: $blue-500;
      }
      &.active {
        border-color: $black-200;
        background-color: $black-200;
        color: $black-600;
        @include respond-below(tablet) {
          background-color: $black-300;
        }
      }
    }
  }
  .nav-form {
    border-radius: 18px !important;
    border: 1px solid $black-300;
    padding: 6px 8px 6px 20px;
    width: 240px;
    height: 36px;
    display: flex;
    align-items: center;
    @include respond-below(tablet) {
      & {
        width: 100%;
      }
    }
    &:hover {
      border-color: $color-brand;
    }
    &.focus {
      border-color: $color-brand;
      background-color: white;
      .search-btn svg .svg-color {
        fill: $blue-500;
      }
      .search-btn:hover svg .svg-color {
        fill: white;
      }
      input {
        background-color: white;
      }
    }
    input[name="search-field"] {
      width: calc(100% - 52px);
      height: 100%;
      vertical-align: middle;
      color: $black-600;
      background-color: transparent;
      font-size: 14px;
      line-height: 24px;
      padding: 0;
      @include respond-below(tablet) {
        font-size: 16px;
      }
      &::placeholder {
        color: $black-400;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: $black-400;
      }
      & ::-ms-input-placeholder {
        color: $black-400;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-text-fill-color: $black-600 !important;
        color: $black-600 !important;
      }
    }
    .clear-search-btn {
      visibility: hidden;
      vertical-align: middle;
      margin-right: 8px;
      @include respond-below(tablet) {
        margin-right: 0;
      }
      &:hover {
        cursor: pointer;
        .svg-color {
          fill: $blue-500;
        }
      }
      &.show {
        visibility: visible;
      }
    }
    button {
      background-color: transparent;
      padding: 0;
      margin: 0;
      outline: 0;
      border: 0;
    }
    .search-btn {
      // position: absolute;
      // top: 3px;
      height: 28px;
      // right: 4px;
      padding: 4px;
      &:hover {
        cursor: pointer;
        background-color: $blue-500;
        border-radius: 50%;
        .svg-color {
          fill: white;
        }
      }
    }
  }
  &.has-form {
    display: flex;
    justify-content: space-between;
  }
  &.animate-nav {
    & > * {
      opacity: 1;
    }
    background-color: transparent;
    box-shadow: none;
  }
  @include respond-below(tablet) {
    &.has-mobile-scroll {
      box-shadow: none;
      border-radius: 0px;
      background-color: transparent;
      padding: 0;
      flex-direction: column;
    }
    & .mobile-scroll {
      width: 100%;
      overflow-x: auto;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
      }
    }
    & .nav-categories {
      white-space: nowrap;
    }
  }
}

.sitemap {
  .hide {
    display: none !important;
  }
  .pages-container.hover\:box-shadow {
    cursor: initial;
  }
  .card--default > ul > li {
    background-color: transparent;
    box-shadow: none;
    border: 1px solid $black-300;
    color: $color-brand;
    font-size: 18px;
    line-height: 1.56;
    h3 {
      font-size: 32px;
      line-height: 1.25;
      color: $color-dark;
      font-weight: $semibold;
      font-family: $primary-font;
    }
    &.pages-container {
      li {
        &:first-child {
          margin-top: 0;
        }
        margin-top: 12px;
        a {
          display: inline-block;
          @extend .hover-link-blue;
        }
        &.hoverState {
          box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
            0 1px 4px 0 rgba(22, 45, 61, 0.1);
          background-color: white;
        }
      }
    }
  }
  .use-setmore-wrap {
    img {
      margin-top: 0 !important;
    }
  }
  .search-results {
    justify-content: flex-start;
    & a {
      margin-right: 40px;
      margin-top: 40px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      @include respond-above(tablet) {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0;
        }
      }
      @include respond-between(tablet, tablet_max) {
        margin-right: 20px;
      }
      @include respond-below(tablet) {
        margin-top: 20px;
        margin-right: 0px;
        &:first-child {
          margin-top: 0px;
        }
      }
    }
  }
  @include respond-below(tablet) {
    #no-results-container {
      h2 {
        font-size: 24px;
        line-height: 1.33;
      }
    }
  }
  .english .pages-container:first-child {
    min-height: 400px;
  }
}

.guides-root {
  .cards__moreInfo {
    display: flex;
  }
  .blog-posts {
    @include respond-above(tablet) {
      margin-top: 40px !important;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0 !important;
      }
    }
    @include respond-below(tablet) {
      &:nth-child(1) {
        margin-top: 0 !important;
      }
    }
    h3 {
      font-size: 24px;
      line-height: 32px;
      color: $color-dark;
      font-family: $primary-font;
    }
    &:hover {
      background-color: white;
      border-color: #d9e1ee;
    }
  }
  @include respond-above(tablet_max) {
    .cta-contrast.has-image {
      h2 {
        font-size: 38px;
        line-height: 1.26;
      }
      picture {
        top: -80px;
      }
    }
  }
  @include respond-above(tablet) {
    .hero--default .fg-layer {
      transform: scale(1.2);
      position: absolute;
      left: -48px;
      bottom: -40px;
    }
    .card--default.card--feature > a {
      margin-top: 40px;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0;
      }
    }
  }
  @include respond-below(tablet) {
    .hero--default .fg-layer {
      top: 0;
    }
    .cards__moreInfo {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

// For CLS improvement

.cls-improvement {
  .use-setmore-wrap {
    &.bg-light {
      picture img {
        aspect-ratio: 760/460;
      }
    }
    @include respond-below(tablet) {
      & > div > picture {
        width: 100%;
      }
    }
    &.hero--default > div > picture {
      & img {
        aspect-ratio: 624/560;
      }
    }
  }
  .hero--default {
    & > figure:nth-child(2) img {
      aspect-ratio: 560/526;
    }
  }
  .grid--default {
    & > picture img {
      aspect-ratio: 560/400;
    }
    & > figure .bg-layer {
      aspect-ratio: 560/480;
    }
    & > figure .fg-layer {
      aspect-ratio: auto !important;
    }
  }
  &--desktop {
    @include respond-above(tablet) {
      .grid--default {
        & > figure .fg-layer {
          aspect-ratio: 560/550 !important;
        }
      }
    }
  }
}

@include respond-above(desktop_standard) {
  .highlight-img {
    min-width: 40%;
  }
}
.teachers {
  .masonry-image {
    width: 270px;
    margin-left: auto;
    margin-right: auto;
  }
  @include respond-above(tablet) {
    .review-card p {
      margin-bottom: 20px;
    }
  }
  @include respond-below(tablet) {
    .cta-contrast.has-image {
      padding-bottom: 27px;
    }
  }
}

//integration animation
@include respond-above(tablet) {
  .logo-holder {
    &.is-sticky {
      position: fixed !important;
      top: 190px !important;
      @include z-index(10);
    }
    &.is-mutable {
      opacity: 0;
    }
    &.is-visible {
      opacity: 1;
    }
  }
  .logo-content-holder {
    &.content-sticky {
      position: relative;
      left: 400px;
    }
  }
}
.booking-page-setup,
.automate-your-front-desk {
  @include respond-above(tablet_max) {
    .card--default.card--feature {
      & .icon-title-wrap p {
        max-width: 200px;
      }
    }
  }
}

.integration-listing-page {
  .logo-holder figure {
    max-width: 260px;
  }
  .faq-section .faq-ques {
    padding: 26px 30px 28px 20px;
    &::after {
      position: absolute;
      top: 50%;
      right: -56px;
      width: 24px;
      height: 24px;
      border: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2348A36C' fill-rule='evenodd' d='M21.797 15.864c.27.26.27.681 0 .941-.245.234-.625.257-.897.07l-.086-.07L12 8.36l-8.814 8.445c-.244.234-.625.257-.896.07l-.087-.07c-.244-.234-.268-.599-.073-.859l.074-.082 8.866-8.495c.246-.236.581-.369.93-.369.3 0 .588.098.819.274l.11.095 8.868 8.495z' clip-rule='evenodd'/%3E%3C/svg%3E");
      transform: translateY(-50%) rotate(180deg);
    }
    @include respond-below(tablet) {
      &::after {
        right: -63px;
      }
      padding-right: 0;
    }
  }
  .faq-section input:checked + .faq-ques::after {
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
  }
  .ordered-bulletin {
    & > li {
      position: relative;
      padding-left: 38px;
      margin-bottom: 12px;
      &::before {
        position: absolute;
        left: 0px;
        top: 5px;
        width: 18px;
        height: 18px;
        line-height: 18px;
      }
    }
  }
  @include respond-below(tablet) {
    .cta-contrast.has-image h2 {
      font-size: 24px !important;
      line-height: 30px !important;
    }
    .lander-cta {
      .cta-contrast.has-image h2 {
        font-size: 24px !important;
        line-height: 32px !important;
      }
    }
    .faq-section.bg-offset-top::before {
      height: 95px;
    }
    .faq-section input:checked ~ .faq-point {
      padding-bottom: 24px;
      max-height: initial;
    }
    &.integrations-faq h3 {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  @include respond-above(tablet) {
    .faq-section input:checked ~ .faq-point {
      max-height: initial !important;
    }
    .logo-holder {
      &.is-sticky {
        position: fixed !important;
        top: 180px !important;
        @include z-index(10);
      }
      &.is-mutable {
        opacity: 0;
      }
      &.is-visible {
        opacity: 1;
      }
      &.is-fixed {
        position: absolute;
        bottom: 0;
      }
    }
    .logo-content-holder {
      &.content-sticky {
        position: relative;
        left: 400px;
      }
    }

    .faq-section.bg-offset-top::before {
      height: 200px;
    }
    .cta-contrast.has-image h2 {
      font-size: 38px;
      line-height: 48px;
    }
    .lander-cta {
      .cta-contrast.has-image h2 {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
}

.square-listing {
  @include respond-above(tablet) {
    .logo-holder {
      &.is-sticky {
        top: 158px !important;
      }
    }
  }
}

.diary-management,
.online-agenda-software,
.online-booking-diary-software,
.online-scheduling-assistant {
  .highlight {
    h2 {
      font-family: $default-font;
      color: $black-600;
    }
  }
}

.land-pages {
  @include respond-above(tablet) {
    .has-four-col {
      .icon-title-wrap {
        figure {
          justify-content: center;
          width: auto;
          display: flex;
        }
      }
    }
  }
}

.features-desktop-app {
  .device-btn-wrap {
    a:last-child {
      vertical-align: middle;
      & > * {
        vertical-align: baseline !important;
      }
    }
  }
}

.page-with-widget {
  @include respond-below(tablet) {
    .trustpilot-widget-micro {
      width: 272px;
      margin: 0 auto;
    }
    .trustpilot-widget-macro .trustpilot-widget > iframe {
      height: 300px;
    }
    .app-review-wrap {
      position: static;
    }
    &.free-plan {
      .hero--default .layered-images-wrap picture img {
        margin-top: -536px;
      }
      .hero--default .width-adaptive {
        top: 204px;
      }
      .trustpilot-widget-mini {
        position: relative;
        top: 742px;
        @include z-index(1);
        width: 248px;
      }
      .m-top-mob {
        margin-top: 334px;
      }
    }
    &.premium-pro-with-widget {
      .pricing-wrap-mobile {
        top: 202px;
      }
      .trustpilot-widget-mini {
        position: relative;
        top: 1234px;
        @include z-index(1);
      }
      .pricing-wrap-mobile {
        padding-bottom: 180px;
      }
    }
  }
}
.listing-template {
  @include respond-above(tablet) {
    .logo-holder .bg-white {
      width: 260px;
      height: 260px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .logo-holder {
      &.is-fixed {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

.faq-root {
  #start-chat-btn {
    border: none;
    vertical-align: middle;
    cursor: pointer;
    outline: none;
    &:focus {
      border: none;
      outline: none;
    }
  }

  @include respond-above(tablet) {
    .faq-navigation {
      li {
        border-bottom: 1.5px solid #edf0f2;
        padding-bottom: 15px;
      }
      a {
        color: #8996a3;
        transition: color 0.5s;
        &.selected {
          font-weight: bold;
          color: $black-600;
          span {
            color: $color-brand;
            vertical-align: top;
            font-size: inherit;
          }
        }
      }
      &.faq-navigation-fixed {
        position: fixed !important;
        top: 180px !important;
        width: 260px;
      }
      &.faq-navigation-sticky {
        position: absolute;
        bottom: 140px;
      }
    }
    .faq-content {
      article::before {
        display: block;
        content: " ";
        margin-top: -180px;
        height: 180px;
        visibility: hidden;
        pointer-events: none;
      }
      &.content-sticky {
        margin-left: 400px;
      }
      .faq {
        width: initial !important;
        flex-grow: 0;
      }
    }
  }
  .faq-with-table {
    width: 100%;
    font-size: 16px;
    line-height: 1.75;
    thead {
      background-color: #f5f8fa;
    }
    th {
      padding: 7px 0;
      color: #303a47;
    }
    td {
      padding-top: 16px;
      color: #556270;
    }
    th,
    td {
      &:first-child {
        padding-left: 14px;
      }
      &:not(:first-child) {
        text-align: center;
      }
    }
  }
  @include respond-below(tablet) {
    .ff-euclid.text-grey {
      line-height: 2 !important;
    }
    .faq-section {
      border-bottom: 1.5px solid #edf0f2;
    }
    .faq-content {
      article {
        .faq-section {
          max-height: 0;
          overflow: hidden;
          transition: all 0.35s ease-in-out;
        }
        input {
          visibility: hidden;
        }
        .faq-section-header {
          position: relative;
          &::after {
            position: absolute;
            top: 50%;
            display: inline-block;
            content: "";
            right: 0px;
            width: 24px;
            height: 24px;
            border: none;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2348A36C' fill-rule='evenodd' d='M2.204 8.136c-.272-.26-.272-.681 0-.941.244-.234.624-.257.896-.07l.086.07L12 15.64l8.814-8.445c.244-.234.625-.257.896-.07l.087.07c.244.234.268.599.073.859l-.073.082-8.867 8.495c-.246.236-.581.369-.93.369-.3 0-.588-.098-.819-.274l-.11-.095-8.867-8.495z' clip-rule='evenodd'/%3E%3C/svg%3E");
            transform: translateY(-50%);
            transition: all 0.35s;
          }
        }
        input:checked ~ .faq-section {
          max-height: initial;
          overflow: visible;
        }
        input:checked + .faq-section-header::after {
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }
  .bg-offset::after {
    height: 100px;
    bottom: 0;
    @include respond-below(tablet) {
      display: none;
    }
  }
}
.customers-root {
  .customer-review {
    font-size: 24px;
    line-height: 32px;
  }
  .customer-business {
    color: #556270;
    font-size: 16px;
    line-height: 24px;
    & ~ a.text-brandcolor {
      line-height: 28px;
    }
  }
  .quot-glyph {
    font-size: 60px;
    line-height: normal;
  }
  // .video-highlight{cursor: pointer;}
  .video-highlight .play-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  #videoUtube {
    display: none;
    border-radius: 8px;
    width: 99.8%;
    height: 427px;
    @include respond-below(tablet) {
      width: 99.8%;
      height: 188px;
      z-index: 1;
    }
  }
  @include respond-above(tablet) {
    .customer-info > picture {
      width: 160px;
    }
    .customer-info article {
      box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
        0 1px 3px 0 rgba(48, 58, 71, 0.1);
      &:hover {
        box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
          0 1px 4px 0 rgba(22, 45, 61, 0.1);
      }
    }
    .bg-offset-top::before {
      height: 85%;
    }
  }
  @include respond-below(tablet) {
    .video-section {
      h2.text-lg {
        font-size: 38px;
        line-height: 48px;
      }
      & + section {
        background-color: $color-bg !important;
      }
    }
    .bg-offset-top::before {
      content: none;
    }
    .bg-offset-top {
      background-color: $color-bg;
    }
    .customer-info {
      background-color: white;
      border-radius: 8px;
    }
    .quot-glyph {
      font-size: 80px;
      margin-left: -3px;
    }
    .customer-info {
      box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
        0 1px 3px 0 rgba(48, 58, 71, 0.1);
      article {
        box-shadow: none;
      }
    }
  }
}
.integration-listing-zoho-crm {
  @include respond-below(tablet) {
    .logo-holder {
      width: 196px;
      height: 82px;
    }
  }
}

.integration-listing-answerconnect {
  .integration-table {
    display: block;
    width: auto;
    margin: 12px 0px 24px 0px;
    padding: 20px 65px 25px 20px;
    border: solid 1px #d9e1ee;
    background-color: #f6f8fc;
    border-radius: 8px;
    tbody {
      tr {
        th {
          font-size: 18px;
          font-weight: bold;
          line-height: 28px;
          width: 200px;
          height: 32px;
        }
        td {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
    @include respond-above(tablet) {
      &.mobile {
        display: none;
      }
    }
    @include respond-below(tablet) {
      display: none;
      &.mobile {
        display: block;
        margin: 14px 0px 0px 0px;
        padding: 24px;
        padding-bottom: 11px;
        tbody {
          tr {
            th,
            td {
              display: inline-block;
              width: 100%;
              margin-bottom: 22px;
            }
          }
        }
      }
    }
  }
}

.integration-listing-google-meet,
.integration-listing-infusionsoft-by-keap,
.integration-listing-freshsales,
.integration-listing-serviceminder,
.integration-listing-mailerlite {
  @include respond-above(tablet) {
    .hero--default {
      margin-bottom: 80px;
    }
  }
}
