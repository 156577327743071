.cta-contrast,
.cta-contrast-stripe {
  background-color: $blue-500;
  p,
  h2 {
    color: $color-white;
  }
}

// CTA Contrast White Background
.cta-contrast-white {
  background-color: #fff;
  p {
    color: $black-600;
  }
  h2 {
    color: $black-700;
  }
}

.cta-contrast {
  position: relative;
  &.cta-dark-green-theme {
    background-color: $green-800 !important;
  }
  &.cta-light-green-theme {
    background-color: $green-500 !important;
  }
  &.has-noimage {
    @include respond-below(desktop) {
      background-color: $green-500 !important;
    }
  }
  @include respond-above(tablet_max) {
    min-height: 400px;
    align-items: center;
    &.has-image {
      padding-left: 100px;
      h2 {
        font-size: 32px;
        line-height: 40px;
      }
      picture {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      &.has-img-offset {
        picture {
          right: 100px;
        }
      }
    }
    &.has-noimage {
      padding-top: 80px;
      padding-bottom: 88px;
      background-color: $green-500;
      @include respond-above(tablet_max) {
        min-height: initial;
      }
      article {
        max-width: 600px;
      }
    }
  }
  @include respond-below(desktop) {
    &.has-image {
      &.has-img-offset {
        picture {
          right: 0;
        }
      }
    }
  }
  @include respond-below(tablet_max) {
    &.has-image {
      padding: 20px;
      picture {
        display: none;
      }
      article {
        max-width: 75%;
      }
      h2 {
        font-size: 24px;
        line-height: 32px;
      }
    }
    &.has-noimage {
      padding: 32px 40px 40px;
    }
  }
  @include respond-below(tablet) {
    &.has-image {
      article {
        max-width: initial;
      }
    }
  }
}

.cta-sm {
  min-height: 176px;
}

.cta-with-image-left {
  .cta-contrast.has-image {
    .text-brandcolor {
      color: #fff;
      font-weight: 600;
      text-decoration: underline;
    }
  }
  @media (min-width: 768px) {
    .cta-contrast.has-image {
      justify-content: flex-end;
      .btn-ternary {
        margin-top: 14px !important;
        padding: 12px 26px;
      }
      picture {
        position: absolute;
        bottom: 0;
        right: 0 !important;
        left: 100px;
        img {
          width: auto;
          height: auto;
        }
      }
      article {
        margin-right: 100px !important;
      }
    }
  }
  @media (max-width: 767px) {
    .cta-contrast.has-image h2 {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }
}
