.booking-steps {
  position: relative;
  width: 100%;
  max-width: initial;
  padding-bottom: 28px;
  overflow: hidden;
  margin: 0 auto;

  article {
    position: relative;
    > h2 {
      text-align: center;
      margin: 0 auto;
      display: inline-block;
      width: 100%;
    }

    > p {
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
      line-height: 28px;
    }

    > ul {
      margin: 0 auto;
    }

    ul > li {
      position: relative;
    }

    > ul > li::after {
      content: attr(data-step);
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #1d90f5;
      position: absolute;
      top: 200px;
      left: 50%;
      transform: translate(-50%, -50%);
      line-height: 40px;
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      font-family: $primary-font;
      text-align: center;
    }
  }
  @include respond-above(desktop_standard) {
    article {
      position: static;
    }
  }
}

.booking-img-r,
.booking-img-l {
  width: auto;
  position: absolute;
  left: -12%;
  top: -30px;
}
.booking-img-r {
  width: auto;
  left: initial;
  right: -12%;
}

@include respond-above(desktop_standard) {
  .booking-img-r,
  .booking-img-l {
    left: 0;
    top: 0;
  }
  .booking-img-r {
    right: 0;
    left: initial;
  }
}

.booking-step {
  border-radius: 8px;
  margin-bottom: 44px;

  svg {
    width: 40px;
    vertical-align: middle;
    margin-right: 22px;
  }

  > p {
    padding: 20px 0 20px 0;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(48, 58, 71, 0.14),
      0px 2px 4px rgba(48, 58, 71, 0.1);
    border-radius: 8px;
    text-align: center;
    font-size: 14px;
    line-height: 28px;
    max-width: 266px;
    margin: 0 auto;
  }

  + p {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }

  > ul {
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(48, 58, 71, 0.14),
      0px 2px 4px rgba(48, 58, 71, 0.1);
    border-radius: 8px;
    text-align: left;
    font-size: 14px;
    line-height: 28px;
    padding: 21px 0px 24px 57px;
    max-width: 266px;
    margin: 0 auto;
  }

  li {
    width: 176px;
    padding: 8px 0;
    font-size: 14px;
    line-height: 28px;
    border-radius: 8px;
    background-color: #f5f8fa;
    margin-bottom: 6px;
    position: relative;
  }

  li::before {
    content: "";
    width: 17px;
    height: 17px;
    border: 1px solid #edf0f2;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -40px;
  }

  li:nth-child(2) {
    padding: 0;
    background-color: #fff;
  }

  li:nth-child(2)::before {
    top: 7px;
  }

  li:nth-child(2)::after {
    content: "";
    width: 11px;
    height: 11px;
    background-color: #8996a3;
    border-radius: 50%;
    position: absolute;
    left: -36px;
    top: 11px;
  }
}

@media (max-width: 768px) {
  .booking-step {
    display: none;
  }

  .booking-steps {
    article {
      > h2 {
        text-align: left;
      }

      > ul > li {
        margin-bottom: 24px;
      }

      > ul > li::after {
        left: 20px;
        top: 0;
        transform: translate(-50%);
        bottom: initial;
      }

      > ul p {
        margin-left: 56px;
        text-align: left;
      }

      > p {
        text-align: left;
      }
    }
  }
}

@include respond-below(desktop_sm) {
  .booking-img-r,
  .booking-img-l {
    display: none;
  }
}

@include respond-above(tablet) {
  .booking-steps {
    .card--default.has-three-col > * {
      width: calc((100% - (30px * 2)) / 3);
    }

    .card--default.card--feature.has-three-col > li p {
      padding-right: 0;
    }
  }
}
