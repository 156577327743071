.integration-listing-page {
  .logo-holder figure {
    max-width: 260px;
  }
  .faq-section .faq-ques {
    padding: 26px 30px 28px 20px;
    &::after {
      position: absolute;
      top: 50%;
      right: -56px;
      width: 24px;
      height: 24px;
      border: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2348A36C' fill-rule='evenodd' d='M21.797 15.864c.27.26.27.681 0 .941-.245.234-.625.257-.897.07l-.086-.07L12 8.36l-8.814 8.445c-.244.234-.625.257-.896.07l-.087-.07c-.244-.234-.268-.599-.073-.859l.074-.082 8.866-8.495c.246-.236.581-.369.93-.369.3 0 .588.098.819.274l.11.095 8.868 8.495z' clip-rule='evenodd'/%3E%3C/svg%3E");
      transform: translateY(-50%) rotate(180deg);
    }
    @include respond-below(tablet) {
      &::after {
        right: -63px;
      }
      padding-right: 0;
    }
  }
  .faq-section input:checked + .faq-ques::after {
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
  }
  .ordered-bulletin {
    & > li {
      position: relative;
      padding-left: 38px;
      margin-bottom: 12px;
      &::before {
        position: absolute;
        left: 0px;
        top: 5px;
        width: 18px;
        height: 18px;
        line-height: 18px;
      }
    }
  }
  @include respond-below(tablet) {
    .cta-contrast.has-image h2 {
      font-size: 24px !important;
      line-height: 30px !important;
    }
    .lander-cta {
      .cta-contrast.has-image h2 {
        font-size: 24px !important;
        line-height: 32px !important;
      }
    }
    .faq-section.bg-offset-top::before {
      height: 95px;
    }
    .faq-section input:checked ~ .faq-point {
      padding-bottom: 24px;
      max-height: initial;
    }
    &.integrations-faq h3 {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  @include respond-above(tablet) {
    .faq-section input:checked ~ .faq-point {
      max-height: initial !important;
    }
    .logo-holder {
      &.is-sticky {
        position: fixed !important;
        top: 180px !important;
        @include z-index(10);
      }
      &.is-mutable {
        opacity: 0;
      }
      &.is-visible {
        opacity: 1;
      }
      &.is-fixed {
        position: absolute;
        bottom: 0;
      }
    }
    .logo-content-holder {
      &.content-sticky {
        position: relative;
        left: 400px;
      }
    }

    .faq-section.bg-offset-top::before {
      height: 200px;
    }
    .cta-contrast.has-image h2 {
      font-size: 38px;
      line-height: 48px;
    }
    .lander-cta {
      .cta-contrast.has-image h2 {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
}

.square-listing {
  @include respond-above(tablet) {
    .logo-holder {
      &.is-sticky {
        top: 158px !important;
      }
    }
  }
}

.listing-template {
  @include respond-above(tablet) {
    .logo-holder .bg-white {
      width: 260px;
      height: 260px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .logo-holder {
      &.is-fixed {
        position: absolute;
        bottom: 0;
      }
    }
  }
}

.integration-listing-zoho-crm {
  @include respond-below(tablet) {
    .logo-holder {
      width: 196px;
      height: 82px;
    }
  }
}

.integration-listing-google-meet,
.integration-listing-infusionsoft-by-keap,
.integration-listing-freshsales,
.integration-listing-serviceminder {
  @include respond-above(tablet) {
    .hero--default {
      margin-bottom: 80px;
    }
  }
}

@media (min-width: 768px) {
  .card--default.has-shadow > * {
    padding: 24px 22px 32px;
  }
}
