.grow-your-brand {
  .hero-default {
    .brand-text {
      @include respond-below(mobile_max) {
        margin-left: 6px !important;
      }
    }
    .emoji-with-tooltip {
      .tooltip {
        visibility: hidden;
        position: absolute;
        img {
          position: relative;
          top: -10px;
          left: -6px;
          width: 80px;
          @include respond-between(tablet, tablet_max) {
            width: 70px;
            left: -6px;
            top: -14px;
          }
          @include respond-between(tablet_max, desktop_sm) {
            top: -12px;
            left: -12px;
          }
        }
        &::before {
          transition: all 0.3s cubic-bezier(0.19, 1, 0.26, 1.05);
        }
        &::after {
          content: attr(data-tooltip);
          position: absolute;
          top: -43px;
          transition: all 0.3s cubic-bezier(0.19, 1, 0.26, 1.05);
        }
        &.active {
          visibility: visible;
          transform: translateY(0);
          img {
            opacity: 1;
            transform: scale(1);
            transition: all 0.2s cubic-bezier(0.19, 1, 0.26, 1.05);
          }
          &::after {
            top: -53px;
            opacity: 1;
          }
          &::before {
            top: -6px;
            opacity: 1;
          }
          @include respond-between(tablet, tablet_max) {
            &::after {
              top: -60px;
            }
            &::before {
              top: -14px;
              left: 4px;
            }
          }
        }
        @include respond-below(mobile_max) {
          left: 50%;
          right: 50%;
          transform: translate(-50%, -50%) !important;
          &::before,
          &::after {
            font-size: 16px;
            left: 50% !important;
            right: 50%;
            transform: translate(-70%, -50%) !important;
          }
          &::before {
            top: -5px !important;
            left: -10px !important;
          }
          &.active {
            &::before {
              top: -15px !important;
            }
          }
          &:nth-child(1)::after {
            left: -98%;
            transform: translate(-60%, -50%) !important;
          }
          &:nth-child(2)::after {
            width: 100px;
            left: -38%;
            transform: translate(-60%, -50%) !important;
          }
          &:nth-child(3)::after {
            width: 150px;
            left: -95%;
            transform: translate(-60%, -50%) !important;
          }
          &:nth-child(4)::after {
            width: 130px;
            left: -98%;
            transform: translate(-60%, -50%) !important;
          }
        }

        @include respond-between(mobile_max, tablet_max) {
          &:nth-child(1)::after {
            left: -98%;
          }
          &:nth-child(2)::after {
            left: -45%;
          }
          &:nth-child(3)::after {
            left: -100%;
          }
          &:nth-child(4)::after {
            left: -65%;
          }
        }
      }
      @include respond-below(mobile_max) {
        top: 10%;
        left: 54%;
        transform: translate(-50%);
      }
      @include respond-between(mobile_max, desktop_sm) {
        left: 55%;
      }
    }
  }
  .white-card {
    @include respond-below(mobile_max) {
      width: 335px !important;
      &:first-child,
      &:nth-child(4),
      &:nth-child(7) {
        height: 228px;
      }
      &:not(:nth-child(7)):not(:nth-child(4)):not(:first-child) {
        height: 194px;
      }
    }
  }
  .report-card-item {
    .report-card-title,
    picture,
    .report-card-content {
      position: relative;
    }
    @include respond-between(mobile, tablet_max) {
      picture {
        img {
          width: 180px;
        }
      }
      &:first-child {
        picture {
          top: 10px;
          right: 25px;
        }
        .report-card-title {
          top: 1px;
          left: 4px;
        }
        .report-card-content {
          top: 2px;
        }
      }
      &:nth-child(2) {
        picture {
          top: 35px;
          right: 18px;
        }
        .report-card-title {
          top: 27px;
          left: 10px;
        }
        .report-card-content {
          top: 25px;
        }
      }
      &:nth-child(3) {
        picture {
          top: 36px;
          right: 14px;
        }
        .report-card-title {
          top: 27px;
          left: 10px;
        }
        .report-card-content {
          top: 25px;
        }
      }
    }
    @include respond-above(tablet_max) {
      &:first-child {
        picture {
          bottom: 28px;
          right: 15px;
          img {
            width: 340px;
          }
        }
        .report-card-title {
          bottom: 25px;
        }
      }
      &:nth-child(2) {
        picture {
          bottom: 5px;
          img {
            height: 365px;
          }
        }
        .report-card-title {
          bottom: 4px;
        }
      }
      &:nth-child(3) {
        picture {
          bottom: 20px;
          img {
            height: 360px;
          }
        }
        .report-card-title {
          bottom: 25px;
        }
      }
    }
    @include respond-below(mobile) {
      &:nth-child(1) {
        picture {
          top: 6px;
        }
      }
      &:nth-child(3) {
        height: 300px;
        picture {
          top: 45px;
        }
        .report-card-title {
          top: 35px;
        }
        .report-card-content {
          top: 35px;
        }
      }
    }
  }
  .booking-sec-slider {
    .slider-img {
      position: relative;
      z-index: 1;
      transition: all 1s ease-out;
      &:not(:nth-child(2)) {
        img {
          width: 560px;
          height: auto;
          margin-top: 80px;
          @include respond-below(tablet_max) {
            margin-top: 30px;
            width: 300px;
          }
          @include respond-below(mobile_max) {
            margin-top: 15px;
            width: 160px;
          }
        }
      }
      &:nth-child(1) {
        transform: translate(20px);
        left: 30%;
        @include respond-below(desktop) {
          left: 45%;
        }
        @include respond-below(tablet_max) {
          left: 20%;
        }
        @include respond-below(tablet_sm) {
          left: 30%;
        }
      }
      &:nth-child(3) {
        transform: translate(-20px);
        right: 30%;
        @include respond-below(desktop) {
          right: 45%;
        }
        @include respond-below(tablet_max) {
          right: 20%;
        }
        @include respond-below(tablet_sm) {
          right: 30%;
        }
      }
      &:nth-child(2) {
        position: relative;
        z-index: 5;
        img {
          width: 760px;
          height: auto;
          @include respond-below(tablet_max) {
            width: 400px;
          }
          @include respond-below(mobile_max) {
            width: 212px;
          }
        }
      }
      &.slider-active {
        transform: translate(0px);
      }
    }
  }
  .sec-with-top-offset {
    position: relative;
    height: 280px;
    background-color: #edf0f2;
    @include respond-below(tablet_sm) {
      height: 200px;
    }
  }
  .faq-section {
    position: relative;
    height: 700px;
    padding-bottom: 0px !important;
    h2 {
      margin-bottom: 40px !important;
    }
    .faq-ques {
      font-size: 20px !important;
    }
    @include respond-below(tablet_sm) {
      height: 660px;
    }
    @include respond-between(mobile_sm, mobile) {
      height: 880px;
    }
  }
  .brand-carousel {
    @include respond-between(mobile_max, tablet_max) {
      padding-bottom: 200px;
    }
    @include respond-below(mobile_max) {
      padding-bottom: 120px;
      .sec-with-top-offset {
        height: 70px;
      }
    }
    .long-carousel {
      &-item {
        opacity: 0.5;
        cursor: pointer;
        box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
          0 1px 3px 0 rgba(48, 58, 71, 0.1);
        .descp {
          @include respond-below(mobile_max) {
            padding: 32px 16px 24px !important;
          }
          p {
            width: 300px;
            @include respond-below(mobile_max) {
              width: auto;
            }
          }
          h3 {
            text-transform: none !important;
            font-family: $primary-font;
          }
        }
        .play-btn {
          svg {
            &:nth-child(2) {
              display: none !important;
            }
          }
        }
        .video-highlight {
          .video-timg:hover {
            video {
              cursor: pointer;
            }
          }
        }
        @include respond-above(desktop_max) {
          opacity: 1 !important;
          &:last-child {
            display: none !important;
          }
        }
        &:last-child {
          display: block;
        }
        &:nth-child(1) {
          opacity: 0.5;
          &:hover {
            opacity: 1;
          }
        }
        &:nth-child(2) {
          opacity: 1;
          .video-timg:hover {
            path {
              transform: none !important;
            }
          }
          .play-btn {
            width: 140px !important;
            left: 66% !important;
            @include respond-below(tablet) {
              width: 120px !important;
              left: 52% !important;
            }
            @include respond-above(desktop_max) {
              left: 52% !important;
            }
            svg {
              &:nth-child(1) {
                display: none !important;
              }
              &:nth-child(2) {
                display: block !important;
              }
            }
          }
        }
        &:nth-child(3) {
          opacity: 0.5;
          &:hover {
            opacity: 1;
          }
        }
        &.active {
          box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1),
            0 4px 8px 0 rgba(48, 58, 71, 0.14);
          opacity: 1 !important;
          pointer-events: visible;
          .play-btn {
            svg {
              &:nth-child(1) {
                display: none !important;
              }
              &:nth-child(2) {
                display: block !important;
              }
            }
          }
        }
        .video-highlight {
          @include respond-below(tablet_max) {
            height: auto !important;
            width: 100%;
          }
          .play-btn {
            left: 76%;
            top: 82%;
            @include respond-above(desktop_max) {
              top: 73%;
              left: 60%;
            }
            svg {
              width: 64px;
              height: 64px;
              &:nth-child(2) {
                width: 128px;
                height: 64px;
                @include respond-below(tablet_max) {
                  width: 110px;
                }
              }
            }
            @include respond-below(tablet_max) {
              top: 76%;
            }
            @include respond-below(mobile) {
              top: 70%;
              left: 55%;
            }
          }
        }
      }
    }
  }
  .link-cta-sec {
    .nav-link {
      @include respond-below(mobile_max) {
        width: 75%;
      }
    }
  }
  .nav-link a,
  .masnory-link a {
    font-size: 18px;
    line-height: 28px;
    @include respond-below(mobile_max) {
      width: 90%;
    }
  }
  .video-highlight {
    & .video-timg {
      &::before,
      &::after {
        content: none;
      }
    }
  }
  .carousel {
    transform: translate(-235px);
    .carousel-item {
      font-family: $primary-font;
      opacity: 0.5;
      box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
        0 1px 3px 0 rgba(48, 58, 71, 0.1);
      &:hover {
        opacity: 1;
      }
      .descp {
        h3 {
          text-transform: none !important;
        }
        @include respond-below(mobile_max) {
          padding: 32px 16px 24px !important;
          p {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
      &.active {
        opacity: 1 !important;
        pointer-events: visible;
        box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1),
          0 4px 8px 0 rgba(48, 58, 71, 0.14);
      }
      @include respond-above(tablet_sm) {
        &:nth-child(3) {
          opacity: 1;
          pointer-events: visible;
        }
      }
      @include respond-below(tablet_sm) {
        &:nth-child(2) {
          opacity: 1;
          pointer-events: visible;
        }
      }
    }
    .video-highlight {
      width: 352px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-top: 3px;
      border-radius: 8px;
      overflow: hidden;
      height: auto !important;

      .play-btn {
        position: absolute;
        left: 60%;
        top: 65%;
        width: 64px;
        height: 64px;
      }
    }
    h3 {
      color: #556270 !important;
    }
    @include respond-below(tablet_sm) {
      width: 4000px !important;
      transform: translate(-263px);
      .play-btn {
        left: 39% !important;
        top: 50% !important;
        width: 20px !important;
        height: 20px !important;
        svg {
          height: 65px;
        }
      }
    }
    @include respond-between(tablet_max, desktop) {
      transform: translate(-450px);
    }
    @include respond-between(mobile_max, tablet_max) {
      transform: translate(-185px);
      .play-btn {
        left: 50% !important;
        top: 55% !important;
        width: 20px !important;
        height: 20px !important;
        svg {
          height: 65px;
        }
      }
    }
    @include respond-above(desktop_md) {
      transform: translate(-153px);
    }
    @media (max-width: 1900px) and (min-width: 1799px) {
      transform: translate(-80px);
    }
  }
  .branding-section {
    @include respond-above(mobile_max) {
      picture {
        text-align: center !important;
      }
      .welcome-branding-img {
        width: 560px !important;
        margin-bottom: 40px;
      }
    }
  }
  .cta-section {
    margin-top: 0 !important;
    padding-bottom: 40px !important;
    .cta-contrast {
      padding-left: 100px;
      padding-right: 100px;
      article {
        max-width: 520px;
        h2 {
          font-size: 24px;
          line-height: 32px;
        }
        p {
          font-size: 18px;
          line-height: 28px;
          max-width: 460px;
          margin-bottom: 40px !important;
        }
        a {
          background: transparent;
          border: 1px solid $green-800;
          color: $green-800;
          padding: 4px 20px;
          height: auto;
          margin-top: 0 !important;
          span {
            font-size: 14px;
            line-height: 36px;
          }
        }
        .btn-quaternary {
          margin-left: 24px !important;
        }
      }
      picture {
        right: 35px !important;
        bottom: 25px;
        img {
          width: 100%;
          height: 100% !important;
        }
        @include respond-between(tablet_max, desktop) {
          right: 0px !important;
        }
      }
    }
    @include respond-between(mobile_max, tablet_max) {
      .cta-contrast {
        flex-direction: column;
        width: 76%;
        left: 50%;
        transform: translateX(-50%);
        padding: 170px 80px 80px !important;

        article {
          margin-right: 0px !important;
          a {
            padding: 12px 32px;
            height: auto;
            margin-top: 0 !important;
          }
          .btn-quaternary {
            margin-left: 0 !important;
            margin-top: 24px !important;
          }
        }
        picture {
          display: block;
          position: absolute;
          bottom: 70%;
          width: 80%;
          right: 0 !important;
          left: 50%;
          transform: translateX(-50%);
          padding-left: 0;
        }
      }
    }
    @include respond-below(mobile_max) {
      .cta-contrast {
        margin-top: 0px;
        width: 89%;
        left: 45%;
        transform: translateX(-50%);
        padding: 160px 20px 20px !important;
        margin-bottom: 0px;
        padding: 0px;
        article {
          margin-right: 0px !important;
          a {
            padding: 12px 15px;
            height: auto;
            margin-top: 0 !important;
          }
          .btn-quaternary {
            margin-left: 0 !important;
            margin-top: 16px !important;
          }
        }
        picture {
          display: block;
          position: absolute;
          bottom: 74%;
          width: 100%;
          right: 0 !important;
          left: 50%;
          transform: translateX(-50%);
          padding-left: 0;
        }
      }
    }
  }
  .secondary-cta {
    background-image: url("https://assets.setmore.com/website/v2/images/grow-your-brand/young-women-grp.png");
    background-position: center;
    background-size: cover;
    width: 96%;
    height: 485px;
    padding-left: 100px;
    a {
      background: #1b3d32 !important;
      color: #fff !important;
      padding: 8px 32px;
      position: relative;
      z-index: 1;
      span {
        line-height: 30px;
      }
    }
    picture {
      display: none;
      max-width: none !important;
      right: 0px !important;
      width: 100% !important;
      padding-left: 0px !important;
      margin-left: 20px;
      img {
        width: 100%;
        height: 480px;
      }
    }
    h2 {
      font-size: 32px;
      line-height: 40px;
      max-width: 460px;
    }
    p {
      max-width: 450px !important;
    }
    article {
      padding-top: 80px;
    }
    @include respond-below(tablet_max) {
      background-image: none;
      transform: translateX(-50%);
      background-color: #edf0f2;
    }
    @include respond-between(tablet, tablet_max) {
      width: 75%;
      left: 47%;
      padding-top: 0px !important;
      padding-left: 0;
      padding-right: 0;
      flex-direction: column;
      margin-top: 0;
      article {
        padding-left: 80px;
        max-width: 100%;
      }
      picture {
        display: block;
        margin-left: 0px;
      }
    }
    @include respond-below(tablet) {
      padding: 10px;
      left: 50%;
      height: 100%;
      max-width: 88%;
      margin-right: 10px;
      margin-left: 0;
      article {
        padding: 10px;
      }
      picture {
        display: none;
      }
    }
    @media (max-width: 1080px) and (min-width: 992px) {
      p {
        max-width: 360px !important;
      }
    }
  }

  .signin-demo-sec {
    > picture {
      position: absolute;
      z-index: 1;
      top: 332px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 760px;
        height: auto;
      }
    }
    @include respond-between(mobile_max, tablet_max) {
      > picture {
        top: 340px;
        img {
          width: 700px;
        }
      }
    }
    @include respond-below(mobile_md) {
      > picture {
        top: 516px;
        img {
          width: 350px;
        }
      }
    }
    @include respond-between(mobile_md, mobile_max) {
      > picture {
        top: 486px;
        img {
          width: 376px;
        }
      }
    }
  }
}
