//HOME PAGE
.page--home {
  .cta-contrast.has-image {
    @include respond-above(desktop) {
      padding-top: 80px;
      padding-bottom: 88px;
    }
    h2 {
      @extend .text-lg;
    }
  }
  .home-masonry {
    &.bg-offset::after {
      bottom: -32%;
      height: 76%;
      @include respond-below(tablet) {
        height: 68%;
        bottom: -31%;
      }
    }
  }
  .home-integrations {
    &.bg-offset::after {
      bottom: 0;
      height: 38%;
      @include respond-below(tablet) {
        height: 15%;
        bottom: 0;
      }
    }
  }
  .root-integrations {
    &.bg-offset::after {
      height: 36%;

      @include respond-below(tablet) {
        height: 15%;
      }
    }
  }
  .custom-bg-offset {
    .bg-offset::after {
      bottom: 0;
      height: 1000px;

      @include respond-below(tablet) {
        height: 1000px;
        bottom: 0;
      }
    }
  }
  .pricing-wrap {
    .card--default {
      @include respond-below(tablet) {
        > * {
          padding: 20px 40px 40px;
        }
        a.btn-primary,
        a.btn-secondary {
          background-color: $green-800;
          color: $white-900;
        }
      }
      .ticked-bulletin li {
        margin-bottom: 6px;
      }
    }
    &.bg-offset::after {
      top: 0;
      height: 50%;
      @include respond-below(tablet) {
        height: 18%;
        top: 0;
      }
    }
  }
  .use-setmore-wrap {
    .app-review-wrap {
      margin-top: -270px;
    }
  }
  .ordered-bulletin {
    @include respond-below(tablet) {
      li {
        margin-bottom: 15px;

        &::before {
          margin-right: 10px;
        }
      }
    }
  }
  .premium-bg-cta {
    background-color: $white-900;
  }
}

// OFFSET STYLES
.industry-masonry {
  &.bg-offset::after {
    top: 0;
    height: 210px;
    @include respond-below(tablet) {
      height: 224px;
    }
  }
  &.bg-offset-lg::after {
    top: 0;
    height: 262px;

    @include respond-below(tablet) {
      height: 224px;
    }
  }
  &.bg-offset-btm::after {
    top: auto;
    bottom: 0;
    height: 320px;

    @include respond-below(tablet) {
      height: 37px;
    }
  }
}
.industry-features {
  &.bg-offset::after {
    bottom: 0;
    height: calc(100% - 264px);
    @include respond-below(tablet) {
      height: 124px;
    }
  }
  @include respond-above(tablet) {
    &.bg-offset--adapt::after {
      height: calc(100% - 325px);
    }
  }
}
// DEVICES SECTION
.device-wrap {
  .desktop-wrap {
    max-width: 540px;
  }
  @include respond-below(tablet) {
    .desktop-wrap,
    .mobile-wrap {
      width: 100%;
      margin: 0 auto;
    }
  }
  @include respond-below(mobile_sm) {
    [class^="btn-"] {
      margin: 0 0 20px 0;
    }
  }
  [class^="btn-"] {
    padding: 11px 24px;
  }
  .device-btn-wrap {
    > * {
      @include respond-below(tablet) {
        max-width: 160px;
        padding: 12px 20px;
      }
    }
    > *:first-child {
      @include respond-below(tablet) {
        margin-right: 15px;
      }

      @include respond-below(mobile_sm) {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
  }
}
// USE SETMORE SECTION
.use-setmore-wrap {
  @include respond-above(tablet) {
    > div {
      max-width: 1360px;
      margin: 0 auto;
    }
    article {
      margin-left: 40px;
    }
    picture {
      img {
        margin-top: -80px;
      }
    }
    h2 {
      margin-bottom: 24px;
    }
    .btn-primary {
      margin-top: 40px;
    }
  }

  @include respond-between(tablet, desktop) {
    > div {
      align-items: center;
    }
    picture {
      min-width: 400px;

      img {
        margin-top: 0;
      }
    }
    article {
      margin-left: 20px;
    }
  }
  @include respond-below(tablet) {
    article {
      margin: 0 20px;
    }
    picture {
      img {
        margin-top: -40px;
      }
    }
    h2 {
      margin-bottom: 20px;
    }
    .btn-primary {
      margin-top: 20px;
    }
  }
}
// PEOPLE LOVE SETMORE
.people-love-setmore {
  .heart-glyph {
    @extend .ff-euclid;
    font-size: 40px;
    line-height: 64px;

    @include respond-below(tablet) {
      font-size: 28px;
      line-height: 48px;
    }
  }
  @include respond-below(tablet) {
    font-size: 38px;
    line-height: 48px;
  }
}
// CUSTOMISE SECTION
.customise-wrap {
  align-items: center;
  &.has-content-left {
    > article {
      margin-left: 100px;
      @include respond-between(tablet, desktop) {
        margin-left: 20px;
      }
      @include respond-below(tablet) {
        margin-left: 0px;
      }
    }
  }
  &.has-content-right {
    > article {
      margin-right: 100px;
      @include respond-between(tablet, desktop) {
        margin-right: 20px;
      }
      @include respond-below(tablet) {
        margin-right: 0;
      }
    }
  }
  @include respond-above(tablet) {
    h2 {
      margin-bottom: 24px;
    }
  }
  @include respond-below(tablet) {
    h2 {
      margin-bottom: 20px;
    }
    article {
      padding: 40px 20px 20px;
    }
  }
}
// HIGHLIGHT SECTION
@include respond-above(desktop_standard) {
  .highlight-img {
    min-width: 40%;
  }
}

.page-with-widget {
  @include respond-below(tablet) {
    .trustpilot-widget-micro {
      width: 272px;
      margin: 0 auto;
    }
    .trustpilot-widget-macro .trustpilot-widget > iframe {
      height: 300px;
    }
    .app-review-wrap {
      position: static;
    }
  }
}

.industry-with-video {
  .use-setmore-wrap {
    div {
      max-width: 1160px;
    }
    .device-section {
      max-width: 1360px;
    }
    article {
      margin: 0;
      @include respond-above(desktop) {
        margin-left: 140px;
      }
    }
    picture {
      img {
        margin-top: 0;
      }
    }

    // Video styles
    .video-highlight {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      width: 560px;
      height: 314px;
      cursor: pointer;
      @include respond-above(desktop) {
        margin-top: -40px;
      }
      @include respond-below(tablet) {
        width: 100%;
        height: 100%;
        @include z-index(1);
      }

      .video-timg {
        position: relative;
        display: inline-block;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        overflow: hidden;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          border-radius: 8px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          transition: opacity 0.3s;
        }
        @include respond-below(tablet) {
          &::after {
            display: block;
            content: "";
            width: 100%;
            height: 0px;
            position: absolute;
            bottom: 0;
            background: #f6f8fc;
          }
        }
        &:focus {
          outline: none !important;
        }
        & video {
          width: 103% !important;
          position: relative;
          left: -6px;
          top: -4px;
          @include respond-below(tablet) {
            left: -5px;
            top: -5px;
            margin: 0px 0 -10px 0px;
          }
        }
      }
      .video-timg:hover {
        cursor: pointer;
        &::before {
          opacity: 0.25;
          cursor: pointer;
        }
        path {
          transform: scale(1.3);
          transform-origin: 40px 40px;
        }
      }
      .video-timg:active {
        &::before {
          opacity: 0.5;
        }
        circle {
          fill-opacity: 1;
          fill: #fff;
        }
      }
    }
    .video-content {
      position: relative;
      border-radius: 8px;
      width: 960px;
      // height: 539px;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      @include respond-below(desktop_standard) {
        width: 760px;
      }

      @include respond-below(tablet) {
        width: 100%;
      }
    }

    .video-cont {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
    }
    .video-cont iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    #videoUtube {
      display: none;
      border-radius: 8px;
      @include respond-below(tablet) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 99.8%;
        height: 100%;
        z-index: 1;
      }
    }
    .play-btn {
      width: 80px;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      cursor: pointer;
      border: 0;
      padding: 0;
      background-color: transparent;
      outline: none;
      @include respond-below(tablet) {
        width: 100%;
        height: 100%;
      }
    }
    .is-played {
      @include respond-below(tablet) {
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
      }
    }
  }
}
