.try-setmore-wrap {
  text-align: left;

  @include respond-above(desktop) {
    article {
      margin-left: 40px;
    }
  }

  @include respond-between(tablet, desktop) {
    article {
      margin-left: 0;
    }
  }

  @include respond-below(tablet) {
    picture {
      img {
        width: calc(100% + 40px);
        margin-left: -20px;
      }
    }
  }

  > img {
    left: 0;
    top: -60px;

    @include respond-below(mobile) {
      left: -20px;
      top: -40px;
      max-width: initial;
      width: calc(100% + 40px);
    }

    @include respond-above(tablet) {
      left: -40px;
      top: -68px;
    }
  }
}

.mission-contribution {
  &.bg-offset::after {
    bottom: 0;
    height: 285px;

    @include respond-below(tablet) {
      height: 120px;
    }
  }
}
