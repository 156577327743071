.online-agenda-software,
.online-booking-diary-software,
.online-scheduling-assistant,
.online-scheduling,
.landing-page {
  .app-review-wrap {
    background-color: white;
  }
  .bg-offset-bottom {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 260px;
      left: 0;
      background-color: $black-200;
      @include z-index(minus-1);
    }
  }
  @include respond-above(tablet) {
    .highlight > div > article a.pos-abs {
      bottom: 80px;
    }
  }
  @include respond-below(tablet) {
    .app-review-wrap {
      background-color: transparent;
      margin-top: 22px;
      width: 100%;
    }
    .highlight {
      .btn-secondary {
        max-width: 100%;
      }
    }
    .list-with-icon li > span {
      display: inline-block;
      width: 86%;
      vertical-align: top;
      margin-top: 3px;
    }
    .card--masonry li.bg-green {
      picture {
        margin-top: -36px !important;
        margin-left: -23px !important;
      }
    }
    .ticked-bulletin li {
      margin-bottom: 6px !important;
    }
    .pricing-wrap.bg-offset-bottom {
      &::after {
        height: 70%;
      }
    }
  }
}
.online-booking-diary-software,
.online-scheduling-assistant,
.online-scheduling,
.landing-page {
  .list-with-icon li > span {
    display: inline-block;
    width: 86%;
    vertical-align: top;
    margin-top: 3px;
  }
}

.online-scheduling-assistant,
.online-scheduling,
.landing-page {
  .app-review-wrap {
    background-color: transparent;
  }
}

.diary-management,
.online-agenda-software,
.online-booking-diary-software,
.online-scheduling-assistant,
.online-scheduling,
.landing-page {
  .highlight {
    h2 {
      font-family: $default-font;
      color: $black-600;
    }
  }
}

.landing-pages {
  &.bg-offset::after {
    height: 82%;

    @include respond-below(tablet) {
      height: 77%;
    }
  }
}
.land-pages {
  @include respond-above(tablet) {
    .has-four-col {
      .icon-title-wrap {
        figure {
          justify-content: center;
          width: auto;
          display: flex;
        }
      }
    }
  }
}

//Modal in booking page demo
.booking-page-demo {
  #popup {
    display: none;
    width: 80%;
    height: 80%;
    object-fit: contain;
    top: 80px;
    left: 120px;
    z-index: 11;
    position: fixed;
    opacity: 1;
    visibility: visible;
    border-radius: 8px;
    @include respond-below(tablet) {
      left: 50px;
    }
    .popup-close {
      width: 115px;
      height: 25px;
      right: -115px;
      z-index: 1;
      line-height: 24px;
      top: -45px;
      font-size: 15px;
      color: white;
      background: transparent;
      border: transparent;
      padding: 0px;
      text-align: left;
      &::before,
      &::after {
        left: 100px;
        width: 30px;
        background-color: #ffffff;
      }
      @include respond-below(desktop_sm) {
        right: -25px;
      }
      @include respond-below(mobile_max) {
        top: -30px;
        right: 0px;
      }
    }
  }
  #iframe {
    border: 0;
    width: 100%;
    height: 100%;
    background: #fbfcfc;
    position: relative;
    z-index: 10;
    border-radius: 8px;
  }
  .overlay {
    background-color: #181818;
    opacity: 0.8;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .btn-primary {
    cursor: pointer;
    span {
      font-size: 16px;
      line-height: 30px;
    }
  }
}

.faq-root {
  #start-chat-btn {
    border: none;
    vertical-align: middle;
    cursor: pointer;
    outline: none;
    &:focus {
      border: none;
      outline: none;
    }
  }

  @include respond-above(tablet) {
    .faq-navigation {
      li {
        border-bottom: 1.5px solid #edf0f2;
        padding-bottom: 15px;
      }
      a {
        color: #8996a3;
        transition: color 0.5s;
        &.selected {
          font-weight: bold;
          color: $black-600;
          span {
            color: $color-brand;
            vertical-align: top;
            font-size: inherit;
          }
        }
      }
      &.faq-navigation-fixed {
        position: fixed !important;
        top: 180px !important;
        width: 260px;
      }
      &.faq-navigation-sticky {
        position: absolute;
        bottom: 140px;
      }
    }
    .faq-content {
      article::before {
        display: block;
        content: " ";
        margin-top: -180px;
        height: 180px;
        visibility: hidden;
        pointer-events: none;
      }
      &.content-sticky {
        margin-left: 400px;
      }
      .faq {
        width: initial !important;
        flex-grow: 0;
      }
    }
  }
  .faq-with-table {
    width: 100%;
    font-size: 16px;
    line-height: 1.75;
    thead {
      background-color: #f5f8fa;
    }
    th {
      padding: 7px 0;
      color: #303a47;
    }
    td {
      padding-top: 16px;
      color: #556270;
    }
    th,
    td {
      &:first-child {
        padding-left: 14px;
      }
      &:not(:first-child) {
        text-align: center;
      }
    }
  }
  @include respond-below(tablet) {
    .ff-euclid.text-grey {
      line-height: 2 !important;
    }
    .faq-section {
      border-bottom: 1.5px solid #edf0f2;
    }
    .faq-content {
      article {
        .faq-section {
          max-height: 0;
          overflow: hidden;
          transition: all 0.35s ease-in-out;
        }
        input {
          visibility: hidden;
        }
        .faq-section-header {
          position: relative;
          &::after {
            position: absolute;
            top: 50%;
            display: inline-block;
            content: "";
            right: 0px;
            width: 24px;
            height: 24px;
            border: none;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2348A36C' fill-rule='evenodd' d='M2.204 8.136c-.272-.26-.272-.681 0-.941.244-.234.624-.257.896-.07l.086.07L12 15.64l8.814-8.445c.244-.234.625-.257.896-.07l.087.07c.244.234.268.599.073.859l-.073.082-8.867 8.495c-.246.236-.581.369-.93.369-.3 0-.588-.098-.819-.274l-.11-.095-8.867-8.495z' clip-rule='evenodd'/%3E%3C/svg%3E");
            transform: translateY(-50%);
            transition: all 0.35s;
          }
        }
        input:checked ~ .faq-section {
          max-height: initial;
          overflow: visible;
        }
        input:checked + .faq-section-header::after {
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }
  .bg-offset::after {
    height: 100px;
    bottom: 0;
    @include respond-below(tablet) {
      display: none;
    }
  }
}
.premium-bg-cta {
  background-color: $white-900 !important;
}

.online-scheduling {
  .hero--default {
    picture img {
      @include respond-above(desktop) {
        width: 680px;
        height: 420px;
        position: relative;
        right: 18px;
      }
    }
  }
  .use-setmore-wrap {
    background-color: $light-grey-100 !important;
    img {
      box-shadow: 0px 1px 2px 0px #303a4733, 0px 1px 3px 0px #303a471a;
    }
  }
  section.highlight {
    background-color: $light-grey-100 !important;
    h3 {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
    }
  }
  .pricing-container {
    background-color: $light-grey-100;
    padding-bottom: 0px !important;
    h2 {
      font-size: 40px;
      line-height: 48px;
      padding-top: 0 !important;
    }
    article {
      p {
        margin-bottom: 80px !important;
        max-width: 700px;
      }
    }
    &::after {
      bottom: -200px;
      height: 200px;
      background-color: $light-grey-100;
    }
  }
  .pricing-container,
  section:nth-of-type(6) {
    .text-brandcolor {
      color: $blue-600 !important;
      &:hover {
        color: $blue-800 !important;
      }
    }
  }
  section {
    &:nth-of-type(6) {
      background-color: $white-900;
      box-shadow: 0px 1px 2px 0px rgba(48, 58, 71, 0.2),
        0px 1px 3px 0px rgba(48, 58, 71, 0.1);
      margin-bottom: 160px !important;
    }
  }
  .featured-text-section {
    margin-bottom: 160px;
    h2 {
      font-size: 40px;
      line-height: 48px;
    }
  }
  .cta-contrast.has-noimage {
    article {
      max-width: 430px;
    }
  }
  .btn-secondary {
    font-size: 16px;
    line-height: 24px;
  }
  .list-with-icon li > span {
    display: initial;
  }
  .text-brandcolor {
    color: $green-500 !important;

    &:hover {
      color: $green-400 !important;
    }
  }

  @include respond-below(tablet) {
    .hero--default {
      margin-right: 0;
      margin-left: 0;
      h1 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 16px;
      }
      figure {
        width: 100% !important;
        overflow: hidden;
        picture img {
          aspect-ratio: auto !important;
          width: 108% !important;
          position: relative;
          left: -14px;
        }
      }
      article {
        margin-left: 20px !important;
        margin-right: 20px !important;
      }
    }
    .use-setmore-wrap {
      padding-bottom: 80px !important;
      h2 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px !important;
      }
      picture img {
        margin-top: -25px !important;
        height: 222px !important;
      }
    }
    .highlight {
      h3 + p {
        font-size: 18px;
        line-height: 28px;
      }
      ul li {
        &::before {
          top: 6px !important;
        }
      }
    }
    .pricing-container {
      article {
        h2 {
          margin-top: 0 !important;
          margin-bottom: 32px;
        }
        p {
          margin-bottom: 40px !important;
        }
      }
      ul {
        li {
          margin-left: 20px !important;
        }
        li:nth-of-type(1) {
          margin-top: 0px !important;
        }
      }
      .billing-savings-off {
        display: none;
      }
      &::after {
        bottom: -250px;
        height: 250px !important;
      }
    }
    section:nth-of-type(6) {
      margin-bottom: 80px !important;
      padding: 24px 48px !important;
      & > div {
        width: 100%;
      }
    }
    .badge-carousel-section,
    .featured-text-section {
      ul li {
        background-color: $light-grey-100;
        border-radius: 8px;
      }
      h2 {
        font-size: 32px;
        line-height: 40px;
        text-align: left;
      }
    }
    .featured-text-section {
      margin-bottom: 80px;
    }

    .list-with-icon li > span {
      display: inline-block;
    }
    .list-with-icon .btn-secondary {
      padding: 9px 20px;
    }
    .list-with-icon {
      margin-top: 52px;
    }
    .app-review-wrap {
      margin-left: auto;
      margin-right: auto;
    }
  }
  @include respond-above(tablet) {
    .use-setmore-wrap {
      padding-bottom: 160px !important;
      div {
        max-width: 1160px;
        margin-right: auto;
        margin-left: auto;
        article {
          margin-left: 140px;
          h2 {
            font-size: 32px;
            line-height: 48px;
            margin-top: 80px !important;
          }
        }
      }
      picture img {
        margin-top: -40px;
        aspect-ratio: 560 / 372 !important;
      }
    }
    .highlight {
      ul {
        width: 51%;
      }
    }
    .billing-type-wrap {
      max-width: 1160px;
    }
    .billing-toggle {
      order: 2;
    }
    .billing-savings-off {
      display: initial !important;
      order: 1;
      margin-top: 20px;
    }
  }
}

.landing-page.has-pricing-comments {
  @include respond-above(tablet) {
    .billing-type-wrap {
      max-width: 1160px;
    }
    .billing-savings-off {
      display: initial !important;
      order: 1;
      margin-top: 20px;
    }
  }
}
.landing-page {
  .list-with-icon li > span {
    width: calc(100% - 42px);
    margin-top: 0px;
    text-align: left;
    @include respond-above(tablet) {
      vertical-align: bottom;
    }
  }
}
.landing-page-free-business-website {
  @include respond-below(tablet) {
    &.landing-page .card--masonry li.bg-green picture {
      margin-left: 0 !important;
      margin-top: -14px !important;
    }
  }
}
.landing-page-ipad,
.landing-page-mobile-appointment-scheduling-software {
  .highlight .btn-secondary {
    line-height: 24px;
  }
  @include respond-above(tablet) {
    .cta-w-image-section {
      padding-bottom: 180px;
    }
    .cta-contrast.has-image.premium-bg-cta picture {
      bottom: -66px;
    }
    .cta-contrast {
      min-height: 345px;
      h2 {
        font-size: 38px;
        line-height: 48px;
      }
    }
  }
  @include respond-below(tablet) {
    .cta-w-image-section {
      padding-bottom: 98px;
    }
    .cta-contrast.has-image h2 {
      font-size: 32px;
      line-height: 40px;
    }
    .cta-contrast.has-image.premium-bg-cta {
      padding: 0;
      display: flex;
      flex-direction: column;
      & > picture {
        order: 1;
        display: initial;
      }
      & > article {
        order: 2;
      }
    }
  }
  .faq-section .container h2 {
    margin-bottom: 40px !important;
  }
  &.industry-with-video .use-setmore-wrap .container {
    @include respond-below(tablet) {
      margin-top: -75px;
    }
  }
}
.landing-page-mobile-appointment-scheduling-software {
  @include respond-above(tablet) {
    .cta-contrast {
      min-height: 441px;
    }
    .cta-w-image-section {
      padding-bottom: 209px;
    }
    .cta-contrast.has-image {
      padding-left: 83px;
    }
    .cta-contrast.has-image.premium-bg-cta picture {
      bottom: -130px;
    }
  }
  @include respond-below(tablet) {
    .cta-contrast.has-image.premium-bg-cta {
      margin-top: 60px;
      picture {
        margin-top: -60px;
      }
    }
  }
}

.qr-landing-page {
  .faq-section .faq-ques {
    padding: 26px 30px 28px 20px;
    &::after {
      position: absolute;
      top: 50%;
      right: -56px;
      width: 24px;
      height: 24px;
      border: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%2348A36C' fill-rule='evenodd' d='M21.797 15.864c.27.26.27.681 0 .941-.245.234-.625.257-.897.07l-.086-.07L12 8.36l-8.814 8.445c-.244.234-.625.257-.896.07l-.087-.07c-.244-.234-.268-.599-.073-.859l.074-.082 8.866-8.495c.246-.236.581-.369.93-.369.3 0 .588.098.819.274l.11.095 8.868 8.495z' clip-rule='evenodd'/%3E%3C/svg%3E");
      transform: translateY(-50%) rotate(180deg);
    }
    @include respond-below(tablet) {
      &::after {
        right: -63px;
      }
      padding-right: 0;
    }
  }
  .faq-section input:checked + .faq-ques::after {
    top: 50%;
    transform: translateY(-50%) rotate(0deg);
  }
  .ordered-bulletin {
    & > li {
      position: relative;
      padding-left: 38px;
      margin-bottom: 12px;
      &::before {
        position: absolute;
        left: 0px;
        top: 5px;
        width: 18px;
        height: 18px;
        line-height: 18px;
      }
    }
  }
  .faq-section.bg-offset-top::before {
    height: 0px;
  }
  @include respond-below(tablet) {
    .faq-section input:checked ~ .faq-point {
      padding-bottom: 24px;
      max-height: initial;
    }
    &.integrations-faq h3 {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  @include respond-above(tablet) {
    .faq-section input:checked ~ .faq-point {
      max-height: initial !important;
    }
  }
  .features {
    @include respond-above(tablet) {
      &.bg-offset-top::before {
        height: 345px;
      }
    }
    .card--default.has-four-col {
      .btn-outline {
        border: transparent;
        box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1),
          0 4px 8px 0 rgba(48, 58, 71, 0.14);
      }
    }
  }

  @include respond-above(tablet) {
    .cta-contrast.has-image h2 {
      font-size: 38px;
      line-height: 48px;
    }
  }

  /* Slideshow container */
  .slideshow-container {
    position: relative;
    object-fit: contain;
    .image-slides {
      display: none;
      img {
        vertical-align: middle;
        height: 400px;
        border-radius: 8px;
        width: 100%;
        margin: 0 auto;
        @include respond-below(tablet) {
          height: auto;
        }
      }
    }
    .image-slides-dot {
      text-align: center;
      margin-top: 16px;
      .image-dot {
        cursor: pointer;
        width: 12px;
        height: 12px;
        margin: 0 12px 0 0;
        background-color: #dbdbdb;
        border-radius: 50%;
        display: inline-block;
        transition: background-color 0.6s ease;
        &.active,
        &:hover {
          background-color: #1d90f5;
        }
      }
      @include respond-below(tablet) {
        margin-top: 30px;
      }
    }
    /* Fading animation */
    .fade {
      -webkit-animation-name: fade;
      -webkit-animation-duration: 1.5s;
      animation-name: fade;
      animation-duration: 1.5s;
    }
    @-webkit-keyframes fade {
      from {
        opacity: 0.4;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fade {
      from {
        opacity: 0.4;
      }
      to {
        opacity: 1;
      }
    }
  }
  .customise-wrap {
    box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
  }
}
.partners {
  .video-section::before {
    height: 87%;
  }
  .hover-review-card.boxshadow-none {
    border: solid 1.5px $black-300;
    &:hover {
      box-shadow: none;
    }
  }
  .hover-review-card:hover,
  .blog-posts:hover {
    box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1),
      0 4px 8px 0 rgba(48, 58, 71, 0.14);
  }
  // .card--default.card--showcase figure {
  //   margin: -5px 0 5px 70px;
  //   @include respond-below(tablet) {
  //     margin: 25px 0 0px 80px;
  //   }
  // }
  .card--default.card--showcase {
    & > a {
      text-align: left;
      display: flex;
      // width: calc((100% - (20px * 2)) / 2);
      @include respond-below(tablet) {
        flex-wrap: wrap;
        width: 330px;
      }
    }
    @include respond-below(tablet) {
      & > * {
        padding: 18px 16px 24px 20px;
      }
    }
  }
  .card--default.card--showcase p {
    font-size: 18px;
    line-height: 28px;
    @include respond-above(tablet) {
      max-width: 300px;
      display: inline-block;
    }
    text-align: left;
  }
  .img-with-green-bg {
    margin-top: -15px;
    @include respond-below(tablet) {
      margin-top: -12px;
    }
  }
  @include respond-below(tablet) {
    .img-with-yellow-bg {
      margin-top: 4px;
    }
    .video-section article h2 {
      font-size: 38px;
      line-height: 48px;
      @include respond-below(tablet) {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }
}

.community {
  @include respond-above(tablet) {
    .hero--default {
      figure {
        margin-top: -13px;
        margin-right: 8px;
        video {
          height: 532px;
        }
      }
    }
    .use-setmore-wrap {
      picture img {
        margin-top: -45px;
      }
      article {
        margin-left: 140px;
      }
    }
    .video-highlight {
      position: relative;
      border-radius: 8px;
      background-color: transparent;
      overflow: hidden;
      width: 560px;
      height: 316px;
      @include respond-above(tablet) {
        margin-left: 24px;
      }
    }
  }
  @include respond-below(tablet) {
    .hero--default {
      figure {
        video {
          max-width: 100%;
        }
      }
    }
    .use-setmore-wrap {
      picture img {
        margin-top: 15px;
        width: auto;
      }
    }
    .video-highlight #videoUtube {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 99.8%;
      height: 100%;
      z-index: 1;
    }
    #blogPostsContainer {
      overflow-x: auto;
      white-space: nowrap;
      scroll-behavior: smooth;
      margin-left: -20px;
      width: 100%;
      padding: 0px 0px 13px 0px;
      margin-right: 0px;
      a {
        display: inline-block;
        padding: 24px 26px 24px 24px;
        margin: 0px 10px;
        width: 295px;
        vertical-align: bottom;
        height: 232px;
        h3,
        p {
          white-space: pre-line;
        }
      }
    }
    .industry-masonry {
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
      }
    }
    .cta-contrast,
    .cta-contrast-white {
      article {
        h2 {
          font-size: 24px !important;
          line-height: 32px !important;
        }
      }
    }
  }
  .social-media-section {
    figure {
      max-width: 235px;
      display: flex;
      justify-content: space-between;
    }
    a {
      background-color: white;
      padding: 12px;
      border-radius: 4px;
      box-shadow: 0 0.7px 1.4px 0 rgba(48, 58, 71, 0.2),
        0 0.7px 2.1px 0 rgba(48, 58, 71, 0.1);
      &:hover {
        box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1),
          0 4px 8px 0 rgba(48, 58, 71, 0.14);
      }
    }
    .text-xs {
      @include respond-below(tablet) {
        font-size: 18px;
        line-height: 28px;
        font-weight: normal;
      }
    }
    &::after {
      height: 50%;
    }
  }
  .contact-section {
    display: flex;
    a,
    button {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        width: auto;
        display: flex;
        span {
          line-height: 24px;
        }
      }
      &:nth-child(1) {
        padding: 0px 30px 0px 0px;
      }
      &:nth-child(2) {
        border-right: 1px solid #d9e1ee;
        border-left: 1px solid #d9e1ee;
        padding: 0px 30px 0px 30px;
      }
      &:nth-child(3) {
        padding: 0px 0px 0px 30px;
      }
    }
    button {
      background: transparent;
      border: transparent;
      cursor: pointer;
    }
    @include respond-below(tablet) {
      margin-left: -10px;
      a,
      button {
        &:nth-child(1) {
          padding: 0 22px 0 0;
        }
        &:nth-child(2) {
          padding: 0 21px 0 10px;
        }
        &:nth-child(3) {
          padding: 0 0 0 7px;
        }
      }
    }
  }
  .earth-cta {
    .try-setmore-wrap {
      border-radius: 8px;
      box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
        0 1px 3px 0 rgba(48, 58, 71, 0.1);
    }
    &.bg-offset-top::before {
      height: 120px;
    }
  }
}

.video-section {
  article {
    order: 2;
    @include respond-below(tablet) {
      display: block;
    }
  }
}

.landing-pages-v2 {
  @media (min-width: 768px) {
    .use-setmore-wrap {
      margin-top: 24px;
      picture {
        img {
          margin-top: -42px;
          width: 100%;
          height: auto;
        }
      }
      .text-lg {
        font-size: 40px;
        line-height: 48px;
      }
    }
    .industry-masonry {
      .text-xl {
        font-size: 56px;
      }
    }
    .bg-offset-top::before {
      content: "";
      height: 400px;
    }
    .bg-offset-bottom::after {
      content: "";
      height: 225px;
    }
    .featured-section {
      .grid--default {
        article {
          p:nth-child(3) {
            margin-top: 12px !important;
          }
        }
      }
    }
    .faq-section {
      .text-xl {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 80px !important;
      }
    }
    .pricing-wrap {
      .text-xl {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }
  @media (max-width: 767px) {
    .industry-masonry {
      &.bg-offset-top::before {
        height: 570px;
      }
    }
    .faq-section {
      .text-xl {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 20px !important;
      }
    }
    .bg-offset-bottom::after {
      content: "";
      height: 110px;
      bottom: -20px;
    }
    .bg-offset-top::before {
      content: "";
      height: 435px;
    }
    .people-love-setmore {
      font-size: 32px;
      line-height: 40px;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      justify-content: space-between;
      width: 290px;
    }
    .pricing-wrap {
      .text-xl {
        margin-top: 0px !important;
      }
    }
  }
}
