.form-wrap {
  @extend %border-radius-md;
  position: relative;
  min-width: 400px;
  padding: 48px 80px;
  background-color: $color-white;
  box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
    0 1px 4px 0 rgba(22, 45, 61, 0.1);
  a[class*="btn-"] {
    margin-top: 0;
  }
  .input-wrap {
    position: relative;
    input {
      width: 100%;
      padding: 32px 0 10px 0;
      color: $black-600;
      font-size: 16px;
      line-height: 24px;
      border-bottom: 1px solid $black-300;
      &::placeholder {
        color: $black-400;
      }
      //to remove autofill caused by webkit browsers
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-text-fill-color: $black-600 !important;
        color: $black-600 !important;
      }
    }
    &.active input,
    input:focus {
      border-color: $color-brand;
    }
    .input-msg {
      display: none;
      position: absolute;
      font-size: 10px;
      transform: translateY(100%);
      bottom: -4px;
      @include z-index(1);
      > * {
        vertical-align: middle;
      }

      > i {
        @extend %d-inline-block;
        width: 15px;
        height: 15px;
        margin-right: 4px;
        background: no-repeat;
        background-size: cover;
      }
    }
    &.error {
      input {
        border-color: $color-error;
      }
      .input-msg {
        display: block;
        > i {
          background-image: url($icon-error);
        }
      }
    }
  }
  .social-btn-wrap {
    font-size: 0;
    > [class*="btn-"] {
      min-width: initial;
      width: calc((100% - 20px) / 2);
      padding: 10px 5px;
    }
    > *:first-child {
      margin-right: 20px;
    }
    i {
    }
  }
  @include respond-below(desktop) {
    padding: 40px;
  }
  @include respond-below(tablet_max) {
    min-width: 250px;
    padding: 30px;
  }
  @include respond-below(tablet) {
    padding: 20px;
  }
}

.toast {
  @extend %text-truncate;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  color: $color-white;
  padding: 12px;
  background-color: rgba($black, 0.9);
  border-radius: 30px;
  text-align: center;
  opacity: 0;
  transition: 0.5s opacity;
  &.is-visible {
    opacity: 1;
  }
  &.hide {
    transition: 1s opacity;
    opacity: 0 !important;
  }
}

@include respond-above(tablet) {
  .form-holder {
    &.is-sticky {
      position: fixed !important;
      top: initial !important ;
      @include z-index(10);
    }
    &.is-mutable {
      position: absolute;
      opacity: 0;
      transition-delay: 0.05s;
    }
    &.is-visible {
      opacity: 1;
    }
  }
}
@include respond-between(tablet, desktop) {
  .form-holder {
    &.is-mutable {
      right: 20px;
    }
  }
}

@include respond-between(tablet, desktop) {
  .form-holder {
    &.is-sticky {
      right: 20px;
    }
  }
}
@include respond-above(tablet_max) {
  .form-holder {
    width: 460px;
  }
}
