.customer-template {
  .apostrophe {
    font-size: 80px;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: $color-brand;
    margin-left: -2px;
  }
  .apostrophe + blockquote.fs-italic {
    color: #8996a3;
  }
  @include respond-above(tablet) {
    .hero--default {
      article {
        align-self: flex-end;
        .apostrophe {
          line-height: 0;
        }
        h1 {
          font-size: 40px;
        }
      }
    }
  }
  @include respond-below(tablet) {
    .hero--default {
      article h1 {
        margin-top: -28px;
      }
    }
  }
  .customer-story {
    &.btn-outline {
      border: 1px solid #cee0f5;
    }
    &:hover {
      background-color: white;
      border-color: transparent;
      box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
        0 1px 3px 0 rgba(48, 58, 71, 0.1);
    }
    blockquote {
      font-size: 24px;
      line-height: 1.33;
      color: $color-dark;
      margin-top: -40px;
    }
    .customer-business {
      font-size: 12px;
      letter-spacing: 0.6px;
      line-height: normal;
      text-align: left;
      @include respond-below(tablet) {
        font-size: 16px;
        letter-spacing: 0.8px;
      }
    }
  }
}

.customers-root {
  .customer-review {
    font-size: 24px;
    line-height: 32px;
  }
  .customer-business {
    color: #556270;
    font-size: 16px;
    line-height: 24px;
    & ~ a.text-brandcolor {
      line-height: 28px;
    }
  }
  .quot-glyph {
    font-size: 60px;
    line-height: normal;
  }

  .video-highlight {
    position: relative;
    border-radius: 8px;
    background-color: transparent;
    overflow: hidden;
    width: 560px;
    height: 316px;
    @include respond-above(tablet) {
      margin-left: 24px;
    }
  }
  // .video-highlight{cursor: pointer;}
  .video-highlight .play-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  #videoUtube {
    display: none;
    border-radius: 8px;
    width: 99.8%;
    height: 427px;
    @include respond-below(tablet) {
      width: 99.8%;
      height: 188px;
      z-index: 1;
    }
  }
  @include respond-above(tablet) {
    .customer-info > picture {
      width: 160px;
    }
    .customer-info article {
      box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
        0 1px 3px 0 rgba(48, 58, 71, 0.1);
      &:hover {
        box-shadow: 0 14px 32px 0 rgba(22, 45, 61, 0.08),
          0 1px 4px 0 rgba(22, 45, 61, 0.1);
      }
    }
    .bg-offset-top::before {
      height: 85%;
    }
  }
  @include respond-below(tablet) {
    .video-section {
      h2.text-lg {
        font-size: 38px;
        line-height: 48px;
      }
      & + section {
        background-color: $color-bg !important;
      }
    }
    .bg-offset-top::before {
      content: none;
    }
    .bg-offset-top {
      background-color: $color-bg;
    }
    .customer-info {
      background-color: white;
      border-radius: 8px;
    }
    .quot-glyph {
      font-size: 80px;
      margin-left: -3px;
    }
    .customer-info {
      box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
        0 1px 3px 0 rgba(48, 58, 71, 0.1);
      article {
        box-shadow: none;
      }
    }
  }
}

.customer-root-v2 {
  @include respond-above(tablet) {
    .hero--default {
      figure {
        video {
          width: 620px;
          margin: -25px 0px 0px -10px;
        }
      }
    }
    .use-setmore-wrap {
      article {
        margin-left: 140px;
      }
      .video-highlight {
        height: 314px !important;
      }
    }
    .video-highlight {
      position: relative;
      border-radius: 8px;
      background-color: transparent;
      overflow: hidden;
      width: 560px;
      height: 316px;
    }
    .bg-offset-top::before {
      height: 800px;
    }
    .cta-img-gradient {
      background-image: linear-gradient(
        to bottom,
        white,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
      width: 452px;
      height: 100px;
      display: flex;
      top: 176px;
      position: absolute;
      transform: rotate(-90deg);
      z-index: 1;
    }
  }
  @include respond-below(tablet) {
    .hero--default {
      figure {
        video {
          max-width: 355px;
          margin: 0px 0px 0px -18px;
        }
      }
    }
    .use-setmore-wrap {
      picture img {
        margin-top: 15px;
        width: auto;
      }
    }
    .video-highlight #videoUtube,
    .video-highlight #videoUtubeOne {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 99.8%;
      height: 100%;
      z-index: 1;
    }
    #blogPostsContainer {
      overflow-x: auto;
      white-space: nowrap;
      scroll-behavior: smooth;
      margin-left: -20px;
      width: 100%;
      padding: 0px 0px 13px 0px;
      margin-right: 0px;
      a {
        display: inline-block;
        padding: 24px 26px 24px 24px;
        margin: 0px 10px;
        width: 295px;
        vertical-align: bottom;
        height: 232px;
        h3,
        p {
          white-space: pre-line;
        }
      }
    }
    .video-section {
      .video-highlight {
        height: auto !important;
      }
    }
    .industry-masonry {
      h2 {
        font-size: 32px !important;
        line-height: 40px !important;
      }
    }
    .cta-contrast,
    .cta-contrast-white {
      article {
        h2 {
          font-size: 24px !important;
          line-height: 32px !important;
        }
      }
    }
    .cta-contrast-white {
      border-radius: 8px;
      box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
        0 1px 3px 0 rgba(48, 58, 71, 0.1);
    }
    .bg-offset-top::before {
      height: 980px;
    }
  }

  .video-section {
    .video-highlight {
      width: 560px;
      height: 314px;
    }
  }
  .social-media-section {
    max-width: 235px;
    display: flex;
    justify-content: space-between;
    a {
      background-color: white;
      padding: 12px;
      border-radius: 4px;
      box-shadow: 0 0.7px 1.4px 0 rgba(48, 58, 71, 0.2),
        0 0.7px 2.1px 0 rgba(48, 58, 71, 0.1);
      &:hover {
        box-shadow: 0 2px 4px 0 rgba(48, 58, 71, 0.1),
          0 4px 8px 0 rgba(48, 58, 71, 0.14);
      }
    }
    .text-xs {
      @include respond-below(tablet) {
        font-size: 18px;
        line-height: 28px;
        font-weight: normal;
      }
    }
    &::after {
      height: 50%;
    }
    @include respond-below(tablet) {
      max-width: 335px;
      a {
        padding: 18px;
        svg {
          width: 34px;
          height: 34px;
        }
      }
    }
  }
  .contact-section {
    display: flex;
    a {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        width: auto;
        display: flex;
        span {
          line-height: 24px;
        }
      }
      &:nth-child(1) {
        padding: 0px 30px 0px 0px;
      }
      &:nth-child(2) {
        border-right: 1px solid #d9e1ee;
        border-left: 1px solid #d9e1ee;
        padding: 0px 30px 0px 30px;
      }
      &:nth-child(3) {
        padding: 0px 0px 0px 30px;
      }
    }
    @include respond-below(tablet) {
      margin-left: -10px;
      a {
        &:nth-child(1) {
          padding: 0 22px 0 0;
        }
        &:nth-child(2) {
          padding: 0 21px 0 10px;
        }
        &:nth-child(3) {
          padding: 0 0 0 7px;
        }
      }
    }
  }
  .earth-cta {
    .try-setmore-wrap {
      border-radius: 8px;
      box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
        0 1px 3px 0 rgba(48, 58, 71, 0.1);
    }
    &.bg-offset-top::before {
      height: 120px;
    }
  }
  // Carousel
  .carousel-cont {
    overflow: hidden;
    padding: 10px 0;

    @include respond-below(tablet) {
      overflow-x: auto;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
      }
    }
  }
  .carousel {
    width: 2400px;
    transform: translate(-260px);
    transition: all 1s;
    @include respond-below(tablet) {
      width: 1890px;
      transform: translate(-275px);
    }
    &-item {
      max-width: calc((100% - (40px * 6)) / 6);
      width: 100%;
      border-radius: 0 0 8px 8px;
      box-shadow: 0 1px 2px 0 rgba(48, 58, 71, 0.2),
        0 1px 3px 0 rgba(48, 58, 71, 0.1);
      background-color: #fff;
      h3,
      p {
        max-width: 288px;
      }
      h3 {
        color: #8996a3;
        text-transform: capitalize;
      }
      p {
        font-size: 24px;
        line-height: 32px;
        color: #303a47;
        font-weight: bold;
        margin-bottom: 24px;
        @include respond-below(tablet) {
          font-size: 18px;
          line-height: 28px;
          margin-top: 8px;
        }
      }
      a {
        font-size: 18px;
        line-height: 28px;
        color: $color-brand;
        @include respond-below(tablet) {
          font-size: 16px;
          line-height: 24px;
        }
      }
      @include respond-below(tablet) {
        max-width: calc((100% - (20px * 6)) / 6);
      }
    }
  }
  // Video styles
  .video-highlight {
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    height: auto !important;
    // cursor: pointer;
    .video-timg {
      position: relative;
      display: inline-block;
      border-radius: 8px;
      width: 100%;
      @include respond-below(tablet) {
        &::after {
          display: block;
          content: "";
          width: 100%;
          height: 10px;
          position: absolute;
          bottom: 0;
          background: #f6f8fc;
        }
      }
      background-color: transparent;
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.3s;
      }
      &:focus {
        outline: none !important;
      }
      overflow: hidden;
      & video {
        width: 101%;
        margin: -1px 0 -3px -2px;
      }
    }
    .video-timg:hover {
      cursor: pointer;
      &::before {
        opacity: 0.25;
        cursor: pointer;
      }
      path {
        transform: scale(1.3);
        transform-origin: 40px 40px;
      }
    }
    .video-timg:active {
      &::before {
        opacity: 0.5;
      }
      circle {
        fill-opacity: 1;
        fill: #fff;
      }
    }
  }
  .video-content {
    position: relative;
    border-radius: 8px;
    width: 960px;
    // height: 539px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    @include respond-below(desktop_standard) {
      width: 760px;
    }
    @include respond-below(tablet) {
      width: 100%;
    }
  }
  .video-cont {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .video-cont iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  #videoUtube,
  #videoUtubeOne {
    border-radius: 8px;
    @include respond-below(tablet) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 99.8%;
      height: 100%;
      z-index: 1;
    }
  }
  .play-btn {
    width: 80px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
  }
  .play-btn:not(:focus) {
    outline: none;
  }
  .is-played {
    @include respond-below(tablet) {
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
    }
  }
  // Testimonial modal
  .testimonial-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(25, 31, 41, 0.95);
    align-items: center;
    justify-content: center;
    z-index: 10;

    .btn-primary {
      min-width: 242px;
    }
  }

  #closeBtn {
    font-size: 16px;
    line-height: 1.5;
    color: #f5f8fa;
    position: absolute;
    top: 32px;
    right: 32px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    svg {
      vertical-align: middle;
    }
    @include respond-below(tablet) {
      top: 30px;
      right: 14px;
    }
  }
  .btn-outline {
    cursor: pointer;
    @extend %btn-default;
    border: 1px solid $black-300;
    transition: 0.4s box-shadow, 0.3s background-color linear;
    > span {
      color: $black-600;
    }
    &:hover {
      @extend .hover-review-card;
    }
  }
  .cta-w-image-section {
    &.bg-offset-top::before {
      top: 80px;
      height: 400px;
      @include respond-below(tablet) {
        height: 700px;
      }
    }
  }
  .customise-wrap {
    box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.2);
    @include respond-above(tablet) {
      &.has-content-left {
        article {
          margin-left: 80px;
        }
      }
    }
  }
  //utility classes
  .mb-2\.5 {
    margin-bottom: 20px;
  }
}

.customer-nichole {
  .hero--default .customer-business-logo {
    width: 140px;
    height: 78px;
    margin-top: 20px;
  }
  @include respond-above(tablet) {
    .hero--default article h1.lg\:maxw-md {
      max-width: 480px !important;
    }
    .hero--default .customer-business-logo {
      margin-top: 70px;
    }
  }
}

.customer-sanaa {
  .hero--default .customer-business-logo {
    width: 86px;
    height: 87px;
  }
  @include respond-above(tablet) {
    .hero--default article h1.lg\:maxw-md {
      max-width: 465px !important;
    }
    .hero--default article p.lg\:maxw-md {
      margin-bottom: 12px;
    }
    .bg-offset-bottom .cta-contrast .btn-ternary {
      padding-right: 56px;
      padding-left: 56px;
    }
  }
  @include respond-below(tablet) {
    .hero--default {
      margin-top: 70px;
      .customer-business-logo {
        margin-top: 20px;
      }
      picture {
        margin-top: 20px !important;
      }
    }
  }
}

.customer-hayat {
  .hero--default .customer-business-logo {
    width: 105px;
    height: 89px;
  }
  @include respond-above(tablet) {
    .hero--default article h1.lg\:maxw-md {
      max-width: 465px !important;
    }
    .hero--default article p.lg\:maxw-md {
      margin-bottom: 35px;
    }
    .info-section .grid--default article.lg\:maxw-md {
      margin-top: 56px;
    }
    .info-section .grid--default article.fx--o-2 {
      margin-top: 20px;
    }
    .bg-offset-bottom .cta-contrast .btn-ternary {
      padding-right: 56px;
      padding-left: 56px;
    }
  }
  @include respond-below(tablet) {
    .hero--default {
      margin-top: 55px;
      .customer-business-logo {
        margin-top: 20px;
      }
      picture.lg\:maxw-lg {
        margin-left: -20px !important;
        .imgholder-secondary {
          width: auto !important;
        }
      }
    }
  }
}

.customer-lynsey {
  .hero--default .customer-business-logo {
    width: 124px;
    height: 50px;
  }
  @include respond-above(tablet) {
    .hero--default article h1.lg\:maxw-md {
      max-width: 465px !important;
    }
    .hero--default article p.lg\:maxw-md {
      margin-bottom: 65px;
    }
    .bg-offset-bottom .cta-contrast .btn-ternary {
      padding-right: 56px;
      padding-left: 56px;
    }
  }
  @include respond-below(tablet) {
    .hero--default {
      margin-top: 55px;
      .customer-business-logo {
        margin-top: 20px;
      }
      picture.lg\:maxw-lg {
        margin-left: -20px !important;
        .imgholder-secondary {
          width: auto !important;
        }
      }
    }
  }
}

.customer-travis {
  .hero--default .customer-business-logo {
    width: 150px;
    height: 78px;
  }
  @include respond-above(tablet) {
    .hero--default article h1.lg\:maxw-md {
      max-width: 465px !important;
    }
    .hero--default article p.lg\:maxw-md {
      margin-bottom: 46px;
    }
    .info-section .grid--default article.lg\:maxw-md {
      margin-top: 56px;
    }
    .info-section .grid--default article.fx--o-2 {
      margin-top: 37px;
    }
    .bg-offset-bottom .cta-contrast .btn-ternary {
      padding-right: 56px;
      padding-left: 56px;
    }
  }
  @include respond-below(tablet) {
    .hero--default {
      margin-top: 55px;
      .customer-business-logo {
        margin-top: 27px;
      }
      picture.lg\:maxw-lg {
        margin-left: -20px !important;
        .imgholder-secondary {
          width: auto !important;
        }
      }
    }
  }
}

.customer-naomi {
  .info-section .grid--default.has-align-top > * {
    vertical-align: middle;
  }
  @include respond-above(tablet) {
    .hero--default .customer-business-logo {
      width: 108px;
      height: 96px;
      margin-top: 40px;
    }
    .cta-w-image-section .btn-lg {
      padding: 12px 56px;
    }
  }
  @include respond-below(tablet) {
    .hero--default .customer-business-logo {
      width: 92px;
      height: 86px;
      margin-top: 20px;
    }
  }
}

.customer-naomi,
.customer-deniserenee {
  @include respond-below(tablet) {
    .hero--default.container {
      margin-left: initial;
      margin-right: initial;
      article {
        margin-left: 20px;
        margin-right: 20px;
      }
      img.imgholder-secondary {
        aspect-ratio: 375/306 !important;
      }
    }
  }
}
.customer-deniserenee {
  .hero--default .customer-business-logo {
    width: 174px;
    height: 49px;
    margin-top: 20px;
  }
  @include respond-above(tablet) {
    .hero--default .customer-business-logo {
      width: 230px;
      height: 65px;
      margin-top: 59px;
    }
    .highlight-section .grid--default:nth-child(2).has-align-middle > * {
      vertical-align: top;
    }
  }
  @include respond-below(tablet) {
    .hero--default.container {
      img.imgholder-secondary {
        aspect-ratio: 375/286 !important;
      }
    }
  }
}
.customer-caitee {
  .hero--default .customer-business-logo {
    width: 120px;
    height: 78px;
    margin-top: 20px;
  }
  @include respond-above(tablet) {
    .hero--default .customer-business-logo {
      width: 120px;
      height: 78px;
      margin-top: 94px;
    }
  }
}
