.industry-page-with-trust-pilot {
  .trustpilot-widget-micro {
    width: 272px;
    height: 56px;
  }
  .trustpilot-widget-macro {
    height: 150px;
    @include respond-below(tablet) {
      height: 300px;
    }
  }
  @include respond-above(tablet) {
    &:not(.page--home) .industry-masonry.bg-offset::after {
      height: 490px;
    }
    &.industry-root-page {
      .industry-masonry.bg-offset-top::before {
        height: 490px;
      }
    }
  }
  @include respond-below(tablet) {
    .trustpilot-widget-macro .trustpilot-widget > iframe {
      height: 300px;
    }
    .industry-masonry.bg-offset::after {
      height: 570px;
    }
    &.industry-root-page {
      .industry-masonry.bg-offset-top::before {
        height: 570px;
      }
    }
  }
}

.dental-page,
.barber-shops {
  @include respond-above(tablet) {
    .trustpilot-widget-micro {
      margin-top: 32px !important;
    }
  }
  @include respond-below(tablet) {
    .trustpilot-widget-micro {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
