// To create space for responsive images while they load

.imgholder-primary {
  aspect-ratio: 560/400 !important;
}
.imgholder-secondary,
.imgholder-custom {
  aspect-ratio: 560/480 !important;
}
.imgholder-tertiary {
  aspect-ratio: 560/526 !important;
}
.imgholder-quaternary {
  aspect-ratio: 624/560 !important;
}
.imgholder-quinary {
  aspect-ratio: 760/440 !important;
}
.imgholder-senary {
  aspect-ratio: 760/520 !important;
}
.imgholder-septenary {
  aspect-ratio: 140 / 93 !important;
}
.imgholder-basic {
  aspect-ratio: 560/560 !important;
}
.imgholder-custom-bp {
  aspect-ratio: 744/450 !important;
}
