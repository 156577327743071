.hero--default {
  @include respond-above(tablet) {
    margin-top: 88px;
    padding-top: 72px;
    .layered-images-wrap {
      vertical-align: bottom;
      min-width: 350px;
    }
  }
  @include respond-below(tablet) {
    margin-top: 56px;
    padding-top: 40px;
  }
  @include respond-below(mobile) {
    [class^="btn-"] {
      width: 100%;
    }
  }
  .app-review-wrap {
    margin-top: 42px;
  }
  .fg-layer {
    position: absolute;
    left: 0;
    bottom: -80px;
  }
  .fg-layer-md {
    position: absolute;
    left: 0;
    bottom: -116px;
    @include respond-below(tablet) {
      bottom: -33%;
    }
  }
}

.hero-offset {
  margin-top: 88px;
  @include respond-below(tablet) {
    margin-top: 56px;
  }
}
